import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';
import { ForgeAppBar, ForgeButton, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import MenuDrawer from './MenuDrawer';
import ExpiryOverlay from 'pages/Expiry/ExpiryOverlay';
import ForgeMenuButton from './ForgeMenuButton';
import ForgeHelpIconSelector from './ForgeHelpIconSelector';
import TylerForgeOmniBarNotification from './TylerOmniBarNotification';
import ForgeProfileButton from './ForgeProfileButton';
import {
  FORECAST_PAGE_TYPES, HEADER_THEME_COLOR,
  KEY_ON_BOARD_WIZARD,
  OVERTIME_VISUALIZATION_TYPES, VISUALIZATION_TYPES
} from 'appConstants';
import {
  getBrandingLogoUrl,
  getHeaderBarTheme,
  showInsight,
  showAppWarningBanner,
  getAppExpirationWarningMessage,
  isPrivate,
  getAdvanceSearchName,
  showAdvanceSearch,
  getRadarOptions
} from 'common/config/customerConfiguration';
import { addhttp } from 'helpers/HttpHelper';
import {
  getAxisGranularity,
  getDefaultFutureForecastRange,
  getForecastDateRange,
  storeFromPath
} from 'pages/Forecasting/ForecastHelper';
import {
  updateForecastDateRange,
  updateFutureForecastDateRange,
  updateForecastPageView
} from 'actions/forecastingActions';
import { updateAxisGranularity, updateOvertimeChartView } from 'actions/overtimeActions';
import { updateUserMenu } from 'actions/managePageActions';
import {
  setCurrentVisualizationType,
  updateTemplateEntryChange
} from 'actions/drilldownActions';
import { updateHistoryTabHash } from 'actions/insightActions';
import { setBodyClassBasedOnHelpIconClick } from 'helpers/DomPageHelper';
import { removeLocalAndSessionStorage } from 'helpers/localStorageHelper';
import { removeRadarSessionStorage } from 'pages/Radar/radarSessionStorageHelper';
import { updateDateRange } from 'actions/commonFiltersActions';
import HeaderSearch from './HeaderSearch';
import { updateSelectedAreaName } from 'actions/radarActions';
import AppNavigation from '../AppNavigation/AppNavigation';

const TylerForgeOmniBar = ({ currentUser, title }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const forgeAppBarRef = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const minDatesTemplateForForecast = useSelector(
    state => _.get(state, 'forecasting.minDatesTemplateForForecast', null));

  const user = _.get(currentUser, 'user');
  const headerTheme = HEADER_THEME_COLOR.WHITE == getHeaderBarTheme ? 'white' : 'primary';
  const isAdvancedSearchPage = location.pathname === '/advanced-search';
  const headerTitle = isAdvancedSearchPage && showAdvanceSearch() ? getAdvanceSearchName() : title;


  useEffect(() => {
    setAppBarTitleClick();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setBodyClassBasedOnHelpIconClick(event);
  }

  const setAppBarTitleClick = () => {
    const forgeAppBar = forgeAppBarRef.current;
    if (forgeAppBar) {
      const forgeAppBarTitle = $('slot[name="title"]', forgeAppBar.shadowRoot);
      forgeAppBarTitle.css('cursor', 'pointer');
      forgeAppBarTitle.on('click', () => {
        onLogoClick();
      });
    }
  }

  const onClickSignOutButton = () => {
    removeLocalAndSessionStorage(KEY_ON_BOARD_WIZARD);
    localStorage.clear();
    window.location.href = '/auth/signout';
  }

  const onSignInButtonClick = () => {
    window.location.href = '/auth/login';
  }

  const onClickInsight = () => {
    const tabName = location.hash.split('?')[0];
    if (tabName !== '#!/insights' && tabName) {
      const pageName = _.get(location.hash.split('#!'), '1', '/');
      dispatch(updateHistoryTabHash(pageName));
    }
    history.push('/insights');
  }

  const onListOrButtonClick = (page, to, isProfilePage = false, templateOption = {}) => {
    dispatch(updateUserMenu(page));
    if (!_.isEmpty(templateOption)) {
      const { templateEntry, viewEntry } = templateOption;
      if (!_.isEmpty(viewEntry) && !_.isEmpty(templateEntry)) {
        onUpdateForecastDateRange(templateEntry);
        dispatch(updateTemplateEntryChange(templateEntry, viewEntry));
        dispatch(updateForecastPageView(FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE));
        history.push('/forecasting-metrics');
        dispatch(updateOvertimeChartView(OVERTIME_VISUALIZATION_TYPES.TIMELINE.type));
        dispatch(setCurrentVisualizationType(VISUALIZATION_TYPES.OVERTIME.type))
      }
    }
    if (page == 'radar') {
      const radarOptions = getRadarOptions();
      const { selectAreaName, comparisonAreaName } = radarOptions;
      dispatch(updateSelectedAreaName('selectAreaName', selectAreaName));
      dispatch(updateSelectedAreaName('comparisonAreaName', comparisonAreaName));
    }
    removeRadarSessionStorage();
    if (!isProfilePage) {
      setShowDrawer(!showDrawer);
    }
    setTimeout(() => {
      history.push(to);
    }, 100);
  }


  const onUpdateForecastDateRange = (templateEntry) => {
    const templateId = _.get(templateEntry, 'template_id');
    const newDateRanges = getForecastDateRange({
      templateId,
      minDatesTemplateForForecast
    });
    const defaultFutureForecastRange = getDefaultFutureForecastRange();
    dispatch(updateFutureForecastDateRange(defaultFutureForecastRange))
    if (!_.isEmpty(newDateRanges)) {
      const updateNewDateRange = {
        dateRange: _.cloneDeep(newDateRanges)
      }
      dispatch(updateDateRange(newDateRanges))
      dispatch(updateForecastDateRange(updateNewDateRange))
      const axisGranularity = getAxisGranularity(newDateRanges);
      dispatch(updateAxisGranularity(axisGranularity))
    }
    const locationHash = location.hash;
    const isAnalysisPage = _.includes(locationHash, '!/analysis');
    if (isAnalysisPage) {
      storeFromPath('/analysis');
    } else {
      storeFromPath('/overview');
    }
  }

  const onMenuClick = () => {
    setShowDrawer(!showDrawer);
  };

  const onHelpItemSelect = (to) => {
    setTimeout(() => {
      history.push(to);
    }, 100);
  }

  const onLogoClick = () => {
    window.location.href = addhttp(window.location.host);
  }

  const renderSignInButton = () => {
    const isPublicApp = !isPrivate();
    return (
      <>
        {renderHeaderLogo()}
        {isPublicApp && renderInsightIcon()}
        {isPublicApp && <ForgeHelpIconSelector onSelect={onHelpItemSelect} currentUser={currentUser} />}
        <ForgeButton slot="end" onClick={onSignInButtonClick}>
          <button type="button">Sign In</button>
        </ForgeButton>
      </>
    );
  }
  const onLogoFailure = () =>{
    const img = document.getElementById('branding-logo');
    img.src = '../images/tyler.png'
  }

  const renderHeaderLogo = () => {
    let brandingLogo = (
      <ForgeIcon slot="logo"
        className="logo" name="tyler_talking_t_logo" onClick={onLogoClick} />);
    if (!_.isEmpty(getBrandingLogoUrl)) {
      brandingLogo = (
        <div slot='logo' className='omnibar-logo'>
          <img id="branding-logo" src={getBrandingLogoUrl}
            alt="BrandingLogo" className='h-100' onClick={onLogoClick}
            onError={onLogoFailure}/>
        </div>
      );
    }
    return brandingLogo
  }

  const renderInsightIcon = () => {
    return showInsight() &&
      (<>
        <ForgeIconButton slot="end" className="insights">
          <button
            onClick={onClickInsight}
            aria-label="Insights"
            tabIndex={0}
            type="button">
            <ForgeIcon name="lightbulb" />
          </button>
        </ForgeIconButton>
        <div className="separater" slot="end"></div>
      </>
      );
  }

  const renderAppHeaderContent = () => {
    return (
      <>
        <ForgeMenuButton onClick={onMenuClick} />
        {renderHeaderLogo()}
        {renderInsightIcon()}
        <HeaderSearch/>
        <ForgeHelpIconSelector onSelect={onHelpItemSelect} currentUser={currentUser} />
        <TylerForgeOmniBarNotification />
        <AppNavigation />
        <ForgeProfileButton
          currentUser={currentUser}
          onSignOutBtnClick={onClickSignOutButton}
          onProfileBtnClick={onListOrButtonClick}
        />
      </>
    );
  };


  const renderExpiryOverlay =()=> {
    const expiryMessage = getAppExpirationWarningMessage();
    return (showAppWarningBanner() &&<ExpiryOverlay expiryMessage={expiryMessage} />);
  }

  return (
    <>
      <div className='stacked-headers'>
        <ForgeAppBar title-text={headerTitle} logo={true} className="tyler-forge-app-bar"
          ref={forgeAppBarRef} theme={headerTheme}>
          {!_.isEmpty(user) ? renderAppHeaderContent() : renderSignInButton()}
        </ForgeAppBar>
      </div>
      {renderExpiryOverlay()}
      <MenuDrawer
        disableForecastLink={_.isNull(minDatesTemplateForForecast)}
        showDrawer={showDrawer}
        onDrawerClose={onMenuClick}
        onListItemClick={onListOrButtonClick} />
    </>
  )
}

TylerForgeOmniBar.propTypes = {
  title: PropTypes.string,
  currentUser: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  isAdminUser: PropTypes.bool,
}


export default TylerForgeOmniBar
