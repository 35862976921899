import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ForgeIconButton, ForgeIcon, ForgeButton } from '@tylertech/forge-react';

import { createRecipients } from 'common/api/recipientsApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import SubscriptionFrequencyOptions from './SubscriptionFrequencyOptions';
import { subscriptionDefaultParams } from './subscriptionHelper';

import { trackEvent } from 'helpers/eventTracking';
import {
  createCollectionSubscription,
  updateSubscription
} from 'common/api/subscriptionApi';
import { subscriptionConfirmationEmail } from 'common/api/commonApi';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  subscriptionCreatedMessage,
  subscriptionCreatedErrorMessage,
  subscriptionEmailErrorMessage
} from 'helpers/toastMessages';
import WatchCount from 'common/components/WatchCount';
import {
  isManageCollections,
  isCollaborateCollection } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import { isUserCreatedCollection } from 'pages/dashboard/components/Collections/collectionHelper';
import Recipients from 'pages/SubscriptionsManager/components/Recipients';
import { updateCollection } from 'common/api/collectionApi';
import {
  addCollaborateCollectionUser,
  updateCollaborateCollectionUserFromSubscription } from 'common/api/collaborateApi';
import WatchCollectionDialog from './WatchCollectionDialog';
class WatchCollection extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showNotifyDropDown: false,
      description: "",
      isLoading: false,
      showNotifyEmailOptionDropDown: false,
      currentSubscription: {},
      isUndoClicked: false,
      isMonthlyFrequency: false,
      showFilterContent: false,
      isRecipientsEmailError: false,
      recipientsEmails: [],
      updateWatchCount: false,
      subscriptionParams: {}
    };
    this.abortFetchController = new AbortController();
    this.notifyTimer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentCollection } = this.props;
    const {
      showNotifyDropDown,
      showNotifyEmailOptionDropDown,
      isUndoClicked
    } = this.state;
    const isModalClosed = (!showNotifyDropDown && !showNotifyEmailOptionDropDown)

    if((
        (prevState.showNotifyDropDown && isModalClosed) ||
        (prevState.showNotifyEmailOptionDropDown && isModalClosed)
      ) && !(prevState.isUndoClicked || isUndoClicked)){
        this.sendSubscriptionEmail();
      }

    if (!_.isEqual(currentCollection, prevProps.currentCollection)){
      this.setState({ recipientsEmails: [] })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const isClickWithinCalendarPicker = $(e.target).closest("forge-calendar").length > 0;
    if (this.NotifyButtonRef && !this.NotifyButtonRef.contains(e.target) && !isClickWithinCalendarPicker) {
      this.setState({
        showNotifyDropDown: false,
        showNotifyEmailOptionDropDown: false,
        isRecipientsEmailError: false,
        recipientsEmails: [],
      });
    }
  }

  enableNotifyModalTimeout = () => {
    this.notifyTimer = setTimeout(() => {
      if (!_.isEmpty(this.state.currentSubscription)) {
        trackEvent('confirm_collection_subscribe');
      }
      this.setState({showNotifyDropDown: false})
    }, 10000);
  }

  cancelNotifyTimeOut = () => {
    clearInterval(this.notifyTimer)
  }

  handlePressedUndoWatch = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleUndoWatch();
    }
  }

  handleUndoWatch = () => {
    this.setState({
      showNotifyDropDown: false,
      showNotifyEmailOptionDropDown: false,
      isUndoClicked: false,
    })
  }

  isCollaborateSubscription = () => {
    const { currentUser } = this.props;
    const { recipientsEmails } = this.state;
    const isOnlyCurrentUser =  recipientsEmails.length == 0 ||
      (_.isEqual(_.first(recipientsEmails), currentUser['email']) &&
      recipientsEmails.length == 1);
    return isManageCollections() && !isOnlyCurrentUser;
  };

  handleMoreOptionSaveClick = (frequencyOptions) => {
    const { currentSubscription, subscriptionParams } = this.state;
    const isCollaborateSubscription = this.isCollaborateSubscription();
    if (!_.isEmpty(currentSubscription)){
      this.handelUpdateSubscription(frequencyOptions);
    } else {
      const initialSubscriptionParams = _.merge(
        {}, subscriptionParams, frequencyOptions, {isCollaborateSubscription});
      this.handleCreateWatchSubscription(initialSubscriptionParams);
    }
  }

  handelUpdateSubscription = (frequencyOptions) => {
    const { currentSubscription, recipientsEmails } = this.state;
    const collectionId = _.get(currentSubscription, 'collection_id');
    const isCollaborateSubscription = this.isCollaborateSubscription();
    const params = {
      id: _.get(currentSubscription, 'id'),
      name: _.get(currentSubscription, 'name'),
      includeImage: true,
      sendEmail: true,
      ...frequencyOptions,
      isCollaborateSubscription
    }
    this.setState({ isLoading: true });
    if(isCollaborateSubscription){
      createRecipients({
        alert_id: _.get(currentSubscription, 'id'),
        alert_type: 'subscription',
        recipients: recipientsEmails
      });
      this.updateCollectionToCollaborateCollection();
    }
    updateSubscription(collectionId, params)
      .then((response) => response.json())
      .then((parsedResponse) => {
        this.setState({
          isLoading: false,
          currentSubscription: parsedResponse,
          showNotifyEmailOptionDropDown: false,
          isMonthlyFrequency: false,
          showNotifyDropDown: false,
          isRecipientsEmailError: false,
          updateWatchCount: true,
        });
        this.enableNotifyModalTimeout();
      })
      .catch((err) => {
        this.setState({ isLoading: false,
          isRecipientsEmailError: false });
        toast.error(`Error while editing view. ${err}`);
        console.error(err); // eslint-disable-line no-console
      });
  }

  //  NOTE: While creating collaborate subscriptions for user created collection,
  //  changing the collection to collaborate collection.
  updateCollectionToCollaborateCollection = async () => {
    const { currentCollection, currentUser, onCollaborateCollectionChange } = this.props;
    const { recipientsEmails } = this.state;
    this.setState({ isLoading: true });
    const collaborateCollectionUsers = recipientsEmails.
          filter(recipientEmail => recipientEmail !== _.get(currentUser, 'email', ''));
    // If the collection is user created, then change it to a collaborator collection.
    if (isUserCreatedCollection(currentCollection) && !_.isEmpty(currentUser)) {
      try {
        // Update collection to collaborator collection.
        currentCollection.is_collaborate_collection = true;
        currentCollection.manage_collaborate_updated_at = new Date().toISOString();
        const updatedCollectionResponse = await updateCollection(currentCollection);
        const updatedCollection = await updatedCollectionResponse.json();
         // Trigger the callback function with the updated collection.
          onCollaborateCollectionChange(updatedCollection);
          this.setState({ isLoading: true });
        // Add collaborators to the collection.
        const params = { collaborateCollectionUsers };
        const addCollaboratorsResponse = await addCollaborateCollectionUser(currentCollection.id, params);
        const addCollaboratorsResult = await addCollaboratorsResponse.json();

        if (addCollaboratorsResult.errors) {
          addCollaboratorsResult.errors.forEach(error => {
            toast.error(error[0]);
          });
        } else {
          toast.success('User collection changed to collaborator collection.');
        }
        this.setState({ isLoading: false });

      } catch (error) {
        console.error('Error updating collection to collaborator collection:', error);
        this.setState({ isLoading: false });
      }
    } else if (isCollaborateCollection(currentCollection) && !_.isEmpty(currentUser)) {
        const params = { collaborateCollectionUsers };
        const updateCollaboratorsResponse = await
          updateCollaborateCollectionUserFromSubscription(currentCollection.id, params);
        const updateCollaboratorsResult = await updateCollaboratorsResponse.json();
        if (updateCollaboratorsResult.errors) {
          updateCollaboratorsResult.errors.forEach(error => {
            toast.error(error[0]);
          });
        } else {
          toast.success('Collaborator Users list Updated.');
        }
        this.setState({ isLoading: false });
    }
  };

  handleWatchSubscription = () => {
    const { currentCollection } = this.props;

    this.setState({
      showNotifyDropDown: true,
      subscriptionParams: subscriptionDefaultParams(currentCollection)
    });
  }

  handleCreateWatchSubscription = (initialSubscriptionParams = {}) => {
    const { recipientsEmails } = this.state;
    const { currentCollection } = this.props;
    const collectionId = _.get(currentCollection, 'id');
    const isCollaborateSubscription = this.isCollaborateSubscription();
    const subscriptionParams = _.isEmpty(initialSubscriptionParams) ?
      subscriptionDefaultParams(currentCollection) : initialSubscriptionParams;
    this.setState({ isLoading: true, updateWatchCount: false });
    trackEvent('initiate_collection_subscribe');
    createCollectionSubscription(collectionId, subscriptionParams)
      .then((response) => response.json())
      .then((parsedResponse) => {
        this.setState({
          isLoading: false,
          showNotifyEmailOptionDropDown: false,
          isMonthlyFrequency: false,
          showNotifyDropDown: false,
          updateWatchCount: true,
          currentSubscription: parsedResponse
        });

        if(isCollaborateSubscription){
          createRecipients({
            alert_id: _.get(parsedResponse, 'id'),
            alert_type: 'subscription',
            recipients: recipientsEmails
          });
          this.updateCollectionToCollaborateCollection();
        }

        this.enableNotifyModalTimeout();
    })
    .catch((err) => {
      this.setState({ isLoading: false });
      toast.error(subscriptionCreatedErrorMessage);
      console.error(`Error while creating subscription`, err); // eslint-disable-line no-console
    });
  }

  getCurrentSubscription = () => {
    const { currentSubscription, subscriptionParams } = this.state;

    const newSubscriptionParams = {
      frequency: _.get(subscriptionParams,'frequency'),
      frequency_interval: _.get(subscriptionParams,'frequencyInterval'),
      week_number_for_month: _.get(subscriptionParams, 'monthlyWeekNumber'),
      email_sending_time: _.get(subscriptionParams, 'emailSendingTime'),
      time_zone: _.get(subscriptionParams, 'timeZone'),
      email_sending_days: _.get(subscriptionParams, 'emailSendingDays'),
      start_date: _.get(subscriptionParams,'startDate'),
      regularity_type: _.get(subscriptionParams, 'regularityType'),
      time_period_day: _.get(subscriptionParams, 'timePeriodDay')
    };

    return _.isEmpty(currentSubscription) ? newSubscriptionParams : currentSubscription;
  }

  sendSubscriptionEmail = () => {
    const { currentUser } = this.props;
    const { currentSubscription } = this.state;

    if (_.isEmpty(currentSubscription)) {
      return;
    }

    const subscriptionEmailParams = {
      ...currentSubscription,
      user_email: _.get(currentUser, 'email', '')
    };
    toast.success(subscriptionCreatedMessage);
    subscriptionConfirmationEmail(subscriptionEmailParams)
      .then((response) => {
        if (response.status === 500 || !response.ok) {
          toast.error(subscriptionEmailErrorMessage);
        }
        this.setState({ isLoading: false, currentSubscription: {} });
      })
      .catch((err) => {
        this.setState({ isLoading: false, currentSubscription: {} });
        console.error(err); // eslint-disable-line no-console
      });
  };

  onCloseNotify = () => {
    const { showNotifyEmailOptionDropDown } = this.state;

    if(showNotifyEmailOptionDropDown) {
      trackEvent('confirm_collection_subscribe');
    }
    this.setState({
      showNotifyDropDown: false,
      showNotifyEmailOptionDropDown: false,
      isRecipientsEmailError: false,
      recipientsEmails: [],
    });
  }

  handleMoreOptionsClick = () => {
    this.setState({
      showNotifyEmailOptionDropDown: true,
      showNotifyDropDown: false,
      updateWatchCount: false
    });
    this.cancelNotifyTimeOut();
  }

  onEmailUpdate = (emails, isError) => {
    this.setState({
      isRecipientsEmailError: isError ||_.isEmpty(emails),
      recipientsEmails: emails
    });
  }

  onFreqOptionsChange = (emailsOptions) => {
    const isMonthlyFrequency = (_.get(emailsOptions, 'frequency', '') === 'month');
    this.setState({ isMonthlyFrequency });
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  renderRecipientsSection() {
    const { recipientsEmails } = this.state;
    const {
      currentUser,
      allowedUserRoles
    } = this.props;

    if (!isManageCollections()) {
      return null;
    }

    return (
      <Recipients
        currentUser={currentUser}
        editMode={!_.isEmpty(recipientsEmails)}
        recipientsEmails={recipientsEmails}
        allowedUserRoles={allowedUserRoles}
        onEmailUpdate={this.onEmailUpdate}
      />
    );
  }

  renderNotifyOptionsDropdown(){
    const { showNotifyEmailOptionDropDown, isRecipientsEmailError } = this.state;
    if(!showNotifyEmailOptionDropDown){
      return null;
    }
    const optionClassName = classNames('show-notify-email-option', {
      'is-show': showNotifyEmailOptionDropDown
    });
    return(
      <div className={optionClassName}>
        {this.renderRecipientsSection()}
        <SubscriptionFrequencyOptions
          onCancel={this.handleUndoWatch}
          isRecipientsEmailError={isRecipientsEmailError}
          currentSubscription={this.getCurrentSubscription()}
          onSaveClick={this.handleMoreOptionSaveClick}
          onFreqOptionsChange={this.onFreqOptionsChange}/>
      </div>
    )
  }

  renderWatchDialog = () => {
    const { showNotifyDropDown,currentSubscription, subscriptionParams } = this.state;
    if(!showNotifyDropDown) {
      return null;
    }

    const watchDialogOptions = {
      isLoading: this.state.isLoading,
      subscriptionParams,
      currentSubscription,
      onHandleUndoWatch: this.handleUndoWatch,
      onHandlePressedUndoWatch: this.handlePressedUndoWatch,
      onHandleMoreOptionsClick: this.handleMoreOptionsClick,
      onHandleCreateWatchSubscription: this.handleCreateWatchSubscription
    }

    return(
      <WatchCollectionDialog watchDialogOptions={watchDialogOptions} />
    )
  }

  handleWatchClick = () => {
    const { showFilterContent } = this.state;
    this.setState({ showFilterContent: !showFilterContent });
  };

  renderWatchButton(){
    const { currentCollection } = this.props;
    const { showNotifyDropDown, showNotifyEmailOptionDropDown, updateWatchCount } = this.state;
    const watchClassName = classNames({
      'watch-btn-group': isManageCollections() && !isUserCreatedCollection(currentCollection)
    });

    return(
      <div className={watchClassName}>
        <ForgeButton type="outlined">
          <button
            disabled={showNotifyDropDown || showNotifyEmailOptionDropDown}
            variant="outline-primary"
            className="collection-watch-btn"
            onClick={() => this.handleWatchSubscription()}>
            Watch
          </button>
        </ForgeButton>
        <WatchCount
          collection={currentCollection}
          updateWatchCount={updateWatchCount}
          type="collection">
        </WatchCount>
      </div>
    )
  }

  render() {
    const {
      showNotifyEmailOptionDropDown,
      showNotifyDropDown,
      isMonthlyFrequency
    } = this.state;
    const showNotifyClass = classNames('bookmark-dropdown-wrapper dropdown-menu', {
      'show notify-msg': showNotifyDropDown,
      'show show-email-option': showNotifyEmailOptionDropDown,
      'monthly-frequency' : isMonthlyFrequency
    });

    return(
      <div>
        <div className="d-flex bookmark-notify-options" ref={(ref) => this.NotifyButtonRef = ref}>
          {this.renderSpinner()}
          {this.renderWatchButton()}
          <div className={showNotifyClass}>
            <div className="dropdown-header">
              <ForgeIconButton dense="true" densityLevel="5" className="ml-auto">
                <button
                  aria-label="Close"
                  tabIndex={0}
                  onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}
                  onClick={this.onCloseNotify}
                >
                  <ForgeIcon name="close" />
                </button>
              </ForgeIconButton>
            </div>
            {this.renderWatchDialog()}
            {this.renderNotifyOptionsDropdown()}
          </div>
        </div>
      </div>
    )
  }
}

WatchCollection.propTypes = {
  currentUser: PropTypes.object,
  allowedUserRoles: PropTypes.array,
  onCollaborateCollectionChange: PropTypes.func,
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
    is_collaborate_collection: PropTypes.bool,
    manage_collaborate_updated_at: PropTypes.string,
  })
}

export default WatchCollection;
