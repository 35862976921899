import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import TemplateCard, {
  getContainerClassNames as getTemplateCardContainerClassNames,
} from '../TemplateCards/TemplateCard';
import BookmarkCard, {
  getContainerClassNames as getBookmarkContainerCardClassNames,
} from '../BookmarkCards/BookmarkCard';
import CardDrag from './CardDrag';
import CardDrop from './CardDrop';
import { CARD_TYPES, COLLECTION_TYPES } from 'appConstants';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import { updateCardsOrder } from 'common/api/collectionApi';
import { deleteBookmark, updateBookmarksOrder } from 'common/api/bookmarksApi';
import { templateEntriesPropTypes } from 'common/propTypes';
import {
  getCollectionCardEntries,
  updateCardEntriesOrder,
  getTemplateCardCommonFilters
} from 'pages/dashboard/components/Collections/collectionHelper';
import {
  formatCardEntry,
  getTemplateAndViewEntryFromBookmark,
  getDateFilters,
  isMyViews
} from './cardHelper';
import RadarCard from '../RadarCards/RadarCard';
import ExplorationCard from 'common/components/ExplorationCards/ExplorationCard';
import ForecastCard from '../ForecastCard/ForecastCard';
import CustomAdvanceSearchCard from '../AdvanceSearchCards/CustomAdvanceSearchCard';
import { viewDeleteMessage, viewDeleteErrorMessage } from 'helpers/toastMessages';
import { toast } from 'react-toastify';
import { isCollectionViewAccess } from '../ManageCollection/collaboratorHelper';
import { getFilterWithCompareBookmarkFilter, getRadarGlobalFilterByMetric } from 'pages/Radar/radarHelper';

class Card extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.radarCardRef = React.createRef();
  }

  handleTemplateCardClick = (
    templateEntry, viewEntry, drilldownEntry, commonFilters, event, isNewCard = false
  ) => {
    const { cardType, onCardClick, globalFilters } = this.props;
    const visualizationChartContent = event.target.closest('.visualization-charts');

    if(visualizationChartContent && isMobileOrTablet()) {
      return;
    }
    //To prevent click from attribution .
    if(event.target.tagName !== 'A'){
      onCardClick(cardType, {
        templateEntry,
        viewEntry,
        drilldownEntry,
        globalFilters,
        commonFilters,
        isNewCard
      });
    }
  };

  handleBookmarkCardClick = (bookmarkConfig, event, defaultCommonFilters = {}) => {
    const { cardType, onCardClick } = this.props;
    const visualizationChartContent = event.target.closest('.visualization-charts');

    if(visualizationChartContent && isMobileOrTablet()) {
      return;
    }
    //To prevent click from attribution .
    if(event.target.tagName !== 'A'){
      onCardClick(cardType, bookmarkConfig, defaultCommonFilters);
    }
  };

  handleCardMove = ({ targetCard, draggedCard, insertAfter }) => {
    const { collectionEntry } = this.props;
    if(isMyViews(collectionEntry)){
      this.bookmarkCardPositionChange(targetCard, draggedCard, insertAfter)
    }else{
      this.collectionCardPositionChange(targetCard, draggedCard, insertAfter);
    }
  }

  collectionCardPositionChange = (targetCard, draggedCard, insertAfter) => {
    const { templateEntries, userCards, onCardMove, bookmarks } = this.props;

    const newUserCards = updateCardEntriesOrder(
      targetCard,
      draggedCard,
      userCards,
      insertAfter
    );
    const newCardEntries = getCollectionCardEntries(
      newUserCards,
      templateEntries,
      bookmarks
    );
    onCardMove({
      cardEntries: newCardEntries,
      orderedUserCards: newUserCards,
      type: 'collection'
    });

    if(_.get(draggedCard, 'isMetricLibrary', false)) {
      return;
    }
    const collectionId = _.get(newUserCards, '[0].collection_id');
    updateCardsOrder(collectionId, newUserCards);
  }

  getTemplateCommonFilters = (cardEntry, newCommonFilters) => {
    const commonFilters = _.isEmpty(newCommonFilters) ? _.get(this.props, 'commonFilters') : newCommonFilters;
    const filterOptions = _.pick(this.props, 'globalFilters', 'collectionEntry');
    return getTemplateCardCommonFilters({ ...filterOptions, cardEntry, commonFilters });
  }

  bookmarkCardPositionChange = (targetCard, draggedCard, insertAfter) => {
    const { cardEntries, onCardMove } = this.props;
    const orderedBookmarks = updateCardEntriesOrder(
      targetCard,
      draggedCard,
      cardEntries,
      insertAfter
    );
    onCardMove({cardEntries: orderedBookmarks, type: 'bookmark'});
    updateBookmarksOrder(orderedBookmarks);
  }

  onCardDelete = (card) => {
    const { cardType, onCardDelete, cardEntry }= this.props;

    if (cardType == CARD_TYPES.BOOKMARK) {
      deleteBookmark(cardEntry.id)
        .then((response) => {
          if (response.ok) {
            this.props.reloadBookmarks(cardEntry);
            toast.success(viewDeleteMessage);
          } else {
            toast.error(viewDeleteErrorMessage);
          }
        })
        .catch((error) => {
          console.log('Error on deleting saved view', error);
          toast.error(viewDeleteErrorMessage);
        });
    } else {
      onCardDelete(card);
    }
  }

  renderCard() {
    const {
      cardEntries,
      newLabelCards,
      cardEntry,
      cardType,
      commonFilters,
      isLoading,
      currentUser,
      index,
      onCardMove,
      reloadBookmarks,
      onCardDelete,
      collectionEntry,
      globalFilters
    } = this.props;
    const collectionType = _.get(collectionEntry, 'type');
    const isMyCollection = _.get(collectionEntry, 'is_my_view_collection', false);
    if (cardType == CARD_TYPES.BOOKMARK) {
      const { currentDrilldownTemplateId } = _.get(cardEntry, 'drilldown', {});
      const newCardEntry = formatCardEntry({
        cardEntry, cardEntries, globalFilters, collectionEntry, commonFilters
      });
      const { viewEntry, templateEntry } = getTemplateAndViewEntryFromBookmark(cardEntry);
      const templateCommonFilters = this.getTemplateCommonFilters(newCardEntry);
      const dateFilters = getDateFilters(newCardEntry, collectionEntry, commonFilters, templateCommonFilters);
      const radarBookmarkGlobalFilters = _.get(newCardEntry, 'radarOptions.metricGlobalFilters');
      // const collectionDateFilters = _.get(collectionEntry, 'dateFilters', {});
      const radarGlobalFilters =  getRadarGlobalFilterByMetric(cardEntry, this.props.globalFilters);
      const newGlobalFilters = getFilterWithCompareBookmarkFilter(
        radarGlobalFilters, radarBookmarkGlobalFilters);
      const isNewCard = _.get(cardEntry, 'is_new', false);
      if(cardEntry['is_radar']){
        return(
          <RadarCard
            isNewCard={isNewCard}
            onCardClick={this.handleBookmarkCardClick}
            newLabelCards={newLabelCards}
            key="radar-card"
            onCardDelete={onCardDelete}
            userCardEntry={newCardEntry}
            isRadarBookmark={true}
            isMyCollection={isMyCollection}
            reloadBookmarks={reloadBookmarks}
            commonFilters={dateFilters}
            radarGlobalFilter ={newGlobalFilters}
            isUserCard={collectionType == COLLECTION_TYPES.USER}
            ref={this.radarCardRef}
            cardEntries={cardEntries}
            collectionEntry={collectionEntry} />
        )
      }else if(cardEntry['is_forecast']){
        return(
          <ForecastCard
            isNewCard={isNewCard}
            onCardClick={this.handleBookmarkCardClick}
            key={"forecast-card-"+cardEntry.id}
            commonFilters={commonFilters}
            onCardDelete={this.onCardDelete}
            userCardEntry={newCardEntry}
            viewEntry={viewEntry}
            templateEntry={templateEntry}
            isRadarBookmark={true}
            isMyCollection={isMyCollection}
            reloadBookmarks={reloadBookmarks}
            isUserCard={collectionType == COLLECTION_TYPES.USER} />
        )
      }
      else if(cardEntry['is_custom_advance_search']){
        return(
          <CustomAdvanceSearchCard
            isNewCard={isNewCard}
            onCardClick={this.handleBookmarkCardClick}
            key={"forecast-card-"+cardEntry.id}
            commonFilters={commonFilters}
            onCardDelete={this.onCardDelete}
            userCardEntry={newCardEntry}
            viewEntry={viewEntry}
            templateEntry={templateEntry}
            isRadarBookmark={true}
            isMyCollection={isMyCollection}
            reloadBookmarks={reloadBookmarks}
            isUserCard={collectionType == COLLECTION_TYPES.USER} />
        )
      }
      return (
        <BookmarkCard
          templateEntry={templateEntry}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          globalFilters={globalFilters}
          onCardDelete={onCardDelete}
          cardEntries={cardEntries}
          currentUser={currentUser}
          cardEntry={newCardEntry}
          collectionEntry={collectionEntry}
          currentDrilldownViewEntry={viewEntry}
          onCardClick={this.handleBookmarkCardClick}
          dateFilters={dateFilters}
          onBookmarkLoading={isLoading}
          reloadBookmarks={reloadBookmarks}
          index={index}
          isEmbed={false}
        />
      );
    } else if (_.get(cardEntry, 'viewEntry.isRadar')) {
      const radarCommonDateFilters = this.getTemplateCommonFilters(cardEntry);
      const radarGlobalFilters = getRadarGlobalFilterByMetric(cardEntry, this.props.globalFilters);
      return(
        <RadarCard
          newLabelCards={newLabelCards}
          key="radar-card"
          onCardDelete={this.onCardDelete}
          userCardEntry={_.get(cardEntry, 'userCardEntry')}
          commonFilters={radarCommonDateFilters}
          radarGlobalFilter ={radarGlobalFilters}
          isUserCard={collectionType == COLLECTION_TYPES.USER}
          ref={this.radarCardRef} />
      );
    } else if(_.get(cardEntry, 'isExplorationCard')){
       return <ExplorationCard
          showDelete={_.get(cardEntry, 'hideDelete', true)}
          onCardDelete={onCardDelete}
          userCardEntry={_.get(cardEntry, 'userCardEntry')}
          explorationEntry={_.get(cardEntry, 'explorationCardEntry', {})}
          keyId={"exploration-card-"+cardEntry.id} />
    } else {
      const { templateEntry, viewEntry, userCardEntry } = cardEntry;
      const collectionDateFilters = _.get(collectionEntry, 'dateFilters', {});
      const dateFilters = (collectionType === COLLECTION_TYPES.BELLEROPHON) ?
        this.getTemplateCommonFilters(cardEntry, collectionDateFilters) :
        this.getTemplateCommonFilters(cardEntry);

      return (
        <TemplateCard
          collectionEntry={collectionEntry}
          newLabelCards={newLabelCards}
          globalFilters={globalFilters}
          onCardDelete={onCardDelete}
          currentUser={currentUser}
          userCardEntry={userCardEntry}
          cardEntry={templateEntry}
          viewEntry={viewEntry}
          index={index}
          onCardClick={this.handleTemplateCardClick}
          onCardMove={onCardMove}
          commonFilters={dateFilters}
          collectionDateFilters={collectionDateFilters}
          isUserCard={collectionType == COLLECTION_TYPES.USER}
          key={index}
        />
      );
    }
  }

  render() {
    const {
      index,
      cardEntry,
      cardType,
      collectionEntry,
      currentUser
    } = this.props;
    const { templateEntry, viewEntry, userCardEntry } = cardEntry;
    const canDrop = _.get(collectionEntry, 'type') === COLLECTION_TYPES.USER;
    const isExplorationCard = _.get(cardEntry, 'isExplorationCard', false)
    let containerClassNames = classNames(
      'card-container',
      cardType == CARD_TYPES.BOOKMARK
        ? getBookmarkContainerCardClassNames({ cardEntry })
        : isExplorationCard ? 'metric-tile' : getTemplateCardContainerClassNames({
            cardEntry: templateEntry,
            viewEntry: viewEntry,
          })
    );

    const isBookmarkCard = cardType == CARD_TYPES.BOOKMARK;
    const isForecastCard = cardEntry['is_forecast'];
    let content, cardProps;
    if (isBookmarkCard){
      cardProps = { userCardEntry: cardEntry };
    } else if(isExplorationCard){
      cardProps = { userCardEntry };
    }else{
      cardProps = { userCardEntry, viewEntry, templateEntry, collectionEntry };
    }

    if (_.isEmpty(currentUser) || isMobileOrTablet() ||
      isCollectionViewAccess(collectionEntry) || isForecastCard) {
      content = this.renderCard();
    } else if (canDrop || isBookmarkCard) {
      content = (
        <CardDrag {...cardProps}>
          <CardDrop {...cardProps} onCardMove={this.handleCardMove}></CardDrop>
          <CardDrop
            {...cardProps}
            onCardMove={this.handleCardMove}
            insertAfter
          ></CardDrop>
          {this.renderCard()}
        </CardDrag>
      );
    } else {
      content = <CardDrag {...cardProps}>{this.renderCard()}</CardDrag>;
    }

    return (
      <div className={containerClassNames} data-test={index} key={index}>
        {content}
      </div>
    );
  }
}

Card.propTypes = {
  cardType: PropTypes.oneOfType([CARD_TYPES.BOOKMARK, CARD_TYPES.TEMPLATE]),
  cardEntry: PropTypes.object,
  currentUser: PropTypes.object,
  onCardClick: PropTypes.func,
  onCardMove: PropTypes.func,
  newLabelCards: PropTypes.array,
  onCardDelete: PropTypes.func,
  reloadBookmarks: PropTypes.func,
  isLoading: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
  collectionEntry: PropTypes.object,
  commonFilters: PropTypes.object,
  globalFilters: PropTypes.array,
  userCards: PropTypes.array,
  cardEntries: PropTypes.array,
  bookmarks: PropTypes.array,
  templateEntries: templateEntriesPropTypes
};

export default Card;
