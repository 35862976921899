import React, { useState } from 'react';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import AppListModal from './AppListModal';
import { Fragment } from 'react';
import { enableOtherAppNavigation } from 'common/config/customerConfiguration';
import useFetchApplications from './useFetchApplications';

function AppNavigation() {
  const [isOpen, setOpen] = useState(false);

  if (!enableOtherAppNavigation()) {
    return null;
  }

  const { applications } = useFetchApplications();
  
  if (_.isEmpty(applications)) {
    return null;
  }

  const onClickComfyIcon = () => {
    setOpen(!isOpen);
  };

  return (
    <Fragment>
      <ForgeIconButton slot="end">
        <button id="button-app-list-container"
          onClick={onClickComfyIcon}
          aria-label="AppNavigation"
          tabIndex={0}
          type="button"
          className='app-nav-list'
        >
          <ForgeIcon name="apps" />
        </button>
        {isOpen && <AppListModal onClickComfyIcon={onClickComfyIcon}/>}
      </ForgeIconButton>

      <div className="separater" slot="end"></div>
    </Fragment>
  );
}

AppNavigation.propTypes = {};

export default AppNavigation;
