import React, { forwardRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { deleteBookmark, acceptBookmark } from 'common/api/bookmarksApi';
import { viewDeleteMessage, viewDeleteErrorMessage } from 'helpers/toastMessages';

import RadarCardHeader from './RadarCardHeader';
import MetricPercentageChangeSelector from './MetricPercentageChangeSelector';
import DateRangeInfoText from './DateRangeInfoText';
import CardFooter from './CardFooter';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';
import DeleteConfirmModal from '../DeleteConfirmModal';
import UseDataFetching from './UseDataFetching';
import { getRadarOptions, showRadar } from 'common/config/customerConfiguration';
import {
  getMetricWiseData,
  getRadarCustomizeDateRange,
  getRadarMetricFilterWithGlobalFilter
} from 'pages/Radar/radarHelper';
import { isClickInfoSection } from 'helpers/DomPageHelper';
import { getRadarCardId, showRadarCardNewLabel } from './helper';
import { updateCurrentTemplateCardId } from 'common/api/drilldown';
import { defaultRadarOptions } from 'reducers/radarReducer';
import {
  COLLECTION_CARD_DELETE_MESSAGE
} from 'appConstants';
import {
  updateCurrentDateRangeOptions,
  updatePreviousDateRangeOptions,
  updateRadarMetricGlobalFilters,
  updateSelectedAreaName
} from 'actions/radarActions';
import { useDispatch } from 'react-redux';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import classNames from 'classnames';
import RadarFilterIcon from './RadarFilterIcon';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
const RadarCard = forwardRef((
  { isUserCard, onCardDelete, reloadBookmarks,
    userCardEntry, newLabelCards, isRadarBookmark, onCardClick,
    isMyCollection, commonFilters, radarGlobalFilter, cardEntries, collectionEntry,
    isNewCard = false
  },
  ref
) => {
  let radarOptions = _.get(userCardEntry, 'radarOptions', defaultRadarOptions());
  radarOptions = updateRadarAttributeOptions(_.cloneDeep(radarOptions));
  const selectedMetricEntries = _.get(radarOptions, 'selectedMetricEntries', []);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const { loading, data } = UseDataFetching(radarOptions);
  const radarData = getMetricWiseData(data, selectedMetricEntries, true, true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCardDelete = () => {
    if (!isMyCollection) {
      onCardDelete(userCardEntry);
    } else {
      deleteBookmark(userCardEntry.id)
        .then((response) => {
          if (response.ok) {
            reloadBookmarks();
            toast.success(viewDeleteMessage);
          } else {
            toast.error(viewDeleteErrorMessage);
          }
        })
        .catch((error) => {
          console.log('Error on deleting saved view', error);
          toast.error(viewDeleteErrorMessage);
        });
    }
  }

  function updateRadarAttributeOptions(radarOptions) {
    if(!isRadarBookmark){
      const dateRangeOption = getRadarCustomizeDateRange({commonFilters});
      const { currentOptions, previousOptions } = dateRangeOption;
      if(!_.isEmpty(currentOptions) && !_.isEmpty(previousOptions)){
        radarOptions['currentDateRangeOptions'] = currentOptions;
        radarOptions['previousDateRangeOptions'] = previousOptions;
      }
    }
    radarOptions['metricFilters'] = getRadarMetricFilterWithGlobalFilter(
      radarOptions['metricFilters'], radarGlobalFilter);
    return radarOptions;
  }

  const handleClickMenuItems = () => {
    document.body.click();
  }

  const onClickCard = (e) => {
    if (isClickInfoSection(e)) {
      return;
    }
    const cardId = getRadarCardId();
    updateRadarDateRange();

    if (showRadarCardNewLabel(newLabelCards)) {
      // Update the radar card id in user card preference
      updateCurrentTemplateCardId(cardId).then((response) => {
        console.log('Radar card update!!', response);
      }).catch((err) => {
        console.error('Radar card update error', err)
      });
    }
    if (_.isFunction(onCardClick)) {
      const newUserCardEntry = isRadarBookmark ? updateBookmarkGlobalFilter(userCardEntry) : userCardEntry;
      onCardClick(newUserCardEntry, e);
    } else {
      const radarOptions = getRadarOptions();
      const { selectAreaName, comparisonAreaName } = radarOptions;
      dispatch(updateSelectedAreaName('selectAreaName', selectAreaName));
      dispatch(updateSelectedAreaName('comparisonAreaName', comparisonAreaName));
      history.push('/radar');
    }
  }

  const updateBookmarkGlobalFilter = (userCardEntry) => {
    let newUserCardEntry = _.cloneDeep(userCardEntry);
    if(!_.isEmpty(newUserCardEntry['radarOptions'])){
      newUserCardEntry['radarOptions']['metricGlobalFilters'] = radarGlobalFilter;
    }

    return newUserCardEntry;
  }

  const updateRadarDateRange = () => {
    dispatch(updateRadarMetricGlobalFilters(radarGlobalFilter));

    if(isRadarBookmark){
      return
    }

    const dateRangeOption = getRadarCustomizeDateRange({commonFilters});
    const { currentOptions, previousOptions } = dateRangeOption;

    if(!_.isEmpty(currentOptions)){
      dispatch(updateCurrentDateRangeOptions(currentOptions));

      if(!_.isEmpty(previousOptions)){
        dispatch(updatePreviousDateRangeOptions(previousOptions));
      }
    }
  }

  const onAcceptRadarBookmarkClick = (event) => {
    event.stopPropagation();
    acceptBookmark(userCardEntry.id)
      .then(() => {
        reloadBookmarks();
      })
      .catch((error) => {
        console.log('Error on accept bookmark', error);
      });
  };

  const renderPercentageChangeContent = (metricEntry) => {
    const { id, name, metricIndex } = metricEntry
    if (_.isEmpty(radarData)) {
      return null;
    }
    const metricPercentage = _.get(radarData[metricIndex], [id, 'change'])

    return (<MetricPercentageChangeSelector key={id} percentage={metricPercentage} name={name} />);
  }

  const renderMetricDetailsSection = () => {
    return (
      <div className='m-auto'>
        <div className='d-flex flex-column gap-20'>
          <DateRangeInfoText radarOptions={radarOptions} />
          {_.map(selectedMetricEntries, renderPercentageChangeContent)}
        </div>
      </div>
    )
  }

  const RadarShareInfo = () => {
    if (_.get(userCardEntry, 'is_shared_accepted', true)) {
      return null;
    }
    const email = _.get(userCardEntry, 'shared_user.email');
    const name = _.get(userCardEntry, 'shared_user.name');
    const sharer = _.isEmpty(name) ? email : name;

    return (
      <div className="mb-2">
        <div className="bookmarks-share-info">
          <i className="icons-star-in-circle text-success" />
          <span>
            <em>{sharer} shared this view with you.</em>
          </span>
        </div>
        <button
          onClick={(e) => onAcceptRadarBookmarkClick(e)}
          className="btn btn-sm text-primary align-baseline ml-1 p-0 ls-normal"
        >
          <u>Accept</u>
        </button>
      </div>
    );
  }

  const renderFilterItems = () => {
    return (
      <RadarFilterIcon
        radarOptions={radarOptions}
        radarGlobalFilter={radarGlobalFilter}
        cardEntries={cardEntries}
        collectionEntry={collectionEntry}
      />
    );
  }

  const renderCardBody = () => {
    const deleteMessage = COLLECTION_CARD_DELETE_MESSAGE;
    const radarHeaderClass = classNames('metric-head-wrapper', {
      'info-btn': (isUserCard || isMyCollection)
    });

    return (
      <div className='card-body d-flex flex-column h-100 forge-popup-host'>
        <RadarShareInfo />
        <div className={radarHeaderClass}>
          <RadarCardHeader
            isRadarBookmark={isRadarBookmark}
            cardEntry={userCardEntry}
            showNewLabel={showRadarCardNewLabel(newLabelCards) || isNewCard}
            onAcceptRadarBookmarkClick={onAcceptRadarBookmarkClick} />
          {(isUserCard || isMyCollection) && renderMenus()}
        </div>
        {renderFilterItems()}
        {renderMetricDetailsSection()}
        <CardFooter isRadarBookmark={isRadarBookmark} cardEntry={userCardEntry} />
        {
          isDeleteClicked &&
          <DeleteConfirmModal
            name='Remove'
            title="Remove Card"
            showButtons={false}
            message={deleteMessage}
            showMenuItems={handleClickMenuItems}
            onCancelConfirm={() => setIsDeleteClicked(false)}
            onDeleteConfirm={handleCardDelete}
          />
        }
      </div>
    );
  }

  const onKeyDownRadarCard = (e) => {
    if (isEnterButtonPressed(e)) {
      onClickCard(e);
    }
  }

  const handleMenuSelect = ({detail}) => {
    const { value } = detail;
    if (_.isEqual(value, 'delete')){
      setIsDeleteClicked(true);
    } else {
      const { id, name } = userCardEntry;
      const bookmarkType = getBookmarkType(userCardEntry);

      history.push({ pathname: "/app/subscriptions" });
      dispatch(updateCurrentBookmark(id+'', name, bookmarkType));
    }
  }

  const renderMenus = () => {

    let options = [
      { value: 'edit', label: 'Edit' },
      { value: 'delete', label: 'Remove Card' }
    ];

    if (!isMyCollection){
      options = _.reject(options, { 'value': 'edit' });
    }

    return (
      <div className="info-sections align-self-start ml-1">
        <ForgeMenu
          placement='bottom-start'
          options={options}
          on-forge-menu-select={handleMenuSelect}>
            <ForgeIconButton type="button">
              <button type="button">
              <ForgeIcon name='more_vert' className="more-icon" /></button>
            </ForgeIconButton>
        </ForgeMenu>

        {/* <Dropdown>
          <Dropdown.Toggle
            className="expand-btn btn-sm text-muted"
            id={'radar-card-custom' + userCardEntry.id}
            tabIndex={0}
            aria-label="Action"
          >
            <i className="icons-ellipsis-v"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {isMyCollection && <EditButtonForBookmark bookmark={userCardEntry} />}
            <Dropdown.Item className="card-remove" eventKey="2" as="RadarBookmarkDeleteButton">
              <DeleteConfirmModal
                name='Remove'
                title="Remove Card"
                message={deleteMessage}
                showMenuItems={handleClickMenuItems}
                onDeleteConfirm={handleCardDelete}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    );
  }

  return showRadar() && (
    <>
      <div className='card metric-card radar-metric-card' role="listitem"
        tabIndex={0}
        onClick={onClickCard}
        onKeyDown={(e) => onKeyDownRadarCard(e)}
        ref={ref}>
        {loading ? <LoadingSpinner isLoading={loading} /> : renderCardBody()}
      </div>
    </>
  );
});

RadarCard.defaultProps = {
  isUserCard: false,
  onCardDelete: _.noop,
  userCardEntry: {},
  newLabelCards: [],
  isRadarBookmark: false,
  isNewCard: false
}

RadarCard.propTypes = {
  isUserCard: PropTypes.bool,
  isRadarBookmark: PropTypes.bool,
  isMyCollection: PropTypes.bool,
  onCardDelete: PropTypes.func,
  onCardClick: PropTypes.func,
  userCardEntry: PropTypes.object,
  commonFilters: PropTypes.object,
  newLabelCards: PropTypes.array,
  radarGlobalFilter: PropTypes.array,
  reloadBookmarks: PropTypes.func,
  collectionEntry: PropTypes.object,
  cardEntries: PropTypes.array,
  isNewCard: PropTypes.bool
}

export default RadarCard;
