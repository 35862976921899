import { useState, useLayoutEffect } from 'react';

const useAppCardContainerHeight = (props) => {
  const { current: cardRefs } = props;
  const [cardContainerHeight, setCardContainerHeight] = useState(0);
  const NO_OF_CARDS = 15
  const CARDS_PER_ROW = 2.9

  useLayoutEffect(() => {
    if (!cardRefs) return;
    const cardHeights = _.chain(cardRefs)
      .slice(0, NO_OF_CARDS)
      .filter(ref => ref?.current)
      .map(ref => ref?.current?.clientHeight)
      .value();

    if (cardHeights.length === NO_OF_CARDS) {
      const averageHeight = _.sum(cardHeights) / CARDS_PER_ROW;
      setCardContainerHeight(averageHeight);
    }
  });

  return cardContainerHeight;
};

export default useAppCardContainerHeight;
