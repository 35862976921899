import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import NotesPage from './NotesPage';
import { setCurrentVisualizationType } from 'actions/drilldownActions';
import { updateShowLeafPage, updateLeafPageNoteId, updateLeafPageRecordIndex } from 'actions/tableActions';
import { VISUALIZATION_TYPES } from 'appConstants';
import {  onClosePropTypes, stringOrNumberProps } from 'common/propTypes';
import PropTypes from 'prop-types';

class NoteModal extends Component {
  constructor(props) {
    super(props);
    // this.state = {};
  }

  gotoLeafPage = () => {
    const { onClose, dispatchGotoLeafPage, noteId } = this.props;
    onClose();
    dispatchGotoLeafPage(noteId);
  }

  render() {
    const { onClose, noteId } = this.props;

    return (
      <Modal show={true} size="lg" onHide={onClose} className="notes-modal">
        <Modal.Header>
          <Modal.Title>
            Note
          </Modal.Title>
          <button
            className='btn custom-close px-0 right'
            onClick={onClose}><i className="icons-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <NotesPage noteId={noteId} isModelView={true} />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={this.gotoLeafPage}>
            Go to detail page
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

NoteModal.propTypes = {
  onClose: onClosePropTypes,
  noteId: stringOrNumberProps,
  dispatchGotoLeafPage: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGotoLeafPage: (noteId) => {
      dispatch(updateLeafPageRecordIndex(''));
      dispatch(setCurrentVisualizationType(VISUALIZATION_TYPES.TABLE.type));
      dispatch(updateShowLeafPage(true));
      dispatch(updateLeafPageNoteId(noteId));
    }
  };
}

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
