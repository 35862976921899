// Vendor imports
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import ProjectionInfo from 'pages/drilldown/components/LineChartOptions/ProjectionInfo';
import ProjectionBanner from 'pages/drilldown/components/LineChartOptions/ProjectionBanner';

import { HISTORICAL_FORECAST, LINEAR_FORECAST } from 'appConstants';
import {
  getProjectionMetricTypes,
  canShowProjectionMetric
} from 'modules/visualization/LineChart/Helpers/projectionHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

class ProjectionOverlay extends Component {
  renderForecastingPercentField() {
    const { currentForecastPercentage, currentForecastOption, handleForecastPercentChange } = this.props;
    if (!_.isEqual(currentForecastOption, HISTORICAL_FORECAST)) {
      return null
    }

    return (
      <div className="percent-input">
        <input
          aria-label="percentage input"
          className="form-control form-control-sm"
          pattern="^-?[0-9]\d*\.?\d*$"
          type="text"
          value={currentForecastPercentage}
          onChange={handleForecastPercentChange} />
        <span>%</span>
      </div>
    )
  }

  renderForecastingDropdown() {
    const {
      currentDrilldownViewEntry,
      currentForecastOption,
      currentChartView,
      disableShowProjectionOption,
      handleForecastOptionChange,
      isShowProjection
    } = this.props;
    const forecastOptions = getProjectionMetricTypes(currentDrilldownViewEntry, currentChartView);
    const showProjectionMetric = canShowProjectionMetric(currentDrilldownViewEntry, currentChartView);

    if (!showProjectionMetric || disableShowProjectionOption || !isShowProjection) {
      return null;
    }

    return (
      <div className="forecasting-dropdown mb-3">
        <div className="d-flex align-items-center">
          <div className="forecast-dropdown-container d-flex align-items-center mr-1">
            <label className="forge-typography--subtitle1 mr-1 mb-0">Projection</label>
            <div className='projection-hover-select'>
              <SolutionDropDown
                size='sm'
                title={currentForecastOption}
                options={forecastOptions}
                onSelect={handleForecastOptionChange} />
              <ProjectionInfo
                isShowProjection={isShowProjection}
                currentForecastOption={currentForecastOption}
              />
            </div>
          </div>
          {this.renderForecastingPercentField()}
        </div>
      </div>
    );
  }

  renderProjectionOverlay() {
    const {
      isShowProjection,
      currentForecastOption,
      handleSelectProjection,
      disableShowProjectionOption,
      showLinearProjection
    } = this.props;

    if (!isShowProjection || disableShowProjectionOption) {
      return null;
    }

    let projectionInfo = `Your currently selected projection type is ${currentForecastOption}. Adjust the
      projection options to modify your forecast.`

    if (_.isEqual(currentForecastOption, LINEAR_FORECAST) && !showLinearProjection) {
      projectionInfo = `Not enough data points to draw ${currentForecastOption} projections.`
    }

    return (
      <div className="projection-show">
        <div className="d-flex justify-content-between align-items-baseline">
          <div>
            {this.renderForecastingDropdown()}
            <div className="projection-info forge-typography--body2 mb-3">
              {projectionInfo}
            </div>
            <div>
              <ProjectionBanner isProjection={true}/>
            </div>
          </div>
          <div>
            <button
              className="btn projection-close-btn"
              title="close"
              onClick={() => handleSelectProjection(false)}>
              <i className="icons-close"> </i>
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderProjectionOverlay();
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
    isShowProjection: JSON.parse(_.get(state, 'visualization.overtime.isShowProjection', false))
  };
}

ProjectionOverlay.propTypes = {
  showLinearProjection: PropTypes.bool,
  currentForecastOption: PropTypes.string,
  currentForecastPercentage: PropTypes.number,
  disableShowProjectionOption: PropTypes.bool,
  handleSelectProjection: PropTypes.func,
  handleForecastPercentChange: PropTypes.func,
  handleForecastOptionChange: PropTypes.func,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentChartView: PropTypes.string,
  isShowProjection: PropTypes.bool
};

ProjectionOverlay.defaultProps = {
  currentForecastPercentage: 3,
  disableShowProjectionOption: false,
  handleSelectProjection: _.noop,
  handleForecastPercentChange: _.noop,
  handleForecastOptionChange: _.noop
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionOverlay);
