import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useFetchApplications from './useFetchApplications';
import ApplicationList from './ApplicationList';
import LoadingSpinner from '../LoadingSpinner';
import useAppWidth from 'hooks/useAppWidth';
import useAppCardContainerHeight from 'hooks/useAppCardContainerHeight';

function AppListModal({ onClickComfyIcon }) {
  const { applications, isLoading } = useFetchApplications();
  const appListContainerRef = useRef(null);
  const appListRef = useRef(null);
  const width = useAppWidth({ appListRef });
  const cardRefs = useRef([]);

  const cardContainerHeight = useAppCardContainerHeight(cardRefs);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const btnListContainer = document.getElementById(
      'button-app-list-container'
    );

    const isOutsideButton =
      btnListContainer && !btnListContainer?.contains(event.target);
    const isOutsideContainer =
      appListContainerRef.current &&
      !appListContainerRef.current.contains(event.target);

    if (isOutsideContainer && isOutsideButton) {
      onClickComfyIcon();
    }
  };

  const renderMyApps = () => {
    const apps = _.map(applications, (appConfig, index) => {
      const uniqueKey = 'application-card-' + index;
      cardRefs.current[index] = React.createRef();

      return (
        <ApplicationList key={uniqueKey}
          appConfig={appConfig}
          cardRef={cardRefs.current[index]}
        />
      )
    });

    return (
      <div className="app-card-container" ref={appListRef}>
        {apps}
      </div>
    );
  };

  return (
    <div
      id="app-list-container"
      ref={appListContainerRef}
      style={{ width: `${width}px` }}
    >
      <header className="forge-dialog__header">
        <h2 className="forge-typography--caption">My Applications</h2>
      </header>
      <section className="app-list"
        style={{ maxHeight: cardContainerHeight > 0 ? `${cardContainerHeight}px` : "auto" }}
      >
        <LoadingSpinner isLoading={isLoading} />
        {renderMyApps()}
      </section>
    </div>
  );
}

AppListModal.propTypes = {
  onClickComfyIcon: PropTypes.func,
};


export default AppListModal;
