import { applicationId } from "common/config/customerConfiguration";
import { fetchApiData } from "helpers/apiResponseHelper";
import { useEffect, useState } from "react";

const useFetchApplications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplication] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    fetchApiData('/api/user_app_configurations.json').then((response) => {
      const apps = getSortOrderApplications(response);
      setApplication(apps);
      setIsLoading(false);
    }).catch((e) => {
      setIsLoading(false);
      console.log('Failed to fetch audit logs list!', e);
    });
  };

  const getSortOrderApplications = (response) => {
    const sortedApps = _.sortBy(response, item => item.application.name.toLowerCase());
    return _.filter(sortedApps, item => item.application?.id != applicationId);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return { applications, isLoading };
};

export default useFetchApplications;
