import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ForecastingFilter from '../ForecastingFilters/ForecastingFilter';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getForecastChartAttributes } from 'pages/Forecasting/ForecastHelper';
import {
  isComboChartEnable
} from 'common/config/visualizationConfiguration';
import { defaultPrepareDataAxisGranularityOption } from '../ForecastHelper';
class ForecastingSideBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hideSideBar: false,
    };
  }

  onToggleButtonClick = () => {
    const { hideSideBar } = this.state;
    this.setState({ hideSideBar: !hideSideBar }, () => {
      this.props.onUpdateToggleLeftside(hideSideBar);
      this.resizeWindowHandler();
    });
  }

  onToggleButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onToggleButtonClick();
    }
  }

  resizeWindowHandler() {
    setTimeout(() => {
      // dispatch chart resize event, So chart will resize automatically.
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  renderForecastingFilter = () => {
    const {
      minDatesTemplateForForecast,
      currentDrilldownTemplateId,
      commonFilters,
      dispatchUpdateForecastDateRange,
      dispatchUpdateFutureForecastDateRange,
      dispatchUpdateAxisGranularity,
      forecastAttributeOptions,
      currentBookmark
    } = this.props;

    const {
      selectedForecastMetric, currentChartView, axisGranularity,
      futureForecastDateRange
    } = forecastAttributeOptions;
    const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(currentDrilldownTemplateId);
    const isComboChart = isComboChartEnable(selectedForecastMetric, currentChartView)
    const forecastOptions = {...forecastAttributeOptions, isComboChart};
    forecastOptions['defaultAxisGranularity'] = defaultAxisGranularity;
    if (_.isEmpty(axisGranularity)) {
      forecastOptions['axisGranularity'] = defaultAxisGranularity;
    }
    let lineChartAttributes = getForecastChartAttributes({
      forecastOptions,
      viewEntry: selectedForecastMetric,
      templateId: currentDrilldownTemplateId,
      commonFilters: {}
    });
    lineChartAttributes['isForecastingView'] = true;
    const { dateRange } = lineChartAttributes;

    return (
      <ForecastingFilter
        minDatesTemplateForForecast={minDatesTemplateForForecast}
        commonFilters={commonFilters}
        lineChartAttributes={lineChartAttributes}
        currentDateRange={dateRange}
        futureForecastDateRange={futureForecastDateRange}
        dispatchUpdateForecastDateRange = {dispatchUpdateForecastDateRange}
        dispatchUpdateFutureForecastDateRange = {dispatchUpdateFutureForecastDateRange}
        dispatchUpdateAxisGranularity={dispatchUpdateAxisGranularity}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        forecastAttributeOptions = {forecastOptions}
        currentBookmark={currentBookmark}
      />
    )
  }

  render() {
    const { hideSideBar } = this.state;
    const sideBarClass = classNames('radar-sidebar drawer-panel',
      { 'drawer-panel-hide': hideSideBar });

    return (
      <div className={sideBarClass}>
        <div className="toggle-btn"
          tabIndex={0}
          onClick={this.onToggleButtonClick}
          onKeyDown={this.onToggleButtonKeyDown}
          id={hideSideBar ? 'open_leftside_panel' : 'close_leftside_panel'}
          >
          <span className="icons-chevron-left"></span>
        </div>
        <div className='drawer-panel-wrapper'>
          {/* <hr className='dividers' /> */}
          <div className='radar-filter'>
            {this.renderForecastingFilter()}
          </div>
        </div>
      </div>
    )
  }
}

ForecastingSideBar.propTypes = {
  minDatesTemplateForForecast: PropTypes.array,
  currentDrilldownTemplateId: PropTypes.any,
  commonFilters: PropTypes.shape({}),
  dispatchUpdateForecastDateRange: PropTypes.func,
  dispatchUpdateFutureForecastDateRange: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  forecastAttributeOptions: PropTypes.object,
  onUpdateToggleLeftside: PropTypes.func,
  currentBookmark: PropTypes.object
};

export default ForecastingSideBar;
