import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';

import { isClickInfoSection } from 'helpers/DomPageHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import LoadingSpinner from 'common/components/LoadingSpinner';
import SearchDetailsTable from 'modules/AdvanceSearch/SearchResultPage/SearchDetailsTable';
import { getAdvanceSearchResult } from 'common/api/advanceSearchApi';
import { getTableColumnEntries } from 'common/config/templateConfiguration';
import FilterIcon from '../TemplateCards/FilterIcon';
import {
  getQuickFilterEntriesFromTemplate
} from 'pages/drilldown/components/QuickFilterBar/helper';
import NewBadge from 'common/components/NewBadge';

const CustomAdvanceSearchCard = (props) => {
  const {
    onCardDelete, userCardEntry, onCardClick, isNewCard = false
  } = props;
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const advanceSearchOptions = _.get(userCardEntry, 'advanceSearchOptions', {});
  const name = _.get(userCardEntry, 'name', '');
  const description = _.get(userCardEntry, 'bookmarkOptions.description', '');
  const filteredGeojson = JSON.parse(_.get(advanceSearchOptions, 'polygonsGeojson'));
  // const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    const promises = [
      getSearchResultQueryParamsWithUrl(),
    ];
    Promise.all(promises).then((results) => {
      const tableRowsData = _.get(results, '0', []);
      setTableData(tableRowsData);
      setIsLoading(false);
    }).catch((error) => {
      console.log("Error on fetching data ", error);
      setIsLoading(false);
    });
  }, []);

  const handleCardDelete = () => {
    onCardDelete(userCardEntry);
  }

  const onKeyDownRadarCard = (e) => {
    if (isEnterButtonPressed(e)) {
      onClickCard(e);
    }
  }

  const handleClickMenuItems = () => {
    document.body.click();
  }

  const onClickCard = (e) => {
    if (isClickInfoSection(e)) {
      return;
    }
    if (_.isFunction(onCardClick)) {
      onCardClick(userCardEntry, e)
    }
  }

  const renderContent =() => {
    const searchFilters = _.get(advanceSearchOptions, 'filters');
    const selectedShapeIds = _.get(advanceSearchOptions, 'selectedShapeIds');
    const isApplyFilters = (!_.isEmpty(searchFilters) || !_.isEmpty(selectedShapeIds))

    if (isApplyFilters) {
      return renderTable();
    }
  }

  const getSearchResultQueryParamsWithUrl = () => {
    const {
      templateId,
      searchField,
      filters,
      selectedShapeIds,
      shapeGroupId,
      sortColumns,
      polygonsGeojson
    } = advanceSearchOptions;
    const newTemplateId = templateId;
    const params = {
      advanceSearchSubjectField: JSON.stringify(searchField),
      include_quick_filters: true,
      currentDrilldownTemplateId: newTemplateId,
      drilldownEntry: JSON.stringify({quickFilters: filters}),
      shapeIds: selectedShapeIds.join(','),
      shapeGroupId: shapeGroupId,
      subjectRowIds: JSON.stringify([]),
      sortColumns: JSON.stringify(sortColumns || []),
      isCustom: true,
      limit: 20,
      offset: 0,
      polygonGeoJson: polygonsGeojson
    }

    return getAdvanceSearchResult(params);
  }

  const renderTable = () => {
    return (
      <SearchDetailsTable
        onlyShowTableRow={false}
        tableHeaders={getTableColumnEntries(_.get(advanceSearchOptions, 'templateId'))}
        tableData={tableData}
        tableId="filter-table"
        isCustomSearch={true}
        showPagination={false}
        isSmallView={true}
      />
    )
  }

  const renderMenus = () => {
    const options = [
      { value: 'delete', label: 'Delete Custom search' }
    ];

    return (
      <div className="info-sections align-self-start ml-1">
        <ForgeMenu
          placement='bottom-start'
          options={options}
          on-forge-menu-select={() => setIsDeleteClicked(true)}>
            <ForgeIconButton type="button">
              <button type="button">
              <ForgeIcon name='more_vert' className="more-icon" /></button>
            </ForgeIconButton>
        </ForgeMenu>
      </div>
    );
  }

  const renderCardBody = () => {
    const deleteMessage = `Are you sure you would like to delete ${name}? This cannot be undone.`;
    return (
      <div className='card-body d-flex flex-column h-100 forcasting-card forge-popup-host'>
        <div className='metric-head-wrapper'>
          <div className="metric-head d-flex">
              <div>
                <div className="metric-title forge-typography--subtitle1-secondary">
                  <div className='d-flex gap-4'>{name} {isNewCard && <NewBadge />}</div>
                </div>
                {/* <div className="metric-category forge-typography--caption">
                  {dataRangeText}{renderTooltipInfo()}
                </div> */}
              </div>
            </div>
            {renderMenus()}
        </div>
        {<FilterIcon {...getFilterIconAttributes()}/>}
        <div className="mb-auto">
          <div
            className="metric-card-visualization js-plotly-plot mt-2">
            {renderContent()}
          </div>
        </div>
        <div className="forge-typography--caption">{description}</div>
        {isDeleteClicked &&
        <DeleteConfirmModal
                title="Delete saved search"
                message={deleteMessage}
                showButtons={false}
                showMenuItems={handleClickMenuItems}
                onDeleteConfirm={handleCardDelete}
                onCancelConfirm={() => setIsDeleteClicked(false)}
              />}
      </div>
    );
  }

  const getFilterIconAttributes = () => {
    const currentDrilldownTemplateId = _.get(advanceSearchOptions, 'templateId', '');
    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      currentDrilldownTemplateId,
      {}
    );
    return {
      currentDrilldownViewEntry: {},
      // cardEntries,
      dateFilters: {},
      isEmbed: false,
      templateEntry: userCardEntry,
      hideCommonFilters: true,
      quickFilters: _.get(advanceSearchOptions, 'filters'),
      quickFilterEntries: quickFilterEntries,
      filteredGeojson: filteredGeojson
    };
  };

  return (
    <>
      <div className='card metric-card' role="listitem"
        tabIndex={0}
        onClick={onClickCard}
        onKeyDown={(e) => onKeyDownRadarCard(e)}>
          <LoadingSpinner isLoading={isLoading} />
          {renderCardBody()}
      </div>
    </>
  );
};

CustomAdvanceSearchCard.defaultProps = {
  isUserCard: false,
  onCardDelete: _.noop,
  userCardEntry: {},
  newLabelCards: [],
  isRadarBookmark: false,
  isNewCard: false,
}

CustomAdvanceSearchCard.propTypes = {
  isUserCard: PropTypes.bool,
  isNewCard: PropTypes.bool,
  isRadarBookmark: PropTypes.bool,
  // isMyCollection: PropTypes.bool,
  onCardDelete: PropTypes.func,
  onCardClick: PropTypes.func,
  userCardEntry: PropTypes.object,
  newLabelCards: PropTypes.array,
  reloadBookmarks: PropTypes.func,
  loading: PropTypes.bool,
  commonFilters: PropTypes.object,
  templateEntry: PropTypes.object,
  viewEntry: PropTypes.object,
}

export default CustomAdvanceSearchCard;
