import { getBarChartPopupConfigs } from 'common/contentFormatter/barChartContentFormatter';
import { getScatterChartPopupConfigs } from 'common/contentFormatter/scatterPlotChartContentFormatter';
import { getPiChartPopupConfigs } from 'common/contentFormatter/pieChartContentFormatter';
import { isScatterChart } from './plotlyScatterChartHelper';
import { isBarchart } from './barChartHelper';

export const getPopupConfigs = (options) => {
  const {
    chartContainer,
    data,
    templateId,
    viewMode,
    viewEntry,
    groupByEntry,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    secondaryMetricEntry,
    currentSnapshotView,
    isComparisonEnabled,
    commonFilters
  } = options;

  if(isBarchart(currentSnapshotView)) {
    return getBarChartPopupConfigs({
      chartContainer,
      data,
      viewMode,
      groupByEntry,
      templateId,
      isCurrencyDimensionField,
      isCurrencyGroupByField,
      viewEntry,
      secondaryMetricEntry,
      isComparisonEnabled,
      commonFilters
    });
  } else if (isScatterChart(currentSnapshotView)) {
    return getScatterChartPopupConfigs({
      chartContainer,
      data,
      templateId,
      isCurrencyDimensionField,
      isCurrencyGroupByField,
      viewMode,
      viewEntry,
      groupByEntry,
      commonFilters
    });
  } else {
    return getPiChartPopupConfigs(chartContainer, data, isCurrencyDimensionField, viewMode, commonFilters);
  }
}
