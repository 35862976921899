import React from 'react';
import PropTypes from 'prop-types';
import { getSubscriptionNotifyText, initialSubscriptionNotifyText } from './subscriptionHelper';
import { ForgeButton } from '@tylertech/forge-react';
import LoadingSpinner from 'common/components/LoadingSpinner';

function WatchCollectionDialog({ watchDialogOptions }) {
  const {
    isLoading,
    successMessage,
    currentSubscription,
    subscriptionParams,
    onHandleUndoWatch,
    onHandlePressedUndoWatch,
    onHandleMoreOptionsClick,
    onHandleCreateWatchSubscription,
  } = watchDialogOptions;

  let watchNotificationMessage = successMessage;
  if (_.isEmpty(successMessage)) {
    watchNotificationMessage = _.isEmpty(currentSubscription) ?
      initialSubscriptionNotifyText(subscriptionParams) : getSubscriptionNotifyText(currentSubscription);
  }

  const renderCancelButton = () => {
    return (
      <ForgeButton>
        <button
          onClick={onHandleUndoWatch}
          onKeyDown={(e) => onHandlePressedUndoWatch(e)}>
          Cancel
        </button>
      </ForgeButton>
    );
  };

  const renderMoreOptionsButton = () => {
    return (
      <ForgeButton type="outlined">
        <button
          // disabled={_.isEmpty(currentSubscription)}
          tabIndex="0"
          onClick={() => onHandleMoreOptionsClick()}>
          More options
        </button>
      </ForgeButton>
    );
  };

  const renderSaveButton = () => {
    return (
      <ForgeButton type="raised" class="ml-2">
        <button tabIndex="0" onClick={() => onHandleCreateWatchSubscription()}>
          Save
        </button>
      </ForgeButton>
    );
  };

  return (
    <>
      <div className="d-flex gap-10 mb-3 align-items-center overflow-hidden">
        <img src="../images/notifications-spot.svg" loading="lazy" alt="Notification Spot" />
        <span>{watchNotificationMessage}</span>
      </div>
      <LoadingSpinner isLoading={isLoading} />
      <div className="d-flex justify-content-between mt-auto">
        {renderCancelButton()}
        <div>
          {renderMoreOptionsButton()}
          {renderSaveButton()}
        </div>
      </div>
    </>
  );
}

WatchCollectionDialog.propTypes = {
  watchDialogOptions: PropTypes.object,
};

export default WatchCollectionDialog;
