import React from 'react';
import { ForgeBadge } from '@tylertech/forge-react';

const NewBadge = () => {
  return (
    <ForgeBadge
      className="new-label-chip"
      strong="false">
      New
    </ForgeBadge>
  );
}

export default NewBadge;
