import _ from 'lodash';

import { getActualChange, getRateOfChange } from 'common/contentFormatter/helper';
import { getColorClassAndIconName } from 'helpers/visualizationHelper';
import { LINE_CHART_TRACE_COLORS } from 'appConstants';
import { getYearTextByRange } from './dateHelper';
export const getComparisonTooltipAttributes = ({
  metricTotalData, viewEntry, secondaryMetricEntry, tooltipId,
  comparisonDateRanges, dateRangeMode, dateRange
}) => {
  const showSecondaryTotal = !_.isEmpty(secondaryMetricEntry);
  const {
    comparisonDatesMetricTotals,
    currentPeriodMetricTotals,
  } = metricTotalData;
  let defaultTotal = 0;
  let field = 'total';
  if (showSecondaryTotal) {
    field = `secondary_${secondaryMetricEntry['field']}`;
    defaultTotal = _.get(currentPeriodMetricTotals, 'secondary_count', 0);
  }
  const currentPeriodTotal = _.get(currentPeriodMetricTotals, field, defaultTotal);
  const primaryYear = getYearTextByRange(dateRange, dateRangeMode);
  let comparisonTooltipAttributes = [];
  if(!_.isEmpty(comparisonDateRanges)){
    comparisonTooltipAttributes = _.map(comparisonDateRanges, (comparisonDateRange, index) => {
      const compareYear = getYearTextByRange(comparisonDateRange, dateRangeMode);
      const totalData = _.get(comparisonDatesMetricTotals, compareYear, {});
      const defaultPrevious = _.get(totalData, 'secondary_count', 0);
      const previousTotal = _.get(totalData, field, defaultPrevious);
      const { percentage, actualDifference } = calculateChangeBetweenPeriods({currentPeriodTotal, 
        previousTotal,
        primaryYear, 
        compareYear
      });
      
      const { color, iconsClassName } = getColorClassAndIconName(viewEntry, percentage);

      return({
        discreteValue: Math.abs(Number(currentPeriodTotal) - Number(previousTotal)),
        color:  comparisonDateRanges.length <= 1 && index == 0 ? color : LINE_CHART_TRACE_COLORS[index+1],
        percentage,
        actualDifference,
        currentPeriodTotal,
        previousPeriodTotal: previousTotal,
        comparisonDateRange,
        dateRange,
        className: iconsClassName,
        dateRangeMode,
        viewEntry,
        tooltipId: (tooltipId || "metric-and-comparison"),
        key: `${tooltipId}-${index}`,
      });
    });
  }
  return comparisonTooltipAttributes;
}

const calculateChangeBetweenPeriods = ({ currentPeriodTotal, previousTotal, primaryYear, compareYear }) => {
  const isGreaterCompareYear = Number(primaryYear) < Number(compareYear);
  const [currentTotal, compareTotal] = isGreaterCompareYear 
    ? [previousTotal, currentPeriodTotal] 
    : [currentPeriodTotal, previousTotal];

  const percentage = getRateOfChange([currentTotal], [compareTotal], false);
  const actualDifference = getActualChange(currentTotal, compareTotal);

  return { percentage, actualDifference };
}
