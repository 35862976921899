import { useEffect, useState } from "react";

function useAppWidth({ appListRef }) {
  const [width, setWidth] = useState(370);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (appListRef?.current) {
        const containerHeight = appListRef.current.offsetHeight;
        if (containerHeight > 700) {
          setWidth(390);
        } else {
          setWidth(370);
        }
      }
    };

    updateContainerWidth();

    const resizeObserver = new ResizeObserver(() => {
      updateContainerWidth();
    });


    if (appListRef?.current) {
      resizeObserver.observe(appListRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [appListRef]); 

  return width;
}

export default useAppWidth;
