import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from "react";
import { connect } from 'react-redux';
import * as commonPropTypes from "common/propTypes";
import CustomDropDown from 'common/components/SolutionDropDown/CustomDropDown';

import GlobalEvent from 'common/components/GlobalEvents';
import { getEntityCountsUrl } from 'common/api/commonApi';
import { getApiParams } from 'helpers/apiParamsHelper';
import { responseHandler } from 'helpers/apiResponseHelper';
import abortableFetch from 'common/abortableFetch';
import { VISUALIZATION_TYPES } from 'appConstants';
import {
  getDimensionEntries,
  getGroupByEntries,
  getAvailableDrilldownDropdownEntries,
  getAvailableGroupByEntries,
  shouldShowEntityCounts,
  isGroupByAscOrder,
  isDimensionAscOrder,
  getTemplateWiseViewEntries
} from 'common/config/templateConfiguration';

import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  showDimensionsInDistribution
} from 'common/config/visualizationConfiguration';
import {
  setCurrentVisualizationType
} from 'actions/drilldownActions';
import { getTemplateEntries, isPrivate } from 'common/config/customerConfiguration';
import CustomDropdownList from '../SolutionDropDown/CustomDropdownList';
import {
  canRenderDrilldownDimension,
  canRenderGroupByDropdown,
  getAvailableVisualizationTypes } from 'helpers/visualizationHelper';
import { getCollectionWiseMetricList } from 'common/api/collectionApi';
import { getCollectionCardEntries } from 'pages/embed/helper';
import { getNonCollectionCardEntries } from 'helpers/drilldownLeftSideSectionHelper';
import { getDrilldownEntryParams } from 'pages/dashboard/components/TemplateCards/TemplateCard';
import { setAnalysisPageCollectionId, setAnalysisPageCollectionTagId } from 'actions/dashboardActions';
import { dispatchMetricSelection } from 'helpers/templateSelectionDispatchHelper';
import { getCollectionWiseMetricListByTagId } from 'pages/dashboard/components/Collections/collectionHelper';
import {  updateOvertimeIsEmptyLegend, updateOvertimeShowLegendTotal } from 'actions/overtimeActions';

const SENTENCE_BUILDER_MIN_OPTION_SIZE = 1;
class DrillDownSentenceBuilder extends Component {

  state = {
    isMetricsLoading: false,
    groupByEntityCounts: {},
    dimensionsEntityCounts: {},
    templateWiseViewEntries: getTemplateWiseViewEntries(),
    allTemplateWiseViewEntries: getTemplateWiseViewEntries(),
    collectionsWithMetrics: [],
    allCollectionsWithMetrics: [],
    templateEntries: _.get(window.serverConfig, 'customerConfiguration.template_entries', []),
  }

  componentDidMount() {
    this.setGroupByEntityCounts();
    this.setDimensionEntityCounts();
    this.fetchCollectionBasedMetrics();
  }

  componentDidUpdate(prevProps) {
    const { apiParams } = this.props;
    const isApiParamsChanged = !_.isEqual(prevProps.apiParams, apiParams);

    if (isApiParamsChanged) {
      this.setGroupByEntityCounts();
      this.setDimensionEntityCounts();
    }

  }

  fetchCollectionBasedMetrics = async () => {
    const { templateEntries } = this.state;
    const { currentUser } = this.props;

    const isPublicApp = !isPrivate();
    this.setState({
      isMetricsLoading: true
    });
    try {
      if (isPublicApp && (currentUser == "null")) {
        const { collectionWiseMetrics } = getCollectionWiseMetricListByTagId(templateEntries);
        this.setState({
          collectionsWithMetrics: collectionWiseMetrics,
          allCollectionsWithMetrics: collectionWiseMetrics,
          isMetricsLoading: false,
        });
        this.fetchOtherCardEntries(collectionWiseMetrics);
      } else {
        getCollectionWiseMetricList().then((res) => res.json()).then((collectionsWiseMetricList) => {
          const collectionsWithMetricList = collectionsWiseMetricList.filter(metric => metric.cards.length > 0
            && !_.isEqual(metric?.name, 'My Views'));

          const updatedCollections = collectionsWithMetricList.map(({ cards, ...metrics }) => {
            const collectionCards = cards.filter(card => _.isNull(card.bookmark_id));
            const updatedCards = collectionCards.map((card) => {
              const cardId = card['card_id'];
              const { viewEntry } = getCollectionCardEntries(cardId, templateEntries);
              if (!_.isUndefined(viewEntry)) {
                return { ...card, name: viewEntry?.name };
              }
              return null;
            });
            const filteredCollectionCards = updatedCards.filter(card => card !== null);
            return { ...metrics, cards: filteredCollectionCards };
          })
          this.setState({
            collectionsWithMetrics: updatedCollections,
            allCollectionsWithMetrics: updatedCollections,
            isMetricsLoading: false,
          });
          this.fetchOtherCardEntries(updatedCollections);
        });
      }
    }
    catch (error) {
      console.error('Error fetching data:', error);
      this.setState({
        isMetricsLoading: false,
      });
    }
  };

  onTemplateWiseMetricSelect = (entry) => {
    const entryTemplateId = _.get(entry, 'template_id');

    if (this.props.currentDrilldownTemplateId != entryTemplateId) {
      const templateEntry = _.find(getTemplateEntries(), { template_id: entryTemplateId }) || {};
      this.props.onTemplateChange(templateEntry, entry);
      this.props.onViewSelect(entry);
    } else {
      this.props.onViewSelect(entry);
    }
  }

  onSearchInputChange = (value) => {
    const { allCollectionsWithMetrics } = this.state;
    const valueLowerCase = value?.toLowerCase()
    const filteredMetrics = allCollectionsWithMetrics.map(({ cards, ...metrics }) => {
      const filteredCards = cards.filter(({ name }) => _.includes(name?.toLowerCase(), valueLowerCase));
      return {
        ...metrics,
        cards: filteredCards,
      };
    }).filter(({ cards }) => cards.length > 0);
    const newCollectionWithMetrics = _.isEmpty(value) ? allCollectionsWithMetrics : filteredMetrics;
    this.setState({ collectionsWithMetrics: newCollectionWithMetrics });
  }

  setGroupByEntityCounts() {
    const { currentDrilldownTemplateId, apiParams } = this.props;
    const groupByEntities = getGroupByEntries(currentDrilldownTemplateId);
    this.abortFetchController = new AbortController();
    const groupByEntityFields = _.chain(groupByEntities).
      filter('field', !_.isEmpty).
      map('field').
      value();
    const queryParams = {
      ...apiParams,
      entityFields: JSON.stringify(groupByEntityFields)
    };
    this.fetchEntityCounts(queryParams)
  }

  setDimensionEntityCounts() {
    const {
      currentDrilldownTemplateId,
      apiParams
    } = this.props;
    const dimensionsEntities = getDimensionEntries(currentDrilldownTemplateId);
    this.abortFetchController = new AbortController();
    const dimensionsEntityFields = _.chain(dimensionsEntities).
      filter('field', !_.isEmpty).
      map('field').
      value();
    const queryParams = {
      ...apiParams,
      entityFields: JSON.stringify(dimensionsEntityFields)
    };
    this.fetchEntityCounts(queryParams, true)
  }

  fetchEntityCounts(queryParams, isDimensionEntity = false) {
    const { currentDrilldownTemplateId } = this.props;
    if (!shouldShowEntityCounts(currentDrilldownTemplateId)) {
      return;
    }
    const entityCountsUrl = getEntityCountsUrl(queryParams);
    return abortableFetch(entityCountsUrl, { signal: this.abortFetchController.signal })
      .then(response => response.json()).then(responseHandler).then((response) => {
        let currentEntityCounts = {};
        if (isDimensionEntity) {
          currentEntityCounts['dimensionsEntityCounts'] = response;
          GlobalEvent.emit('DIMENSION_COUNT', response);
        } else {
          currentEntityCounts['groupByEntityCounts'] = response;
          GlobalEvent.emit('DIMENSION_GROUP_COUNT', response);
        }
        this.setState(currentEntityCounts);
      }).catch((error) => {
        console.log("Error on fetching Totals data", error);
      });
  }

  fetchOtherCardEntries(collectionMetrics) {
    const { templateEntries } = this.state;
    const nonCollectionEntries = getNonCollectionCardEntries(collectionMetrics, templateEntries)
    if (nonCollectionEntries && nonCollectionEntries.length > 0) {
      this.setState((prevState) => {
        const otherCards = {
          name: 'Other',
          cards: nonCollectionEntries,
        };
        const newCollectionWithCards = [...prevState.collectionsWithMetrics, otherCards];
        return {
          collectionsWithMetrics: newCollectionWithCards,
          allCollectionsWithMetrics: newCollectionWithCards
        }
      });
    }
  }

  onCollectionWiseMetricSelect = (entry) => {
    const { templateEntries } = this.state;
    const { commonFilters, dispatchMetricSelection } = this.props;
    const selectedCollectionId = _.get(entry, 'collection_id', null);
    const selectedCollectionTagId = _.get(entry, 'collection_tag_id', 'allMetrics');
    const { templateEntry, viewEntry } = getCollectionCardEntries(entry?.card_id, templateEntries);
    const drilldownEntry = getDrilldownEntryParams(entry, viewEntry);
    const globalFilters = _.get(commonFilters, 'globalFilters', []);
    dispatchMetricSelection(templateEntry, viewEntry, drilldownEntry,
      commonFilters, globalFilters, selectedCollectionId, selectedCollectionTagId);
    this.setDefaultVisualizationView(templateEntry, viewEntry);
  }

  setDefaultVisualizationView = (templateEntry, viewEntry) => {
    const { currentVisualizationType, dispatchSetCurrentVisualizationType } = this.props;
    const templateId = _.get(templateEntry, 'template_id', '');
    const availableVisualizationTypes = getAvailableVisualizationTypes(templateId, viewEntry);
    const isCurrentVizAvailable = !_.isEmpty(
      _.find(availableVisualizationTypes, {type: currentVisualizationType})
    );
    if (!isCurrentVizAvailable) {
      dispatchSetCurrentVisualizationType(_.first(availableVisualizationTypes)['type']);
    }
  }

  renderViewDropdown() {
    const { currentDrilldownViewEntry, dispatchUpdateShowLegendTotal } = this.props;
    const { templateWiseViewEntries, collectionsWithMetrics, isMetricsLoading } = this.state;
    const title = _.get(currentDrilldownViewEntry, 'name', 'select view');

    return (
      <CustomDropDown
        key="view-drop-down"
        groupByColumn="template_id"
        selectedField={currentDrilldownViewEntry['field']}
        selectedFieldKey="field"
        size="sm"
        id="view"
        className="p-0 d-flex"
        title={title}
        options={templateWiseViewEntries}
        onSearchInputChange={this.onSearchInputChange}
        onSelect={this.onTemplateWiseMetricSelect}
        onMetricSelect={this.onCollectionWiseMetricSelect}
        collectionsWithMetrics={collectionsWithMetrics}
        isMetricsLoading={isMetricsLoading}
        onUpdateShowLegendTotal={dispatchUpdateShowLegendTotal}
      />
    );
  }

  renderGroupByDropdown() {
    let isGroupByDisabled = false;

    const { groupByEntityCounts } = this.state;
    const {
      currentDrilldownGroupByEntry,
      currentDrilldownTemplateId,
      onGroupBySelect,
      currentDrilldownDimensionField,
      currentVisualizationType
    } = this.props;
    const groupByEntries = getGroupByEntries(currentDrilldownTemplateId);

    if (_.isEmpty(groupByEntries)) {
      return null;
    }
    if (!_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.SNAPSHOT.type)) {
      isGroupByDisabled = true;
    }

    const availableGroupByEntries = getAvailableGroupByEntries({
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      groupByEntries,
      currentDrilldownGroupByEntry
    });

    let formattedGroupByEntries = _.map(availableGroupByEntries, (entry) => {
      entry.count = _.get(groupByEntityCounts, `count_${entry.field}`);
      return entry;
    });
    const noneGroupEntry = _.find(formattedGroupByEntries, { 'name': 'None' });
    formattedGroupByEntries = isGroupByAscOrder(currentDrilldownTemplateId) ?
      _.sortBy(formattedGroupByEntries, 'name') :
      formattedGroupByEntries;
    if (!_.isEmpty(noneGroupEntry)) {
      formattedGroupByEntries = _.reject(formattedGroupByEntries, { 'name': 'None' });
      formattedGroupByEntries.push(noneGroupEntry);
    }
    const title = _.get(currentDrilldownGroupByEntry, 'name');
    return (
      <div className="forge-popup-host">
        <div className="control-labels forge-typography--caption">Group by</div>
        <CustomDropdownList
          key="grop-drop-down"
          id="groupby"
          className="p-0 d-flex"
          title={title}
          options={formattedGroupByEntries}
          showCount={true}
          optionDisplayField="name"
          onSelect={onGroupBySelect}
          disabled={isGroupByDisabled}
        />
      </div>
    );
  }

  renderDrilldownDimensions() {
    const { dimensionsEntityCounts } = this.state;
    const {
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      drilledDownDimensions,
      onDimensionSelect,
      currentDrilldownGroupByEntry,
      currentVisualizationType,
      currentDrilldownViewEntry,
      dateRange,
      currentSelectedTimeFrame,
      currentSnapshotView
    } = this.props;

    const dimensions_entries = getDimensionEntries(
      currentDrilldownTemplateId, currentVisualizationType, currentSnapshotView
    );
    const currentDrilldownGroupByColumn = _.get(currentDrilldownGroupByEntry, 'column', '');
    let availableDrilldownEntries = getAvailableDrilldownDropdownEntries({
      currentDrilldownTemplateId,
      drilledDownDimensions,
      currentDrilldownDimensionField,
      currentDrilldownGroupByColumn,
      currentVisualizationType,
      currentSnapshotView
    });
    const title_entry = _.find(dimensions_entries, { 'field': currentDrilldownDimensionField });
    const title = _.get(title_entry, 'name', 'select');
    let isDimensionDisabled = false
    let availableDrilldownEntriesWithCounts = _.map(availableDrilldownEntries, (entity) => {
      entity.count = _.get(dimensionsEntityCounts, `count_${entity.field}`)
      return entity;
    });
    const noneGroupEntry = _.find(availableDrilldownEntries, { 'name': 'None' });
    availableDrilldownEntriesWithCounts = isDimensionAscOrder(currentDrilldownTemplateId) ?
      _.sortBy(availableDrilldownEntriesWithCounts, 'name') :
      availableDrilldownEntriesWithCounts;
    if (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type) ||
      _.isEqual(currentVisualizationType, VISUALIZATION_TYPES.TABLE.type) ||
      (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.DISTRIBUTION.type) &&
        !showDimensionsInDistribution(currentDrilldownViewEntry)
      ) ||
      (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.OVERTIME.type) &&
        shouldDisableDimensions(dateRange, currentSelectedTimeFrame))) {
      isDimensionDisabled = true;
    }

    if (!_.isEmpty(noneGroupEntry)) {
      availableDrilldownEntries = _.reject(availableDrilldownEntries, { 'name': 'None' });
      availableDrilldownEntries.push(noneGroupEntry);
    }

    if (
      _.size(dimensions_entries) <= SENTENCE_BUILDER_MIN_OPTION_SIZE &&
      !isDimensionDisabled
    ) {
      return (<span className="title-only">{title}</span>);
    }

    return (
      <CustomDropdownList
        key="dimension-drop-down"
        id="drilldown"
        className="p-0  d-flex"
        title={title}
        showCount={true}
        optionDisplayField="name"
        options={availableDrilldownEntriesWithCounts}
        onSelect={onDimensionSelect}
        disabled={isDimensionDisabled}
      />
    );
  }

  renderDrilldownDisplayBy = () => {
    const { currentVisualizationType, currentDrilldownViewEntry } = this.props;
    const isDistributionChart = (currentVisualizationType === VISUALIZATION_TYPES.DISTRIBUTION.type);
    const visualizationTypeField = `visualization.${currentVisualizationType}`;
    const currentVisualization = _.get(currentDrilldownViewEntry, visualizationTypeField, {});
    const showDimensionsInDistribution = _.get(currentVisualization, 'show_dimensions', false) === 'true';

    if(isDistributionChart && !showDimensionsInDistribution){
      return null;
    }

    return (
      <div className="forge-popup-host">
        <div className="control-labels forge-typography--caption">Display by</div>
        {this.renderDrilldownDimensions()}
      </div>
    );
  }

  render() {
    const { currentVisualizationType } = this.props;

    return (
      <>
        <div>
          <div className="control-labels forge-typography--caption">Metric</div>
          {this.renderViewDropdown()}
        </div>
        {canRenderDrilldownDimension(currentVisualizationType) && this.renderDrilldownDisplayBy()}
        {canRenderGroupByDropdown(currentVisualizationType) && this.renderGroupByDropdown()}
      </>
    );
  }
}

DrillDownSentenceBuilder.defaultProps = {
  drilledDownDimensions: [],
  showTemplateDropDown: false
}

DrillDownSentenceBuilder.propTypes = {
  onViewSelect: PropTypes.func,
  onGroupBySelect: PropTypes.func,
  onDimensionSelect: PropTypes.func,
  onTemplateChange: PropTypes.func,
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  drilledDownDimensions: PropTypes.array,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  showTemplateDropDown: PropTypes.bool,
  apiParams: PropTypes.object,
  currentDrilldownDimensionField: PropTypes.string,
  currentVisualizationType: PropTypes.string,
  dateRange: PropTypes.object,
  currentSelectedTimeFrame: PropTypes.string,
  currentDrilldownTemplateName: PropTypes.string,
  templateEntries: commonPropTypes.templateEntriesPropTypes,
  currentSnapshotView: PropTypes.string,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  dispatchMetricSelection: PropTypes.func,
  currentUser: PropTypes.object,
  dispatchUpdateShowLegendTotal: PropTypes.func,
  dispatchSetCurrentVisualizationType: PropTypes.func,
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    templateEntries: _.get(state, 'configurations.template_entries', []),
    commonFilters: _.get(state, 'commonFilters', {}),
    apiParams: getApiParams(apiParamsOptions, {}),
    currentUser: _.get(state, 'currentUser', null),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    dateRange: _.get(state, 'commonFilters.dateRange'),
    currentSelectedTimeFrame: _.get(state, 'visualization.overtime.currentSelectedTimeFrame'),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', {}),

  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatchMetricSelection: (templateEntry, viewEntry, drilldownEntry,
    commonFilters, globalFilters, selectedCollectionId, selectedCollectionTagId) => {
    dispatchMetricSelection(dispatch, templateEntry,
      viewEntry, drilldownEntry, commonFilters, globalFilters);
    dispatch(setAnalysisPageCollectionId(selectedCollectionId));
    dispatch(setAnalysisPageCollectionTagId(selectedCollectionTagId));
    dispatch(updateOvertimeShowLegendTotal(true));
    dispatch(updateOvertimeIsEmptyLegend(false));
  },
  dispatchSetCurrentVisualizationType: (visualizationType) => {
    dispatch(setCurrentVisualizationType(visualizationType))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DrillDownSentenceBuilder);
