import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import { getBookmark, updateBookmark } from 'common/api/bookmarksApi';
import RadarSaveButton from './RadarSaveButton';
import { getBookmarkDefaultParams } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { createBookmark } from 'common/api/bookmarksApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { radarPageSaveMessage } from 'helpers/toastMessages';

class SaveButtonContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentBookmark: {},
      isLoading: false,
      hideSaveAsDropDown: true
    };
  }

  componentDidMount() {
    this.fetchBookmark(true);
  }

  componentDidUpdate(prevProps){
    const { bookmarkId, shouldUpdateBookmark, title, description } = this.props;
    const { isLoading } = this.state;
    if(!_.isEqual(bookmarkId, _.get(prevProps, 'bookmarkId'))){
      this.fetchBookmark();
    }
    if(shouldUpdateBookmark && !isLoading) {
      this.handleEditBookmark(title, description);
    }
  }

  fetchBookmark = (shouldPassParams = false) => {
    const { bookmarkId, onBookmarkChange } = this.props;
    const params = shouldPassParams ? { canUpdateBookmarkViewCount: true } : {};
    if(!_.isEmpty(bookmarkId)){
      getBookmark(bookmarkId, params)
        .then((response) => response.json())
        .then((response) => {
          this.setState({currentBookmark: response});
          onBookmarkChange(response);
        });
    } else {
      this.setState({currentBookmark: {}});
      onBookmarkChange({});
    }
  }

  getRadarOptions = () => {
    return _.pick(
      this.props,
      'selectedTracts',
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'selectedMetricEntries',
      'selectedShapeAreaEntry',
      'metricFilters',
      'metricGlobalFilters',
      'selectedMapMetric',
      'comparisonTracts',
      'isCheckedComparisonArea',
      'isCheckedUnSelectedArea',
      'comparisonAreaName',
      'selectAreaName',
      'totalAggregateType'
    );
  }

  handleSaveAsClick = (name, description) => {
    const { onBookmarkChange } = this.props;
    const bookmarkParams = {
      ...getBookmarkDefaultParams(this.props, {name, description, is_new: true }),
      is_radar: true,
      radarOptions: this.getRadarOptions()
    }
    const params = {
      shareEmails: [],
      bookmark: bookmarkParams,
      shareFieldInputs: {},
      include_current_user: true,
      ignore_view_entry: true,
    };

    this.setState({ isLoading: true, hideSaveAsDropDown: false });
    createBookmark(params).
      then(response => response.json()).
      then((response) => {
        const currentBookmark = _.get(_.values(response), '[0]', {})
        this.setState({
          isLoading: false,
          hideSaveAsDropDown: true,
          currentBookmark
        });
        onBookmarkChange(currentBookmark);
        if(response.error) {
          toast.error("error on saving multi-metric analysis view");
        } else {
          toast.success(radarPageSaveMessage);
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false, hideSaveAsDropDown: true });
        toast.error(`Error while creating view. ${err}`);
        console.error(err);   // eslint-disable-line no-console
      }).
      then(() => {
        // trackEvent('saved_view_created', {});
      });
  }

  handleEditBookmark = (name, description) => {
    const { bookmarkId, onBookmarkChange } = this.props;
    const { currentBookmark } = this.state;
    const { bookmarkOptions } = currentBookmark;
    if(!_.isEmpty(bookmarkId)){
      this.setState({ isLoading: true });
      const params = {
        ...currentBookmark,
        name: _.isEmpty(name) ? _.get(currentBookmark, 'name') : name,
        bookmarkOptions: {
          ...bookmarkOptions,
          description
        },
        is_radar: true,
        radarOptions: this.getRadarOptions()
      }
      updateBookmark(bookmarkId, params)
       .then((response) => response.json())
       .then((response) => {
          onBookmarkChange(response);
          this.setState({ isLoading: false, currentBookmark: response});
          toast.success(radarPageSaveMessage);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toast.error(`Error while editing radar view. ${err}`);
          console.error(err); // eslint-disable-line no-console
        });
    }
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  render() {
    const { isLoading, currentBookmark, hideSaveAsDropDown } = this.state;
    return(
      <div >
        {this.renderSpinner()}
        <RadarSaveButton {...this.props}
          currentBookmark={currentBookmark}
          bookmarkId={`${_.get(currentBookmark, 'id')}`}
          hideSaveAsDropDown={hideSaveAsDropDown}
          onEditBookmarkSave={this.handleEditBookmark}
          onBookmarkSave={this.handleSaveAsClick}
          isLoading={isLoading}/>
      </div>
    )
  }
}

SaveButtonContainer.propTypes = {
  bookmarkId: PropTypes.string,
  selectedTracts: PropTypes.array,
  selectedMetricEntries: PropTypes.array,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  selectedShapeAreaEntry: PropTypes.object,
  selectedMapMetric: PropTypes.object,
  metricFilters: PropTypes.object,
  onBookmarkChange: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  shouldUpdateBookmark: PropTypes.bool,
  selectAreaName: PropTypes.string,
  comparisonAreaName: PropTypes.string
}

export default SaveButtonContainer;
