import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ForecastingSideBar from './ForecastingSideBar/ForecastingSideBar';
import ForecastLineChart from './ForecastChart/ForecastLineChart';
import ForecastSummaryTable from './ForecastChart/ForecastSummaryTable';
import ReRunModal from './ReRunModal/ReRunModal';
import { withRouter, Redirect } from 'react-router-dom';
import {
  isComboChartEnable
} from 'common/config/visualizationConfiguration';
import ForecastAccuracy from './ForecastAccuracy';
import {
  updateForecastDateRange,
  updateForecastModelOptions,
  updateFutureForecastDateRange,
  updateForecastOptions,
  updateForecastOvertimeChartView,
  updateForecastAxisGranularity,
  updateProjectionAdjustedValues,
  updateForecastPageView,
  updateForecastBookmarkId
} from 'actions/forecastingActions';
import { setCurrentCollectionId } from 'actions/dashboardActions';
import LoadingSpinner from 'common/components/LoadingSpinner';
import {
  updateCurrentForecastOption,
  updateOvertimeDimensionSortOrder,
  updateOvertimeSecondaryMetricField,
  updateOvertimeShowProjection
} from 'actions/overtimeActions';
import {
  FORECASTING_TYPE,
  FORECAST_VIEW,
  HISTORICAL_TOTAL_DATA_COLOR,
  OVERTIME_VISUALIZATION_TYPES,
  PROPHET_FORECAST_COLOR,
  EXPONENTIAL_FORECAST_COLOR,
  FORECAST_PAGE_TYPES,
  HISTORICAL_FORECAST_COLOR
} from 'appConstants';
import {
  getForecastTitle,
  isForecastViewChanged,
  defaultPrepareDataAxisGranularityOption,
  getForecastChartAttributes,
  isForecastEndDateIsBeforeToday
} from './ForecastHelper';
import SaveContainer from './SaveContainer';
import BookmarkTitleDescription from 'pages/BookmarkTitleDescription';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import { getDefaultSecondaryMetricEntry } from 'helpers/visualizationHelper';
import {
  getOvertimeDimensionSortOrder
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import ForecastingBackButton from './ForecastingBackButton';
import ForecastLineChartOptions from './ForecastChart/ForecastLineChatOptions';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { ForgeButton } from '@tylertech/forge-react';
import {
  getLineChartSummaryFormatter
} from 'modules/visualization/SnapshotVisualization/SummaryTable/lineChartSummaryFormatter';
import {
  forecastExportDownload
} from 'modules/visualization/SnapshotVisualization/SummaryTable/forecastHelper';
import ForecastLegends from './ForecastChart/ForecastLegends';
import { isSmallForecastingChartSize, scrollLegendElementToTarget } from 'helpers/DomPageHelper';

class ForecastIndex extends Component {
  constructor(props, context) {
    super(props, context);
    const { currentForecastDateRange, axisGranularity } = this.props;
    this.state = {
      isChartDataLoading: false,
      isBack: false,
      isUpdateChartData: true,
      title: getForecastTitle(_.get(props, 'selectedForecastMetric')),
      description: '',
      shouldUpdateBookmark: false,
      currentBookmark: {},
      defaultForecastOptions: _.merge(_.cloneDeep(props), { projectionAdjustedValues: [] }),
      renderType: _.get(props, 'currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
      summaryFormattedData: [],
      isSmallView: true,
      formattedChartData: {},
      forecastCurrentView: "overtimeChart",
      leastGranularityApiData: {},
      isOpenLegend: true,
      legendWidth: 250,
      isNewDataAvailable: isForecastEndDateIsBeforeToday(currentForecastDateRange, axisGranularity)
    }

    this.abortFetchController = new AbortController();
  }

  componentDidUpdate(prevProps, prevState) {
    const { commonFilters, currentChartView, currentForecastDateRange, axisGranularity } = this.props;
    const isSameDatePeriod = _.isEqual(_.get(commonFilters, 'dateRange'),
      _.get(prevProps.commonFilters, 'dateRange'));

    const isTypeChange = !_.isEqual(currentChartView, prevProps.currentChartView);

    if (prevState.isUpdateChartData != (!isSameDatePeriod || isTypeChange)) {
      this.setState({
        isUpdateChartData: !isSameDatePeriod || isTypeChange,
      });
    }

    if (!_.isEqual(prevProps.currentForecastDateRange, currentForecastDateRange)) {
      this.setState(
        { isNewDataAvailable: isForecastEndDateIsBeforeToday(currentForecastDateRange, axisGranularity) }
      );
    }
  }

  renderLeftSideBar() {
    const {
      bookmarkId,
      currentDrilldownTemplateId,
      minDatesTemplateForForecast,
      commonFilters,
      dispatchUpdateForecastDateRange,
      dispatchUpdateFutureForecastDateRange,
      dispatchUpdateForecastModelOptions,
      dispatchUpdateAxisGranularity,
      dispatchUpdateCurrentForecastOption,
      dispatchUpdateShowProjection,
      currentForecastDateRange,
      futureForecastDateRange,
      selectedForecastMetric,
      selectedForecastTemplateId,
      forecastModelOptions,
      currentChartView,
      axisGranularity,
      projectionAdjustedValues
    } = this.props;
    const { isChartDataLoading, currentBookmark, formattedChartData, leastGranularityApiData } = this.state;

    const isBurnup = currentChartView === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
    const isDiscreteMode = isDiscreteModeData(selectedForecastMetric);

    const forecastAttributes = {
      currentDrilldownTemplateId,
      minDatesTemplateForForecast,
      commonFilters,
      dispatchUpdateForecastDateRange,
      dispatchUpdateFutureForecastDateRange,
      dispatchUpdateAxisGranularity,
      onUpdateToggleLeftside: this.onUpdateToggleLeftside,
      currentBookmark,
      forecastAttributeOptions: {
        dispatchUpdateForecastModelOptions,
        dispatchUpdateCurrentForecastOption,
        dispatchUpdateShowProjection,
        currentForecastDateRange,
        futureForecastDateRange,
        selectedForecastMetric,
        selectedForecastTemplateId,
        currentChartView,
        forecastModelOptions,
        isChartDataLoading,
        axisGranularity,
        isDiscreteMode,
        isBurnup,
        isAverageMetric: _.get(selectedForecastMetric, 'aggregate_type', '') === 'avg',
        projectionAdjustedValues,
        onUpdateForecastPrepareData: this.onUpdateForecastPrepareData,
        formattedChartData,
        leastGranularityApiData
      }
    }
    const isBookmarkLoaded = _.isEmpty(bookmarkId) ? true : !_.isEmpty(currentBookmark);
    if (!isBookmarkLoaded || _.isEmpty(selectedForecastMetric)) {
      return null;
    }
    return (
      <ForecastingSideBar {...forecastAttributes} />
    )
  }

  onUpdateToggleLeftside = (isSmallView) => {
    this.setState({
      isSmallView: isSmallView
    });
  }

  onUpdateSummaryData = (data) => {
    this.setState({
      summaryFormattedData: data
    });
  }

  onSummaryTableLoading = (loading) => {
    this.setState({
      isChartDataLoading: loading
    });
  }

  handleSelectView = (currentChartView) => {
    const {
      selectedForecastMetric,
      dispatchUpdateChartView
    } = this.props;

    const defaultSecondaryMetric = getDefaultSecondaryMetricEntry(
      selectedForecastMetric, 'overtime', currentChartView);
    const dimensionSortOrder = getOvertimeDimensionSortOrder(selectedForecastMetric, currentChartView);
    this.setState({ renderType: currentChartView });

    dispatchUpdateChartView({
      currentChartView, dimensionSortOrder,
      field: _.get(defaultSecondaryMetric, 'field', ''),
      projectionAdjustedValues: []
    });
  }

  onClickBackButton = (shouldSave) => {
    const forecastLocation = _.get(window, 'forecastFromPage', '/');
    if (_.includes(['/analysis', '/overview', '/'], forecastLocation)) {
      if (!_.isEmpty(this.props.savedForecasts)) {
        this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE)
        this.props.history.push('/forecasting-saved');
      } else {
        this.setState({ isBack: true, shouldUpdateBookmark: shouldSave }, () => {
          this.props.dispatchUpdateProjectionAdjustedValues({});
          this.props.dispatchUpdateForecastModelOptions([]);
          this.props.dispatchUpdateShowProjection(false);
          this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE)
        });
      }
    } else {
      if (!_.isEmpty(this.props.savedForecasts)) {
        this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE)
        this.props.history.push('/forecasting-saved');
      } else {
        this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE)
        this.props.history.push('/forecasting-metrics');
      }
    }
  }

  handleBookmarkChange = (currentBookmark) => {
    const { selectedForecastMetric, dispatchForecastOptions } = this.props;
    const title = _.get(currentBookmark, 'name', getForecastTitle(selectedForecastMetric));
    const description = _.get(currentBookmark, 'bookmarkOptions.description', '');
    this.setState({
      currentBookmark, title, description,
      shouldUpdateBookmark: false
    }, () => {
      let options = _.cloneDeep(_.get(currentBookmark, 'forecastOptions', {}));
      options['bookmarkId'] = currentBookmark.id;
      dispatchForecastOptions(options);
    });
  }

  handleNameDescriptionChange = (title, description) => {
    this.setState({ title, description, shouldUpdateBookmark: true });
  }

  onDataLoading = (isLoading) => {
    if (!_.isNil(isLoading)) {
      this.setState({ isChartDataLoading: isLoading });
    }
  };

  onUpdateChartData = (formattedChartData, leastGranularityApiData) => {
    this.setState({ formattedChartData, leastGranularityApiData });
  }

  onForecastCurrentView = (view) => {
    this.setState({ forecastCurrentView: view });
  }

  onUpdateForecastPrepareData = (tableFormattedData) => {
    this.props.dispatchUpdateProjectionAdjustedValues(tableFormattedData);
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend: isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend, true);
  }

  renderBackButton() {
    const { defaultForecastOptions, currentBookmark } = this.state;
    const isForecastChanged = isForecastViewChanged(this.props, currentBookmark, defaultForecastOptions)
    return (
      <ForecastingBackButton
        shouldEnableModel={isForecastChanged}
        onSaveConfirm={this.onClickBackButton}>
      </ForecastingBackButton>
    );
  }

  onSummaryDownload = () => {
    const { summaryFormattedData } = this.state;
    const { currentDrilldownTemplateId } = this.props;
    const summaryTableOptions = this.getSummaryTableOptions();
    const summaryTableData = getLineChartSummaryFormatter(summaryFormattedData, summaryTableOptions);
    const options = {
      currentDrilldownTemplateId,
      tableHeaders: _.get(summaryTableData, 'tableHeaders'),
      tableData: _.get(summaryTableData, 'tableData')
    }
    forecastExportDownload(options);
  }

  renderExportButton = () => {
    return (
      <ForgeButton type="outlined">
        <button
          className="ml-auto"
          onClick={() => this.onSummaryDownload()}
          id='forecast_export'
          >
          Export
        </button>
      </ForgeButton>
    )
  }

  renderActionButtons = () => {
    const { title, description, shouldUpdateBookmark, defaultForecastOptions } = this.state;
    return (
      <div className="action-buttons d-flex gap-10">
        <SaveContainer
          title={title}
          onDataLoading={this.onDataLoading}
          description={description}
          defaultForecastOptions={defaultForecastOptions}
          dispatchSetCurrentCollectionId={this.props.dispatchSetCurrentCollectionId}
          projectionAdjustedValues={this.props.projectionAdjustedValues}
          shouldUpdateBookmark={shouldUpdateBookmark}
          onBookmarkChange={this.handleBookmarkChange}
          {...this.props} />
        {this.renderExportButton()}
      </div>
    )
  }

  renderBack = () => {
    const origin = _.get(window, 'forecastFromPage', '/');
    return (
      <Redirect push to={origin} />
    )
  }

  renderTitle = () => {
    const { currentDrilldownViewEntry } = this.props;
    return getPrimaryMetricName(currentDrilldownViewEntry)
  }

  getForecastLegends = () => {
    const { formattedChartData } = this.state;

    if (_.isEmpty(formattedChartData) && _.isEmpty(formattedChartData['dimensionConfigs'])) {
      return null;
    }

    const { forecastModelOptions } = this.props;
    let legendItems = [{ traceId: 'Historical data', color: HISTORICAL_TOTAL_DATA_COLOR }]

    _.forEach(forecastModelOptions, (modelDatum) => {
      if (modelDatum.type == FORECASTING_TYPE.PROPHET) {
        legendItems.push({ traceId: modelDatum.name, color: PROPHET_FORECAST_COLOR });
      }
      if (modelDatum.type == FORECASTING_TYPE.HISTORICAL_AVG) {
        legendItems.push({
          traceId: modelDatum.name,
          color: _.get(modelDatum, 'color', HISTORICAL_FORECAST_COLOR)
        });
      }
      if (modelDatum.type == FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
        legendItems.push({ traceId: modelDatum.name, color: EXPONENTIAL_FORECAST_COLOR });
      }
    })

    return legendItems;
  }

  updateLegendWidth = (legendWidth) => {
    this.setState({ legendWidth });
  }

  renderOverTimeViewOptions = () => {

    const { forecastCurrentView, isOpenLegend, isSmallView } = this.state;
    const { currentChartView, currentDrilldownViewEntry,
      dispatchUpdateAxisGranularity, axisGranularity,
      currentDrilldownTemplateId } = this.props;

    const legendItems = this.getForecastLegends();

    return (
      <ForecastLineChartOptions
        axisGranularity={axisGranularity}
        onAxisGranularityChange={dispatchUpdateAxisGranularity}
        currentChartView={currentChartView}
        currentDrilldownViewEntry={currentDrilldownViewEntry}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        handleChartViewChange={this.handleSelectView}
        forecastCurrentView={forecastCurrentView}
        onForecastCurrentView={this.onForecastCurrentView}
        legendItems={legendItems}
        isOpenLegend={isOpenLegend}
        isOpenLeftPanel = {isSmallView}
        toggleClickLegendButton={this.toggleClickLegendButton}
        onUpdateLegendWidth={this.updateLegendWidth}
      />
    );
  }

  renderForecastLineChart = () => {
    const {
      renderType,
      isUpdateChartData,
      currentBookmark,
      forecastCurrentView,
      isOpenLegend,
      legendWidth
    } = this.state;
    const { projectionAdjustedValues, bookmarkId } = this.props;
    const isBookmarkLoaded = _.isEmpty(bookmarkId) ? true : !_.isEmpty(currentBookmark);
    const isChartView = forecastCurrentView === "overtimeChart";

    if (!isBookmarkLoaded || !isChartView) {
      return null;
    }

    return (
      <ForecastLineChart
        dimensionRowCount={0}
        currentChartView={renderType}
        isUpdateChartData={isUpdateChartData}
        projectionAdjustedValues={projectionAdjustedValues}
        onDataLoading={this.onDataLoading}
        onUpdateChartData={this.onUpdateChartData}
        onUpdateSummaryData={this.onUpdateSummaryData}
        onUpdateForecastPrepareData={this.onUpdateForecastPrepareData}
        onHandleSelectView={this.handleSelectView}
        isOpenLegend={isOpenLegend}
        legendWidth={legendWidth} />
    )
  }

  getSummaryTableOptions = () => {
    const { currentDrilldownViewEntry, currentForecastDateRange,
      currentChartView, forecastModelOptions, axisGranularity } = this.props;

    return {
      viewEntry: currentDrilldownViewEntry,
      projectionEnabled: !_.isEmpty(forecastModelOptions),
      isOvertimeChart: true,
      currentDrilldownViewEntry,
      isCurrencyDimensionField: false,
      compareYearRanges: [],
      dateRange: currentForecastDateRange,
      isForecastingChart: true,
      isComboChart: isComboChartEnable(currentDrilldownViewEntry, currentChartView),
      viewMode: 'large',
      renderType: currentChartView,
      forecastModelOptions: forecastModelOptions,
      axisGranularity: axisGranularity
    };
  }

  renderForecastSummaryTable = () => {
    const { renderType, forecastCurrentView } = this.state;
    const { currentDrilldownViewEntry, currentDrilldownTemplateId, currentChartView,
      axisGranularity, selectedForecastMetric, futureForecastDateRange,
      projectionAdjustedValues, currentForecastDateRange, selectedForecastTemplateId,
      forecastModelOptions } = this.props;
    const isTableView = forecastCurrentView === FORECAST_VIEW.SUMMARY_TABLE;
    const isComboChart = isComboChartEnable(currentDrilldownViewEntry, renderType);
    const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(currentDrilldownTemplateId);
    const summaryTableOptions = this.getSummaryTableOptions();
    const isDiscreteMode = isDiscreteModeData(selectedForecastMetric);
    const isBurnup = currentChartView === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;

    const forecastAttributeOptions = {
      currentForecastDateRange,
      futureForecastDateRange,
      selectedForecastMetric,
      selectedForecastTemplateId,
      currentChartView,
      forecastModelOptions,
      axisGranularity,
      isDiscreteMode,
      isBurnup,
      isAverageMetric: _.get(selectedForecastMetric, 'aggregate_type', '') === 'avg',
      projectionAdjustedValues
    }

    const forecastOptions = { ...forecastAttributeOptions, isComboChart };
    forecastOptions['defaultAxisGranularity'] = defaultAxisGranularity;
    if (_.isEmpty(axisGranularity)) {
      forecastOptions['axisGranularity'] = defaultAxisGranularity;
    }

    let lineChartAttributes = getForecastChartAttributes({
      forecastOptions,
      viewEntry: selectedForecastMetric,
      templateId: currentDrilldownTemplateId,
      commonFilters: {}
    });
    lineChartAttributes['isForecastingView'] = true;
    lineChartAttributes['futureForecastDateRange'] = futureForecastDateRange;

    if (isComboChart || !isTableView) {
      return null
    }

    return (
      <ForecastSummaryTable
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        onUpdateSummaryData={this.onUpdateSummaryData}
        onSummaryTableLoading={this.onSummaryTableLoading}
        summaryTableOptions={summaryTableOptions}
        lineChartAttributes={lineChartAttributes}
      />
    )
  }

  renderLegendForMobileView = () => {
    const { isOpenLegend, forecastCurrentView } = this.state;
    const isTableView = forecastCurrentView === FORECAST_VIEW.SUMMARY_TABLE;

    if (!isSmallForecastingChartSize() || isTableView) {
      return null;
    }

    const legendItems = this.getForecastLegends();

    return (<ForecastLegends
      legendItems={legendItems}
      isOpenLegend={isOpenLegend}
      toggleClickLegendButton={this.toggleClickLegendButton}
      fromMobileView={true} />
    )
  }

  renderForecastHeader = () => {
    return (
      <div className="header-info d-flex flex-wrap gap-10">
        <div className="forge-typography--headline5">
          {this.renderTitle()}
        </div>
        <div className="overtime-options gap-10 ml-auto">
          {this.renderOverTimeViewOptions()}
        </div>
      </div>
    );
  }

  render() {
    const { isChartDataLoading, isBack, description, isNewDataAvailable,
      title, currentBookmark, shouldUpdateBookmark,
      isSmallView } = this.state;
    const { projectionAdjustedValues,
      axisGranularity,
      forecastModelOptions,
    } = this.props;

    if (isBack && !shouldUpdateBookmark) {
      return this.renderBack();
    }

    return (
      <main role="main" className="radar-page forecasting-page">
        <LoadingSpinner isLoading={isChartDataLoading} />
        <div className='page-main-head pl-2 flex-wrap'>
            <BookmarkTitleDescription
              renderBackButton={this.renderBackButton()}
              renderActionButtons={this.renderActionButtons()}
              currentBookmark={currentBookmark}
              title={title}
              showInputEditByDefault={true}
              hideBasedOnMessage={true}
              onNameDescriptionChange={this.handleNameDescriptionChange}
              description={description} />
        </div>
        <div className='radar-container '>
          {/* Left sidebar */}
          {this.renderLeftSideBar()}

          {/* Right side  */}
          <div className='radar-right-side forecasting-right'
            ref={(ref) => this.forecastingContainer = ref}>
            {isNewDataAvailable && <ReRunModal></ReRunModal>}
            <header className='radar-page-top'></header>
            <div className='radar-right-side-body' id="id-forecasting-chart-card">
              {this.renderForecastHeader()}
              {this.renderForecastLineChart()}
              {this.renderLegendForMobileView()}
              {this.renderForecastSummaryTable()}
            </div>
            {axisGranularity == 'month' && !_.isEmpty(forecastModelOptions) &&
              <ForecastAccuracy
                projectionAdjustedValues={projectionAdjustedValues}
                isSmallView={isSmallView}
              ></ForecastAccuracy>
            }
          </div>
        </div>
      </main>
    );
  }
}

ForecastIndex.propTypes = {
  minDatesTemplateForForecast: PropTypes.array,
  currentDrilldownTemplateId: PropTypes.any,
  dispatchForecastOptions: PropTypes.func,
  dispatchUpdateForecastDateRange: PropTypes.func,
  dispatchUpdateFutureForecastDateRange: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  dispatchUpdateForecastModelOptions: PropTypes.func,
  dispatchUpdateCurrentForecastOption: PropTypes.func,
  dispatchUpdateShowProjection: PropTypes.func,
  dispatchUpdateChartView: PropTypes.func,
  dispatchUpdateProjectionAdjustedValues: PropTypes.func,
  dispatchSetCurrentCollectionId: PropTypes.func,
  dispatchUpdateSecondaryMetric: PropTypes.func,
  commonFilters: PropTypes.shape({}),
  history: PropTypes.object,
  currentChartView: PropTypes.string,
  futureForecastDateRange: PropTypes.object,
  currentForecastDateRange: PropTypes.object,
  selectedForecastMetric: PropTypes.object,
  selectedForecastTemplateId: PropTypes.any,
  forecastModelOptions: PropTypes.array,
  axisGranularity: PropTypes.string,
  projectionAdjustedValues: PropTypes.array,
  bookmarkId: PropTypes.string,
  currentDrilldownViewEntry: PropTypes.object,
  savedForecasts: PropTypes.array,
  dispatchUpdateForecastPageView: PropTypes.func
}

function mapStateToProps(state) {
  const currentDrilldownViewEntry = _.get(state, 'forecasting.selectedForecastMetric', {});
  const currentChartView = _.get(
    state, 'forecasting.currentChartView') || OVERTIME_VISUALIZATION_TYPES.TIMELINE.type;
  const currentDrilldownTemplateId = _.get(state, 'forecasting.selectedForecastTemplateId', '');
  const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(currentDrilldownTemplateId);
  return {
    bookmarkId: _.get(state, 'forecasting.bookmarkId', '') + '',
    selectedForecastTemplateId: currentDrilldownTemplateId,
    selectedForecastMetric: currentDrilldownViewEntry,
    currentDrilldownViewEntry: currentDrilldownViewEntry,
    currentDrilldownTemplateId: currentDrilldownTemplateId,
    minDatesTemplateForForecast: _.get(state, 'forecasting.minDatesTemplateForForecast', []),
    futureForecastDateRange: _.get(state, 'forecasting.futureForecastDateRange', {}),
    currentForecastDateRange: _.get(state, 'forecasting.currentForecastDateRange', {}),
    forecastModelOptions: _.get(state, 'forecasting.forecastModelOptions', []),
    forecastPrepareDataTime: _.get(state, 'forecasting.forecastPrepareDataTime', ""),
    commonFilters: _.get(state, 'commonFilters', {}),
    axisGranularity: _.get(state, 'forecasting.axisGranularity', defaultAxisGranularity),
    currentDrilldownDimensionField: _.get(state, 'forecasting.currentDrilldownDimensionField'),
    dimensionConfigsByRenderType: _.get(state, 'forecasting.dimensionConfigsByRenderType', {}),
    projectionAdjustedValues: _.get(state, 'forecasting.projectionAdjustedValues', []),
    currentChartView: currentChartView
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchForecastOptions: (forecastOptions) => {
      dispatch(updateForecastOptions(forecastOptions));
    },
    dispatchUpdateForecastDateRange: (dateRange) => {
      dispatch(updateForecastDateRange(dateRange));
    },
    dispatchUpdateFutureForecastDateRange: (futureDate) => {
      dispatch(updateFutureForecastDateRange(futureDate));
    },
    dispatchUpdateForecastModelOptions: (forecastModelOptions) => {
      dispatch(updateForecastModelOptions(forecastModelOptions));
    },
    dispatchUpdateAxisGranularity: (axisGranularity) => {
      dispatch(updateForecastAxisGranularity(axisGranularity));
    },
    dispatchUpdateCurrentForecastOption: (forecastOption) => {
      dispatch(updateCurrentForecastOption(forecastOption));
    },
    dispatchUpdateShowProjection: (enable) => {
      dispatch(updateOvertimeShowProjection(enable));
    },
    dispatchUpdateProjectionAdjustedValues: (adjustedValues) => {
      const updateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      dispatch(updateProjectionAdjustedValues(adjustedValues, updateTime));
    },
    dispatchUpdateChartView: (options) => {
      const { currentChartView, dimensionSortOrder, field, projectionAdjustedValues } = options;
      dispatch(updateOvertimeDimensionSortOrder(dimensionSortOrder));
      dispatch(updateOvertimeSecondaryMetricField(field));
      dispatch(updateForecastOvertimeChartView(currentChartView));
      dispatch(updateProjectionAdjustedValues(projectionAdjustedValues));
    },
    dispatchSetCurrentCollectionId: (collectionId) => {
      dispatch(setCurrentCollectionId(collectionId))
    },
    dispatchUpdateForecastPageView: (currentForecastPage) => {
      dispatch(updateForecastPageView(currentForecastPage))
      dispatch(updateForecastBookmarkId(""))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForecastIndex));
