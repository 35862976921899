import React from 'react';
import { getAcronym } from './helper';
import PropTypes from 'prop-types';

const ApplicationList = ({ appConfig,cardRef }) => {
  const {
    application,
    is_user_in_security_list: isAdminForCurrentCard,
  } = appConfig;

  const { name, app_url } = application;
  const acronymLength = 3;
  
  const openInNewWindow = (url) => {
    window.open(`http://${url}`, '_blank', 'noopener,noreferrer');
  };

  const renderAppTitle = () => {
    return (
      <span className="forge-typography--subtitle1-secondary">
        {getAcronym(name, acronymLength)}
      </span>
    );
  };

  const renderCardBody = () => {
    return (
      <div onClick={() => isAdminForCurrentCard && openInNewWindow(app_url)}>
        <div className="app-card cursor-pointer">
          <div className="app-card-profile">{renderAppTitle()}</div>
          <span className="forge-typography--body2 line-clamp">{name}</span>
        </div>
      </div>
    );
  };

  return <div className="card-container app-item" ref={cardRef}>{renderCardBody()}</div>;
};

ApplicationList.propTypes = {
  appConfig: PropTypes.object,
  cardRef: PropTypes.object
};

export default ApplicationList;
