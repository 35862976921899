import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import { MONTHLY_REGULARITY_TYPES } from 'appConstants';

import FrequencySettings from 'common/components/FrequencySettings/FrequencySettings';
import FrequencySettingOption from 'common/components/FrequencySettings/FrequencySettingOption';
import EmailArrivalText from 'common/components/FrequencySettings/EmailArrivalText';

class SubscriptionFrequencyOptions extends Component {
  constructor(props, context) {
    super(props, context);
    const { currentSubscription } = props;

    this.state = {
      frequency: _.get(currentSubscription, 'frequency'),
      frequencyInterval: _.get(currentSubscription, 'frequency_interval'),
      monthlyWeekNumber: _.get(currentSubscription, 'week_number_for_month'),
      emailSendingTime: _.get(currentSubscription, 'email_sending_time'),
      timeZone: _.get(currentSubscription, 'time_zone'),
      emailSendingDays: _.get(currentSubscription, 'email_sending_days'),
      startDate: _.get(currentSubscription, 'start_date', new Date()),
      regularityType: _.get(currentSubscription, 'regularity_type', MONTHLY_REGULARITY_TYPES.WEEK),
      timePeriodDay: _.get(currentSubscription, 'time_period_day', 1),
    };
  }

  handleWeekdaysOptionsChange = (isChecked, day) => {
    const { emailSendingDays } = this.state;
    let selectedDays = _.cloneDeep(emailSendingDays);
    if(!isChecked && selectedDays.length === 1){
      return;
    }
    selectedDays = isChecked ? [...selectedDays, day] : _.without(selectedDays, day)

    this.setState({emailSendingDays: selectedDays})
  }

  onSettingValueChange = (value, field) =>{
    let formattedValue = value;
    this.setState({ [field]: formattedValue }, () => {
      this.props.onFreqOptionsChange(this.state);
    });
  }

  renderFrequencySettings = () => {
    const {
      frequency,
      timeZone,
      frequencyInterval,
      emailSendingTime,
      startDate
    } = this.state;

    const frequencySettingsAttributes = {
      frequency,
      timeZone,
      frequencyInterval,
      emailSendingTime,
      startDate,
      onSettingValueChange: this.onSettingValueChange
    };

    return (<FrequencySettings {...frequencySettingsAttributes}/>);
  }

  renderWeekdaysOption = () => {
    const frequencySettingsOptionAttributes = _.pick(this.state,
      'emailSendingDays',
      'frequency',
      'monthlyWeekNumber',
      'startDate',
      'regularityType',
      'timePeriodDay',
      'timeZone',
      'frequencyInterval',
      'emailSendingTime'
    );

    return (
      <FrequencySettingOption
        {...frequencySettingsOptionAttributes}
        handleWeekdaysOptionsChange={this.handleWeekdaysOptionsChange}
        onSettingValueChange={this.onSettingValueChange}
      />
    );
  }

  emailCadenceText = () => {
    const options = _.pick(this.state,
      'emailSendingDays',
      'frequency',
      'monthlyWeekNumber',
      'startDate',
      'timePeriodDay',
      'regularityType',
      'timeZone',
      'frequencyInterval',
      'emailSendingTime'
    );

    return(<EmailArrivalText {...options}/>);
  }

  handleSaveClick = () => {
    const { onSaveClick } = this.props;
    const { frequencyInterval } = this.state;
    if(!frequencyInterval || (frequencyInterval < 1)) {
      toast.error('Error while saving changes');
      return;
    }
    onSaveClick(this.state);
  }

  renderSaveButton = () => {
    const { isRecipientsEmailError } = this.props;
    const { frequencyInterval } = this.state;
    const isDisabled = (!frequencyInterval || (frequencyInterval < 1)  || isRecipientsEmailError);
    return (
      <forge-button type="raised" class="float-right">
        <button
          disabled = {isDisabled}
          onClick={this.handleSaveClick}>
          Save
        </button>
      </forge-button>
    )
  }

  renderCancelButton = () => {
    return (
      <forge-button>
        <button
          onClick={this.props.onCancel}>
          Cancel
        </button>
      </forge-button>
    )
  }

  render() {
    return (
      <div className="subscription-settings">
        <div>
          Send an email of this information every
        </div>
        {this.renderFrequencySettings()}
        {this.renderWeekdaysOption()}
        {this.emailCadenceText()}
        <div className="mt-1">
          {this.renderCancelButton()}
          {this.renderSaveButton()}
        </div>
      </div>
    );
  }
}

SubscriptionFrequencyOptions.propTypes = {
  onSaveClick: PropTypes.func,
  onCancel: PropTypes.func,
  onFreqOptionsChange: PropTypes.func,
  name: PropTypes.string,
  isEditMode: PropTypes.bool,
  currentSubscription: PropTypes.object,
  isRecipientsEmailError: PropTypes.bool,
}

SubscriptionFrequencyOptions.defaultProps = {
  onCancel: _.noop
}

export default SubscriptionFrequencyOptions;
