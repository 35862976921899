import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FlipMove from 'react-flip-move';

import {
  CARD_TYPES,
  COLLECTION_ID,
  EMAIL_STRATEGY_OPTIONS,
  BLANK_USER_COLLECTION_MESSAGE
} from 'appConstants';
import { getNumberOfCardsPerRow, isBottomOfPageReached, getNumberRow } from 'helpers/DomPageHelper';
import { withCardClickHandler } from './CardClickHandler';
import EmptyBookmarkText from './EmptyBookmarkText';
import LoadingSpinner from 'common/components/LoadingSpinner';
import Card from './Card';
import { deleteCard } from 'common/api/collectionApi';
import { templateEntriesPropTypes } from 'common/propTypes';
import {
  isMyViews,
  getCardCountConfig,
  getCardSizeAndLimitBasedOnCardType
} from 'pages/dashboard/components/CardList/cardHelper';
import { getExplorationCardEntries  } from 'common/config/customerConfiguration';
const DEFAULT_ROW_COUNT = 2;

class CardList extends Component {
  constructor(props, context) {
    super(props, context);
    const cardsPerRow = getNumberOfCardsPerRow();

    this.state = {
      redirectToAnalysis: false,
      redirectToRadar: false,
      redirectToForecast: false,
      redirectToSubscriptionPage: false,
      currentCardsLimit: cardsPerRow,
      isCardLoading: false,
      redirectToAdvanceSearch: false,
      cardsSizeAndLimit: {},
      row: DEFAULT_ROW_COUNT
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handlePageScroll);
    window.addEventListener('resize', this.handlePageScroll);
    const cardsPerRow = getNumberOfCardsPerRow();

    if(!_.isNull(cardsPerRow)) {
      this.setState({ currentCardsLimit: (cardsPerRow * getNumberRow()) })
    }
  }

  componentDidUpdate(prevProps) {
    const { cardEntries, cardType } = this.props;
    const isCardEntriesChanged = !_.isEqual(cardEntries, prevProps.cardEntries);

    if (!_.isEmpty(cardEntries) && isCardEntriesChanged) {
      const cardsPerRow = getNumberOfCardsPerRow();
      const cardsSizeAndLimit = getCardSizeAndLimitBasedOnCardType(cardEntries, cardType, cardsPerRow);
      const { cardsLimit, row } = getCardCountConfig(cardsSizeAndLimit, getNumberRow());

      this.setState({ cardsSizeAndLimit, currentCardsLimit: cardsLimit, row });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
    window.removeEventListener('resize', this.handlePageScroll);
  }

  getCardKey = (cardEntry, index) => {
    const { cardType, collectionEntry } = this.props;
    const collectionId = _.get(collectionEntry, 'id');
    const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
    // Some time we have bookmark cards in collections.
    const newCardType = isBookmarkCard ? CARD_TYPES.BOOKMARK : cardType;
    if (newCardType == CARD_TYPES.BOOKMARK) {
      const cardId = isBookmarkCard ? _.get(cardEntry, 'cardEntry.id', cardEntry.id) : cardEntry.id;
      return `${collectionId}-${newCardType}-${cardEntry.name}-${cardId}`;
    } else if(_.get(cardEntry, 'isExplorationCard')){
      return `${collectionId}-exploration-${cardEntry['exploration_id']}-${index}-${cardEntry.id}`
    }else {
      const { templateEntry, viewEntry, userCardEntry } = cardEntry;
      let templateId = _.get(templateEntry, 'template_id');
      const viewId = _.get(viewEntry, 'view_id');
      const isRadar = _.get(templateEntry, 'isRadar', false);
      templateId = isRadar ? `${templateId}${index}` : templateId;

      return `${collectionId}-${newCardType}-${templateId}-${viewId}-${_.get(
        userCardEntry,
        'id'
      )}`;
    }
  };

  handlePageScroll = () => {
    const { cardEntries } = this.props;
    const { currentCardsLimit, cardsSizeAndLimit, row } = this.state;
    const shouldUpdateCardLimit = isBottomOfPageReached();

    if (shouldUpdateCardLimit && cardEntries.length > currentCardsLimit) {
      const newRow =  row + 1;
      const { cardsLimit } = getCardCountConfig(cardsSizeAndLimit, newRow, true);

      this.setState({ currentCardsLimit: cardsLimit, row: newRow });
    }
  };

  handleCardDelete = (card) => {
    const { collectionEntry, onCardDelete } = this.props;
    const isBookmarkCard = _.get(card, 'isBookmarkCard', false);
    const cardId = isBookmarkCard ? _.get(card, 'cardEntry.id') : _.get(card, 'id');
    const collectionId = _.get(collectionEntry, 'id');

    this.setState({ isCardLoading: true });

    deleteCard(collectionId, cardId)
      .then((response) => response.json())
      .then(() => {
        onCardDelete({ userCardEntry: card });
        this.setState({ isCardLoading: false });
      })
      .catch((error) => {
        console.log('Error on  deleting card', error);
        this.setState({ isCardLoading: false });
      });
  };

  onCardClick = (cardType, cardConfig, defaultCommonFilters) => {
    const { onCardClick } = this.props;
    // /app/subscriptions
    const { email_strategy, is_radar, is_forecast, is_custom_advance_search } = _.cloneDeep(cardConfig);
    let isBookmarkSubscriptionCard = false;

    if(email_strategy != EMAIL_STRATEGY_OPTIONS[0].type) {
      isBookmarkSubscriptionCard = true
    }
    if((cardType == CARD_TYPES.BOOKMARK) && is_radar){
      this.setState({ redirectToRadar: true }, () => {
        onCardClick(cardType, cardConfig, defaultCommonFilters);
      });
    }else if((cardType == CARD_TYPES.BOOKMARK) && is_forecast){
      this.setState({ redirectToForecast: true }, () => {
        onCardClick(cardType, cardConfig, defaultCommonFilters);
      });
    }else if((cardType == CARD_TYPES.BOOKMARK) && is_custom_advance_search){
      this.setState({ redirectToAdvanceSearch: true }, () => {
        onCardClick(cardType, cardConfig, defaultCommonFilters);
      });
    }else if((cardType == CARD_TYPES.BOOKMARK) && isBookmarkSubscriptionCard){
      this.setState({ redirectToSubscriptionPage: true }, () => {
        onCardClick(cardType, cardConfig, defaultCommonFilters);
      });
    }else {
      this.setState({ redirectToAnalysis: true }, () => {
        onCardClick(cardType, cardConfig, defaultCommonFilters);
      });
    }
  };

  renderBookmarkHelpText() {
    const { cardType, cardEntries, isLoading, isCollectionCardLoading } = this.props;

    if (
      cardType != CARD_TYPES.BOOKMARK ||
      !_.isEmpty(cardEntries) ||
      isLoading
    ) {
      return null;
    }
    const filteredCardEntries = _.filter(cardEntries, (cardEntry) => {
      return !cardEntry.is_hidden;
    });
    if (_.isEmpty(filteredCardEntries) && !isLoading && !isCollectionCardLoading) {
      return <EmptyBookmarkText />
    }
  }

  renderSpinner() {
    const { isLoading, isCollectionCardLoading } = this.props;
    const { isCardLoading } = this.state;

    return <LoadingSpinner isLoading={isLoading || isCardLoading || isCollectionCardLoading} />;
  }

  reloadBookmarks = (arg = {}) => {
    this.props.reloadBookmarks(arg);
    this.props.onReloadCollection(true);
  }

  renderCard(cardEntry, key) {
    const {
      bookmarks,
      currentUser,
      newLabelCards,
      cardType,
      onCardMove,
      collectionEntry,
      globalFilters,
      commonFilters,
      userCards,
      cardEntries,
      templateEntries
    } = this.props;
    // Some time we have bookmark cards in collections.
    const newCardType = _.get(cardEntry, 'isBookmarkCard') ? CARD_TYPES.BOOKMARK : cardType;

    // Not showing hidden bookmark cards in MyViews
    if(isMyViews(collectionEntry) && cardEntry['is_hidden']){
      return null;
    }
    return (
      <Card
        newLabelCards={newLabelCards}
        bookmarks={bookmarks}
        collectionEntry={collectionEntry}
        onCardDelete={this.handleCardDelete}
        currentUser={currentUser}
        cardEntry={cardEntry}
        reloadBookmarks={this.reloadBookmarks}
        onCardClick={this.onCardClick}
        onCardMove={onCardMove}
        key={`${key}`}
        index={key}
        cardType={newCardType}
        commonFilters={commonFilters}
        globalFilters={globalFilters}
        userCards={userCards}
        cardEntries={cardEntries}
        templateEntries={templateEntries}
      />
    );
  }

  renderCards() {
    const { cardEntries } = this.props;
    const { currentCardsLimit } = this.state;

    return _.take(cardEntries, currentCardsLimit).map((cardEntry, index) => {
      return this.renderCard(cardEntry, this.getCardKey(cardEntry, index));
    });
  }

  renderBlankCollection(){
    const { cardType, cardEntries, isLoading, collectionEntry, isCollectionCardLoading } = this.props;
    const isAllMetrics = _.isEqual(collectionEntry.id, COLLECTION_ID.ALL_METRICS);
    const blankMessage = isAllMetrics ? '' : BLANK_USER_COLLECTION_MESSAGE;
    if (
      cardType != CARD_TYPES.TEMPLATE ||
      !_.isEmpty(cardEntries) ||
      isLoading || isCollectionCardLoading
    ) {
      return null;
    }

    if (_.isEmpty(cardEntries) && !isLoading && !isCollectionCardLoading) {
      return (
        <div className="mt-10 text-center helpful-text" role="listitem">
          <img alt= "Empty collection" className="mb-6" src="../images/query_failed.png" />
          <div className="help-info-text">{blankMessage}</div>
        </div>
      );
    }

}
  renderExplorationCards = () => {
    const { collectionEntry } = this.props;
    const explorationCardEntries = getExplorationCardEntries();
    const isAllMetrics = _.isEqual(collectionEntry.id, COLLECTION_ID.ALL_METRICS);

    if (!isAllMetrics || _.isEmpty(explorationCardEntries)) {
      return null;
    }
    const cards = _.map(explorationCardEntries, (entry, index) => {
      const cardId = `${index}${entry['exploration_id']}`
      const cardEntry = {
        ...entry,
        userCardEntry: {...entry, isExplorationCard: true, card_id: cardId},
        isExplorationCard: true,
        explorationCardEntry: entry,
        hideDelete: false
      }
      return this.renderCard(cardEntry, this.getCardKey(cardEntry, index));
    });
    return cards;
  };

  render() {
    if (this.state.redirectToAnalysis) {
      return <Redirect push to="/analysis" />;
    }

    if (this.state.redirectToSubscriptionPage) {
      return <Redirect push to="/app/subscriptions" />;
    }
    if(this.state.redirectToRadar){
      return <Redirect push to="/radar" />;
    }
    if(this.state.redirectToForecast){
      return <Redirect push to="/forecasting" />;
    }
    if(this.state.redirectToAdvanceSearch){
      return <Redirect push to="/advanced-search" />;
    }
    return (
      <>
        {this.renderSpinner()}
        <FlipMove
          className="template-cards"
          leaveAnimation={false}
          enterAnimation={false}
        >
          {this.renderCards()}
          {this.renderExplorationCards()}
        </FlipMove>
        {this.renderBookmarkHelpText()}
        {this.renderBlankCollection()}
      </>
    );
  }
}

CardList.propTypes = {
  cardType: PropTypes.oneOfType([CARD_TYPES.BOOKMARK, CARD_TYPES.TEMPLATE]),
  isLoading: PropTypes.bool,
  cardEntries: PropTypes.array,
  bookmarks: PropTypes.array,
  globalFilters: PropTypes.array,
  currentUser: PropTypes.object,
  commonFilters: PropTypes.object,
  onCardMove: PropTypes.func,
  reloadBookmarks: PropTypes.func,
  onAcceptAllBookmarkClick: PropTypes.func,
  onCardClick: PropTypes.func,
  onCardDelete: PropTypes.func,
  newLabelCards: PropTypes.array,
  collectionEntry: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  userCards: PropTypes.array,
  templateEntries: templateEntriesPropTypes,
  isCollectionCardLoading: PropTypes.bool,
  onReloadCollection: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    templateEntries: _.get(state, 'configurations.template_entries', []),
  };
}

function mapDispatchToProps() {
  return {};
}

export default withCardClickHandler(
  connect(mapStateToProps, mapDispatchToProps)(CardList)
);
