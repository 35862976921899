import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';

import BookmarkTitle from 'pages/drilldown/components/Bookmark/BookmarkSaveButton/BookmarkTitle';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { trackEvent } from 'helpers/eventTracking';
import { ForgeButton } from '@tylertech/forge-react';
class SaveAndSaveAsButton extends Component {
  constructor(props, context) {
    super(props, context);
    const { name, description } = props;
    this.state = {
      isSaveState: false,
      showSaveAsDropDown: false,
      name: name + ' copy',
      description: description,
    };
  }

  componentDidUpdate(prevProps){
    const { isSaveState } = this.state;
    const { hideSaveAsDropDown } = this.props;
    if(!_.isEqual(hideSaveAsDropDown, prevProps.hideSaveAsDropDown)){
      if(hideSaveAsDropDown){
        this.setState({showSaveAsDropDown: false});
      }
    }
    const {
      bookmarkId,
      currentBookmark,
      isViewChanged,
      description,
      name
    } = this.props;
    let newSaveState = false;
    if(!_.isEmpty(bookmarkId) && !_.isEmpty(currentBookmark)){
      newSaveState = false;
      if(isViewChanged(this.props, currentBookmark)){
        newSaveState = true;
      }
    }
    if(!_.isEqual(newSaveState, isSaveState)){
      this.setState({isSaveState: newSaveState});
    }
    if((!_.isEqual(description, prevProps.description) || !_.isEqual(name, prevProps.name))
      && !_.isEmpty(currentBookmark)){
      this.setState({description, name});
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showSaveAsDropDown } = this.state;
    if (this.saveButtonRef && !this.saveButtonRef.contains(e.target)) {
      if(showSaveAsDropDown) {
        this.toggleSaveAsDropdown(false);
      }
    }
  }

  handleSaveAsClick = () => {
    const { name, description, savedTrackMessage} = this.state;
    if(!_.isEmpty(savedTrackMessage)){
      trackEvent(savedTrackMessage);
    }
    this.props.onBookmarkSave(name, description);
  }

  handleInputFieldChange = (e, field) => {
    this.setState({[field]: e.target.value});
  }

  toggleSaveAsDropdown = (showSaveAsDropDown) => {
    const { currentBookmark, saveAsTrackMessage } = this.props;
    if(showSaveAsDropDown){
      if(!_.isEmpty(currentBookmark)){
        this.setState({name: _.get(currentBookmark, 'name', '') + ' copy'});
      }
      if(!_.isEmpty(saveAsTrackMessage)){
        trackEvent(saveAsTrackMessage);
      }
    }
    this.setState({showSaveAsDropDown});
  }

  renderRadarSaveDropDown() {
    const { showSaveAsDropDown, name, description } = this.state;

    if(!showSaveAsDropDown){
      return null;
    }

    const filterClassNames = classNames('bookmark-dropdown-wrapper', {
      'dropdown-menu show': showSaveAsDropDown
    });

    return(
      <div className="bookmark-dropdown-wrappers">
        <div className={filterClassNames}>
          {this.renderSpinner()}
          <BookmarkTitle
            name={name}
            description = {description}
            onInputChange = {this.handleInputFieldChange} />

          <div className="dropdown-footer">
            <button
              className="btn btn-link"
              tabIndex={0}
              aria-label="cancel"
              onClick={() => this.toggleSaveAsDropdown(false)}>
              Cancel
            </button>
            <button
              disabled={_.isEmpty(name)}
              className="btn btn-primary ml-auto"
              tabIndex={0}
              aria-label="Save"
              onClick={() => this.handleSaveAsClick(false)}>
               Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderSaveButtonDropDown() {
    const { isSaveState } = this.state;
    const {
      bookmarkId, isLoading, onEditBookmarkSave, hideSaveAsButton,
      variant
    } = this.props;
    if((!isSaveState || _.isEmpty(bookmarkId)) && !hideSaveAsButton){
      return null;
    }
    return(
      <Dropdown as={ButtonGroup}>
        <Button
          disabled={isLoading}
          onClick={() => onEditBookmarkSave()} variant={variant} id='forecast_save'>
          Save
        </Button>
        <Dropdown.Toggle split disabled={isLoading}
          variant={variant} className="save-dropdown-icon"
          style={{width:'34px'}}
          aria-label="Save Options" />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary"
            onClick={() => {
              this.toggleSaveAsDropdown(true);
            }}
          >
            Save as
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  renderSaveAsButton(){
    const { showSaveAsDropDown, isSaveState } = this.state;
    const { hideSaveAsButton } = this.props;
    if(isSaveState || hideSaveAsButton){
      return null;
    }
    return(
      <ForgeButton type="outlined">
        <button
          variant="outline-primary"
          className="view-save-btn"
          onClick={() => {
            this.toggleSaveAsDropdown(!showSaveAsDropDown)}
          }
        >
          Save as
        </button>
      </ForgeButton>
    )
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.props.isLoading} />
    )
  }

  render() {
    return(
      <div className="d-flex bookmark-save-options" ref={(ref) => this.saveButtonRef = ref}>
        {this.renderSaveAsButton()}
        {this.renderSaveButtonDropDown()}
        {this.renderRadarSaveDropDown()}
      </div>
    )
  }
}

SaveAndSaveAsButton.defaultProps = {
  hideSaveAsButton: false,
  variant: 'primary'
}

SaveAndSaveAsButton.propTypes = {
  currentBookmark: PropTypes.object,
  bookmarkId: PropTypes.string,
  onEditBookmarkSave: PropTypes.func,
  onBookmarkSave: PropTypes.func,
  isViewChanged: PropTypes.func,
  hideSaveAsDropDown: PropTypes.bool,
  hideSaveAsButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  saveAsTrackMessage: PropTypes.string,
  savedTrackMessage: PropTypes.string,
  variant: PropTypes.string
}

export default SaveAndSaveAsButton;
