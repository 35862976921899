import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  getRadarPageTitle,
  getRadarSubCardHeader,
  showRadarBetaTag
} from 'common/config/customerConfiguration';

const RadarCardHeader = ({ showNewLabel, cardEntry, isRadarBookmark }) => {
  const cardName = isRadarBookmark ? _.get(cardEntry, 'name', getRadarPageTitle()) : getRadarPageTitle();
  const description = getRadarSubCardHeader();
  const metricBadgeClass = showNewLabel ? ' add-new-badge' : '';

  return (
    <div className="metric-head d-flex">
      <img alt="RadarImage" src='../images/change-over-time-spot.svg' className='radar-img' />
      <div className='radar-head'>
        <div className={`metric-title forge-typography--subtitle1-secondary ${metricBadgeClass}`}>
          <div className='metric-title-name'>{cardName} </div>
          {showNewLabel && <span className="badge badge-pill badge-success">New</span>}
          { _.isEmpty(description) && <RenderRadarTooltipInfo/> }
        </div>
        { !_.isEmpty(description) &&
        <div className="metric-category forge-typography--caption">
          <span className="radar-description">
            <RenderDescription />
          </span>
          <RenderRadarTooltipInfo/>
        </div> }
      </div>
    </div>
  );
}

const RenderDescription = () => {

  const description = getRadarSubCardHeader();

  const tooltip = (<Tooltip id="radar-beta-tooltip">{description}</Tooltip>);
  const uniqueKey = 'radar-description-tooltip-key';

  return (
    <OverlayTrigger key={uniqueKey} placement="bottom" overlay={tooltip}>
      <span>{description}</span>
    </OverlayTrigger>
  );
}

const RenderRadarTooltipInfo = () => {
  if(!showRadarBetaTag()){
    return null;
  }

  const message = `Introducing our new multi-metric analysis experience!
  If you have any suggestions for how we can improve it,
  please let us know via the orange Feedback button in the lower right corner.`;

  const tooltip = (<Tooltip id="radar-beta-tooltip">{message}</Tooltip>);
  const uniqueKey = 'radar-beta-tooltip-key';

  return (
    <OverlayTrigger key={uniqueKey} placement="right" overlay={tooltip}>
      <span className="badge badge-beta">Beta</span>
    </OverlayTrigger>
  );
}

RadarCardHeader.defaultProps = {
  showNewLabel: false,
  isRadarBookmark: false,
  cardEntry: {}
}

RadarCardHeader.propTypes = {
  showNewLabel: PropTypes.bool,
  isRadarBookmark: PropTypes.bool,
  cardEntry: PropTypes.object
}

export default RadarCardHeader;
