import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ForecastingInput from './ForecastingInput';
import {
  ForgeButton, ForgeOpenIcon, ForgeIcon, ForgeExpansionPanel, ForgeBadge
} from '@tylertech/forge-react';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import UseLineChartDataFetch from './useLineChartDataFetch';
import InstructionMessage from './InstructionMessage';
import { getFormattedWeekPeriod } from 'helpers/dateHelper';
import { getValidChartValue } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import {
  getValidFormattedData, getForecastFormattedData,
  updatePreviousForecastUnsavedPreparedData,
  isForecastEndDateIsBeforeToday
} from '../ForecastHelper';
import ReRunDialogModal from '../ReRunModal/ReRunDialogModal';
import {
  updateForecastDateRange
} from 'actions/forecastingActions';
import { DATE_FORMAT } from "appConstants";

const ForecastingPrepareData = (props) => {
  const dispatch = useDispatch();
  const {
    lineChartAttributes,
    currentDateRange,
    forecastAttributeOptions,
    futureForecastDateRange,
    toggleAdjustDatesPanel,
    currentBookmark
  } = props;
  forecastAttributeOptions['axisGranularity'] = forecastAttributeOptions['defaultAxisGranularity'];
  lineChartAttributes['axisGranularity'] = forecastAttributeOptions['defaultAxisGranularity'];
  const {
    currentForecastDateRange,
    defaultAxisGranularity,
    selectedForecastMetric,
    currentChartView,
    onUpdateForecastPrepareData,
    axisGranularity
  } = forecastAttributeOptions;
  const [forecastFormattedData, setForecastData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tableFormattedData, setTableFormattedData] = useState(forecastFormattedData);
  const {
    loading, data: chartData
  } = UseLineChartDataFetch(
    lineChartAttributes, currentDateRange, defaultAxisGranularity, currentChartView,
    futureForecastDateRange
  );

  useEffect(() => {
    if (!loading) {
      const formattedData = getForecastFormattedData(chartData, forecastAttributeOptions);
      const updatedTableData = updatePreviousForecastUnsavedPreparedData(formattedData, tableFormattedData);
      setTableFormattedData(_.cloneDeep(updatedTableData));
      setForecastData(formattedData);
    }
  }, [chartData])

  useEffect(() => {
    setTableFormattedData([]);
  },[currentChartView])

  const resetToBookmarkValues = () => {
    const projectionAdjustedValues = _.get(currentBookmark, 'forecastOptions.projectionAdjustedValues');
    const bookmarkForecastAttributeOptions = {
      ...forecastAttributeOptions,
      projectionAdjustedValues
    };
    const formattedData = getForecastFormattedData(chartData, bookmarkForecastAttributeOptions);
    setTableFormattedData(_.cloneDeep(formattedData));
  };

  const renderTableHeader = () => {
    const tableHeaders = ["Date", "Original value", "Adjusted value", "Note"];

    return (_.map(tableHeaders, (headingName, index) => {
      return (
        <th key={index} className='forge-typography--subtitle2'>
          {headingName}
        </th>
      );
    }));
  }

  const handleAdjustAndNoteChange = (option) => {
    const { date, value, isNumber, uniqueId } = option;

    const newTableData = _.map(tableFormattedData, (tableDatum) => {
      if (tableDatum.period == date && tableDatum.uniqueId == uniqueId) {
        let newData = { ...tableDatum };
        const updateField = isNumber ? 'adjustValue' : 'note';
        newData[updateField] = _.toString(value);
        return newData
      } else {
        return tableDatum;
      }
    });

    setTableFormattedData(newTableData);
  }

  const updateForecastPrepareChanges = () => {
    const dateRange = {
      startDate: _.get(currentForecastDateRange, 'dateRange.startDate'),
      endDate: moment().format(DATE_FORMAT)
    }
    dispatch(updateForecastDateRange({ dateRange }));
    setForecastData(tableFormattedData);
    onUpdateForecastPrepareData(tableFormattedData, true);
    setShowModal(false);
  };

  const onApplyClick = () => {
    if ( isForecastEndDateIsBeforeToday(currentForecastDateRange, axisGranularity)){
      setShowModal(true);
    } else {
      setForecastData(tableFormattedData);
      onUpdateForecastPrepareData(tableFormattedData, true);
    }
  }

  const checkApplyChanges = () => {
    return _.isEqual(tableFormattedData, forecastFormattedData);
  }

  const renderTableRows = () => {
    if (_.isEmpty(tableFormattedData) || _.isEmpty(currentForecastDateRange)) {
      return null;
    }
    const { dateRange } = currentForecastDateRange;
    const { startDate } = dateRange;


    return tableFormattedData.map((entry, index) => {

      const filterStartDate = moment(startDate).startOf(defaultAxisGranularity);
      if (moment(entry.period) < filterStartDate || entry.renderType == 'bar') {
        return null;
      }

      let periodText = moment(entry.period).format('ll');
      if (defaultAxisGranularity == 'year') {
        periodText = moment(entry.period).format('yyyy')
      } else if (defaultAxisGranularity == 'week') {
        periodText = getFormattedWeekPeriod(entry.period, dateRange, true);
      } else if (defaultAxisGranularity == 'month') {
        periodText = moment(entry.period).format('MMM yyyy');
      }

      const value = getValidChartValue(entry);
      const adjustValue = Number(_.get(entry, 'adjustValue', 0));
      // const accumulatedValue = Number(_.get(entry, 'accumulateValue', 0));
      const renderWarning = (value <= 0 && adjustValue <= 0 ) ? renderAlertIcon() : null;
      // const calcValue = canAccumulateTotal ? accumulatedValue : value;

      const originalValue = getFormattedNumberValue(value, selectedForecastMetric);

      return (
        <tr className='small' key={index} tabIndex={0}>
          <td>
            <div className='d-flex align-items-center gap-4'>
              {renderWarning} {periodText}
            </div>
          </td>
          <td>{originalValue}</td>
          <td>
            <ForecastingInput
              placeholder="Number only"
              selectedForecastMetric={selectedForecastMetric}
              dateField={entry.period}
              value={entry.adjustValue}
              uniqueId={entry.uniqueId}
              uniqueKey={`${'adjust'}-${entry.period}-${entry.dimension_field}`} isNumber={true}
              onHandleChange={handleAdjustAndNoteChange} />
          </td>
          <td>
            <ForecastingInput
              selectedForecastMetric={selectedForecastMetric}
              dateField={entry.period}
              value={entry.note}
              uniqueId={entry.uniqueId}
              uniqueKey={`${'note'}-${entry.period}-${entry.dimension_field}`} isNumber={false}
              onHandleChange={handleAdjustAndNoteChange} />
          </td>
        </tr>
      );
    });
  }

  const renderApplyButton = () => {

    return (
      <div>
        <ForgeButton type="raised">
          <button type="button" disabled={checkApplyChanges()}
            onClick={() => onApplyClick()} id='apply_prepare_data'> Apply changes</button>
        </ForgeButton>
      </div>
    )
  }

  const renderAlertIcon = () => {
    const styleInfo = {
      color: "#F6BE00"
    }

    return (
      <ForgeIcon className="tx-25" key={'issue-data'}
        style={styleInfo} name={'alert_outline'} />
    )
  }

  const renderIssuesDetected = () => {
    const validDataOptions = {
      formattedData: forecastFormattedData,
      ...forecastAttributeOptions
    }
    const formattedData =  getValidFormattedData(validDataOptions);

    const issueFound = _.some(formattedData, (datum) => {
      const value = getValidChartValue(datum);
      const adjustValue = Number(_.get(datum, 'adjustValue', 0));

      return (value <= 0 && adjustValue <= 0)
    });

    if (!issueFound || _.isEmpty(formattedData)) {
      return null;
    }

    return (
      <ForgeBadge strong={false} theme={'default'}>
        Issue detected
      </ForgeBadge>
    )
  }

  const reRunModal = () => {
    if (showModal) {
      return (
        <ReRunDialogModal
          showModal={showModal}
          setShowModal={setShowModal}
          onModalClick={updateForecastPrepareChanges}
          onModalCancel={resetToBookmarkValues}
        >
        </ReRunDialogModal>
      );
    }
  }

  return (
    <ForgeExpansionPanel on-forge-expansion-panel-toggle={toggleAdjustDatesPanel}>
      <div tabIndex={0} slot="header" className='d-flex align-items-center gap-20'>
        <ForgeOpenIcon />
        <div className='forge-typography--headline5'>Prepare data</div>
        {renderIssuesDetected()}
      </div>

      <div className='m-1'>
        <div className="mt-5">
          <div className='perepare-data-wrapper'>
            <InstructionMessage
              forecastFormattedData={forecastFormattedData}
              forecastAttributeOptions={forecastAttributeOptions}/>
            <div className="details-table">
              <div className="table-responsive">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      {renderTableHeader()}
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {renderApplyButton()}
          {reRunModal()}
        </div>
      </div>
    </ForgeExpansionPanel>

  );
}

ForecastingPrepareData.propTypes = {
  forecastAttributeOptions: PropTypes.object,
  forecastFormattedData: PropTypes.array,
  currentDateRange: PropTypes.object,
  futureForecastDateRange: PropTypes.object,
  lineChartAttributes: PropTypes.object,
  toggleAdjustDatesPanel:PropTypes.func,
  currentBookmark: PropTypes.object
}

export default ForecastingPrepareData;
