import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ForgeIcon, ForgeButton, } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import GlobalEvent from 'common/components/GlobalEvents';

import {
  getWidthAndHeightForFilterBadge,
  isClickOutSideFilter
} from 'common/components/Filters/FilterDomHelper';
import { isMobileOrTablet, isMobileView, isSmallForecastingChartSize } from 'helpers/DomPageHelper';
import ForgeCloseButton from '../ForgeCloseButton/ForgeCloseButton';

const DEFAULT_DROP_DOWN_WIDTH = 350;

class MenuDropDownSelector extends Component {
  constructor(props) {
    super(props)
    this.toggleButtonRef = createRef();

    this.state = {
      showDropDown: this.isLegend(),
      showDropdownPosition: false,
      parentWidth: 0
    };
    this.childRef = createRef();
    this.resizeObserver = null;
    this.updateTimeout = null;
  }

  componentDidMount() {
    this.updateParentWidth();

    this.resizeObserver = new ResizeObserver(() => {
      this.debouncedUpdateParentWidth();
    });

    if (this.childRef.current) {
      this.resizeObserver.observe(this.childRef.current);
    }

    GlobalEvent.on('SHOW_CHART_LEGEND', this.showChartLegend);
    document.addEventListener('mousedown', this.handleClickOutside);
    window['isLegendOpen'] = this.state.showDropDown;
  }

  componentWillUnmount() {
    GlobalEvent.off('SHOW_CHART_LEGEND', this.showChartLegend);
    document.removeEventListener('mousedown', this.handleClickOutside);

    if (this.resizeObserver && this.childRef.current) {
      this.resizeObserver.unobserve(this.childRef.current);
    }
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }

  componentDidUpdate(prevProps) {
    const isDropDownOpenChanged = !_.isEqual(prevProps.isDropDownOpen, this.props.isDropDownOpen)
    if (isDropDownOpenChanged) {
      this.setState({ showDropDown: false }, () => {
        this.onToggleClickLegendButton();
      })
    }
  }

  debouncedUpdateParentWidth() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      this.updateParentWidth();
    }, 100);
  }

  updateParentWidth() {
    const { menuType, isForecastingLegends } = this.props;

    if (menuType === "Legend" && isForecastingLegends && this.childRef.current) {
      this.setState({ parentWidth: this.childRef.current.offsetWidth });
      this.props.onUpdateLegendWidth(this.childRef.current.offsetWidth);
    }
  }

  showChartLegend = (showLegend) => {
    if (this.isLegend() && !window['isLegendOpen']) {
      this.setState({ showDropDown: showLegend }, () => {
        this.onToggleClickLegendButton(false);
      });
    }
  }

  handleClickOutside = (e) => {
    if (isClickOutSideFilter(e, this.dropDownRef) && !this.isLegend()) {
      this.setState({ showDropDown: false }, () => {
        this.onToggleClickLegendButton();
      });
    }
  }
  isLegend = () => {
    const { menuType } = this.props;
    return menuType == "Legend";
  }

  handleKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onDropDownButtonClick();
    }
  }

  legendOpenStatus = (status) => {
    if (!this.isLegend()) {
      return
    }
    window['isLegendOpen'] = status;
  }

  onDropDownButtonClick = () => {
    const boundingClientRect = this.toggleButtonRef.current.getBoundingClientRect();
    let showDropdownPosition = false;

    if (boundingClientRect.x + DEFAULT_DROP_DOWN_WIDTH > window.innerWidth && !isMobileView()) {
      showDropdownPosition = true;
    }

    this.setState({ showDropDown: true, showDropdownPosition }, () => {
      this.onToggleClickLegendButton();
    });
  }

  onDropDownButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onDropDownButtonClick();
    }
  }

  onCloseOption = () => {
    this.setState({ showDropDown: !this.state.showDropDown }, () => {
      this.onToggleClickLegendButton();
    });
  }

  onToggleClickLegendButton = (updateStatus = true) => {
    const { toggleClickLegendButton } = this.props;
    const { showDropDown } = this.state;
    if (this.isLegend() && !_.isUndefined(toggleClickLegendButton)) {
      toggleClickLegendButton(showDropDown);
      if (updateStatus) {
        this.legendOpenStatus(showDropDown);
      }
    }
  }

  renderLabel() {
    const { toggleButtonLabel } = this.props;

    return (
      <div onKeyDown={this.onDropDownButtonKeyDown}
        onClick={this.onDropDownButtonClick}
        className="forge-typography--body2">
        {toggleButtonLabel}
      </div>
    );
  }

  renderButton() {
    const { leadingIconName, trailingIconName, toggleButtonLabel, customIconName } = this.props;
    const { showDropDown } = this.state;

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          disabled={showDropDown}
          className=""
          onKeyDown={this.onDropDownButtonKeyDown}
          onClick={this.onDropDownButtonClick}>
          {leadingIconName && <ForgeIcon name={leadingIconName} />}
          {customIconName && <i className={customIconName}></i>}
          <span className="button-label">{toggleButtonLabel}</span>
          {trailingIconName && <ForgeIcon name={trailingIconName} className="mr-0 arrow-down" />}
        </button>
      </ForgeButton>
    );
  }

  renderDropDownButton() {
    const { showDropDown } = this.state;
    const { isForecastingLegends } = this.props;

    return (
      <div className="filter-toggle-btn" ref={this.toggleButtonRef} onClick={(() => this.onCloseOption())}
      id={(isForecastingLegends && showDropDown) ? 'legend_dismiss' : 'legend_open'}>
        {showDropDown && this.renderLabel()}
        {!showDropDown && this.renderButton()}

        {showDropDown && <ForgeCloseButton />}

      </div>
    )
  }

  render() {
    const { showDropDown, showDropdownPosition, parentWidth } = this.state;
    const { className, children, menuType, isForecastingLegends } = this.props;

    const filterClassNames = classNames(`custom-options-dropdown ${className}`,
      { 'show-filter-chip': showDropDown },
      { 'filter-position': showDropdownPosition },
      { 'legend-open': menuType == "Legend" && showDropDown }
    );

    const viewMobileMode = isForecastingLegends ? isSmallForecastingChartSize() : isMobileOrTablet();
    const mobileViewClass = viewMobileMode ? 'filter-chip legend-position' : 'filter-chip';

    const style = showDropDown && menuType != "Legend" ?
      getWidthAndHeightForFilterBadge(this.dropDownRef) :
      (showDropDown && menuType === "Legend" && !viewMobileMode && isForecastingLegends ?
        { width: parentWidth } : {});

    return (
      <div className={filterClassNames} id="id-legend-data" style={style}
        ref={(refInput) => this.dropDownRef = refInput} >
        <div className={mobileViewClass} tabIndex={0} onKeyDown={this.handleKeyDown} ref={this.childRef}>
          {this.renderDropDownButton()}
          <div className="filter-chip-show">
            {showDropDown && children}
          </div>
        </div>
      </div>
    );
  }
}

MenuDropDownSelector.defaultProps = {
  children: null,
  toggleButtonLabel: 'Options',
  leadingIconName: 'settings',
  customIconName: '',
  trailingIconName: '',
  className: '',
  isDropDownOpen: false,
  menuType: '',
  toggleClickLegendButton: _.noop,
  onUpdateLegendWidth: _.noop,
  isForecastingLegends: false
};

MenuDropDownSelector.propTypes = {
  children: PropTypes.any,
  toggleButtonLabel: PropTypes.string,
  leadingIconName: PropTypes.string,
  trailingIconName: PropTypes.string,
  customIconName: PropTypes.string,
  className: PropTypes.string,
  isDropDownOpen: PropTypes.bool,
  menuType: PropTypes.string,
  toggleClickLegendButton: PropTypes.func,
  isForecastingLegends: PropTypes.bool,
  onUpdateLegendWidth: PropTypes.func
};

export default MenuDropDownSelector;
