import _ from 'lodash';
import $ from 'jquery';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

import { deleteBookmark, acceptBookmark } from 'common/api/bookmarksApi';
import TemplateCardVisualization from 'pages/dashboard/components/TemplateCards/TemplateCardVisualization';
import DeleteConfirmModal from '../DeleteConfirmModal';
import SaveViewSelector from './SaveViewSelector';
import EmbedBookmark from './EmbedBookmark';
import MetricTotal from 'common/components/MetricTotal';
import ComparisonSelector from 'pages/ComparisonSelector';
import { shouldShowEmbedLinkInKabob } from 'common/config/customerConfiguration';
import {
  getCurrentTemplateEntryById,
  getCurrentViewEntry
} from 'common/config/templateConfiguration';
import {
  getAvailableVisualizationType,
  getDefaultVisualizationChartView,
  updateBookMarkCardLoadingStatusForImageGenerator,
} from 'helpers/visualizationHelper';
import { getApiParams, sanitizeCommonFilterParam } from 'helpers/apiParamsHelper';
import {
  isMonitorEnabled,
  isMonitorReached,
  formatDrilldownParams,
  getTransformedCardEntry
} from 'pages/SubscriptionsManager/components/Bookmark/helper';
import {
  VISUALIZATION_TYPES,
  EMAIL_STRATEGY_TYPES,
  VIEW_DELETE_MESSAGE,
  DEFAULT_VIEW_DELETE_MESSAGE,

  MY_VIEW_DELETE_TITLE,
  MY_VIEW_BUTTON_BUTTON,
  COLLECTION_VIEW_DELETE_TITLE,
  COLLECTION_VIEW_DELETE_BUTTON,
  BOOKMARK_COLLECTION

} from 'appConstants';
import { isEnterButtonPressed, isEllipsisActive } from 'helpers/mouseEventsHelper';
import { BookmarkErrorBoundaryWrapper } from './BookmarkErrorBoundaryWrapper';
import AttributionDescription from 'common/components/AttributionDescription';
import BookmarkFilterBadges from './BookmarkFilterBadges';
import BookmarkApiHelper from 'pages/SubscriptionsManager/components/Bookmark/BookmarkApiHelper';
import {
  getCommonFilters
} from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { getDefaultVisualizationView } from 'common/config/visualizationConfiguration';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import TargetValueSelector from 'common/components/TargetValueSelector';
import { getViewEntryQuickFilters } from 'pages/drilldown/components/QuickFilterBar/helper';
import { getCurrentTargetEntry } from 'helpers/templateHelper';
import { viewDeleteMessage, viewDeleteErrorMessage } from 'helpers/toastMessages';
import { isCollectionViewAccess } from '../ManageCollection/collaboratorHelper';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { getCardId } from 'pages/embed/helper';
import { setCurrentCardId, setAnalysisPageCollectionId } from 'actions/dashboardActions';
import { getCollectionId } from 'helpers/collectionIdHelper';
import { removeBodyAttribute } from 'helpers/DomPageHelper';
import NewBadge from 'common/components/NewBadge';

// TODO: Refactoring After notification panel implement.
class BookmarkCard extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.abortFetchController = new AbortController();
    this._bookmarkApiHelper = new BookmarkApiHelper(props.cardEntry);

    this.state = {
      secondaryTotal: null,
      isComparisonSelectorLoaded: false,
      isEmptyData: false,
      currentPeriodTotal: null,
      previousPeriodTotal: null,
      percentage: null,
      currentDimensionTotal: {},
      benchmarkValues: [],
      isLoading: false,
      isTotalLoading: true,
      isBenchMarkLoading: false,
      editModeType: null,
      dimensionWiseData: [],
      currentTargetEntry: null,
      showEmbedConfirmModal: false,
      isEllipsisActive: false,
      isDeleteClicked: false
    };
  }

  componentDidMount() {
    this.fetchBenchMarkValues();
    this.fetchDimensionOptionValues();
    this.setState({ isEllipsisActive: isEllipsisActive(this.metricTitleDiv) });
  }

  componentWillUnmount() {
    this.abortFetchController.abort();
  }

  fetchBenchMarkValues = async () => {
    this.setState({ isBenchMarkLoading: true });
    const { benchmarkValues } = await this._bookmarkApiHelper.fetchBenchMarkValues();
    this.setState({ benchmarkValues, isBenchMarkLoading: false });
  };

  fetchDimensionOptionValues = async () => {
    this.setState({ isLoading: true });
    const { dimensionWiseData } = await this._bookmarkApiHelper.fetchDimensionOptionValues();

    this.setState({ dimensionWiseData, isLoading: false });
  }

  updateCurrentDimensionTotal = (currentDimensionTotal, isLoading, secondaryTotal, chartTotalData) => {
    const { cardEntry, currentDrilldownTemplateId, currentDrilldownViewEntry } = this.props;
    const metricTotalData = _.get(chartTotalData, 'currentPeriodMetricTotals');
    let visualizationType = _.get(
      cardEntry,
      'drilldown.currentVisualizationType',
      VISUALIZATION_TYPES.SNAPSHOT.type
    );
    let availableVizType = getAvailableVisualizationType(
      currentDrilldownTemplateId,
      visualizationType,
      currentDrilldownViewEntry
    );
    const targetEntryOptions = {
      visualizationType: availableVizType,
      chartTotalData: metricTotalData,
      quickFilters: getViewEntryQuickFilters(currentDrilldownTemplateId, currentDrilldownViewEntry),
      isSmallView: true,
      userTargetEntry: _.get(cardEntry, 'target_entry', {})
    };
    const currentTargetEntry = getCurrentTargetEntry(
      currentDimensionTotal,
      currentDrilldownViewEntry,
      targetEntryOptions
    );
    this.setState({
      currentDimensionTotal,
      isTotalLoading: isLoading,
      secondaryTotal,
      currentTargetEntry
    });
  };

  onAcceptBookmarkClick = (cardEntry) => {
    acceptBookmark(cardEntry.id)
      .then(() => {
        this.props.reloadBookmarks();
      })
      .catch((error) => {
        console.log('Error on accept bookmark', error);
      });
  };

  handleEmbedClick = (enableState) => {
    this.setState({ showEmbedConfirmModal: enableState })
  }

  handleCardDelete = () => {
    const { onCardDelete, cardEntry } = this.props;
    const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
    removeBodyAttribute("forge-dialog-open");
    if (isBookmarkCard) {
      onCardDelete(cardEntry);
    } else {
      deleteBookmark(cardEntry.id)
        .then((response) => {
          if (response.ok) {
            this.props.reloadBookmarks();
            toast.success(viewDeleteMessage);
          } else {
            toast.error(viewDeleteErrorMessage);
          }
        })
        .catch((error) => {
          console.log('Error on deleting saved view', error);
          toast.error(viewDeleteErrorMessage);
        });
    }
  };

  handleCardClick = (cardEntry, e) => {
    const isClickWithinDropdown = $(e.target).closest('.info-sections').length > 0;
    const isClickOutsideDeleteModal = $(e.target).closest('.modal').length > 0;
    const isClickWithinMenuModal = $(e.target).closest('.forge-menu__popup').length > 0;

    if (isClickWithinDropdown || isClickOutsideDeleteModal || isClickWithinMenuModal) {
      return;
    }

    const { dimensionWiseData, benchmarkValues } = this.state;
    const {
      collectionEntry,
      dateFilters,
      dispatchCurrentCardId,
      userCollectionsWithCards,
      dispatchAnalysisPageCollectionId
    } = this.props;
    const isSharedAccepted = _.get(cardEntry, 'is_shared_accepted', true);
    const commonFilters = getCommonFilters(cardEntry, dateFilters);
    if (!isSharedAccepted) {
      this.onAcceptBookmarkClick(cardEntry);
    }
    const cardId = getCardId(cardEntry?.templateEntry, cardEntry?.viewEntry);
    const collectionId = getCollectionId(userCollectionsWithCards, cardId);
    dispatchCurrentCardId(cardId);
    dispatchAnalysisPageCollectionId(collectionId);
    const isMyViewPageCard = _.get(collectionEntry, 'name') === BOOKMARK_COLLECTION.name;
    const currentCollectionId = _.get(collectionEntry, 'id');
    const newCardEntry = getTransformedCardEntry(cardEntry, dimensionWiseData, benchmarkValues);

    this.props.onCardClick(
      {...newCardEntry, isMyViewPageCard, currentCollectionId },
      e,
      commonFilters,
      { isMyViewPageCard, currentCollectionId }
    );
  };

  handleEditClick = (event, type) => {
    event.stopPropagation();
    this.handleClickMenuItems()
    this.setState({ editModeType: type });
  };

  handleKeyDown = (e, cardEntry) => {
    if (isEnterButtonPressed(e)) {
      this.handleCardClick(cardEntry, e);
    }
  };

  handleClickMenuItems = () => {
    document.body.click();
  }

  handleEditBookmarkClose = (isReloadBookmark) => {
    this.setState({ editModeType: null }, () => {
      if (isReloadBookmark) {
        this.props.reloadBookmarks();
      }
    });
  };

  handleKeyDownEdit = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleEditClick(e);
    }
  };

  handleAcceptClick = (event) => {
    const { cardEntry } = this.props;
    event.stopPropagation();
    this.onAcceptBookmarkClick(cardEntry);
  };


  renderShareInfo() {
    const { cardEntry } = this.props;
    if (_.get(cardEntry, 'is_shared_accepted', true)) {
      return null;
    }
    const email = _.get(cardEntry, 'shared_user.email');
    const name = _.get(cardEntry, 'shared_user.name');
    const sharer = _.isEmpty(name) ? email : name;

    return (
      <div className="mb-2">
        <div className="bookmarks-share-info">
          <i className="icons-star-in-circle text-success" />
          <span>
            <em>{sharer} shared this view with you.</em>
          </span>
        </div>
        <button
          onClick={(e) => this.handleAcceptClick(e)}
          className="btn btn-sm text-primary align-baseline ml-1 p-0 ls-normal"
        >
          <u>Accept</u>
        </button>
      </div>
    );
  }

  renderMonitorIcon() {
    const { cardEntry } = this.props;
    const { currentDimensionTotal, benchmarkValues, dimensionWiseData } = this.state;
    let iconHtml = null;
    const monitorReached = isMonitorReached({
      currentDimensionTotal,
      benchmarkValues,
      dimensionWiseData,
      bookmark: cardEntry
    });
    const isSharedAccepted = _.get(cardEntry, 'is_shared_accepted', true);
    if (!isMonitorEnabled(cardEntry) || !isSharedAccepted) {
      return null;
    }

    if (monitorReached) {
      iconHtml = (
        <div className="monitor-icon">
          <i className="icons-alert-true text-danger"></i>
        </div>
      );
    } else {
      iconHtml = (
        <div className="monitor-icon">
          <i className="icons-alert-set"></i>
        </div>
      );
    }
    return iconHtml;
  }

  onDataLoading = (isLoading) => {
    updateBookMarkCardLoadingStatusForImageGenerator(isLoading);
  };

  renderVisualization() {
    const { cardEntry, currentDrilldownViewEntry, isEmbed, dateFilters } = this.props;
    const { currentDimensionTotal, benchmarkValues, dimensionWiseData, isTotalLoading } = this.state;
    const { bookmarkOptions, visualization } = cardEntry;
    const filterUpdatedCardEntry = getTransformedCardEntry(
      cardEntry, dimensionWiseData, benchmarkValues
    );
    const { drilldown } = filterUpdatedCardEntry;
    const { mapOptions } = visualization;
    let currentVizChartType = _.get(bookmarkOptions, 'currentVizChartType');
    let commonFilters = getCommonFilters(cardEntry, dateFilters);

    const { currentDrilldownTemplateId } = drilldown;
    let visualizationType = _.get(
      cardEntry,
      'drilldown.currentVisualizationType',
      VISUALIZATION_TYPES.SNAPSHOT.type
    );
    const templateEntry = getCurrentTemplateEntryById(
      currentDrilldownTemplateId
    );
    const visualizationChartType = _.get(
      currentDrilldownViewEntry,
      'visualization.' + visualizationType
    );
    if (
      _.get(visualizationChartType, 'hide_this_visualization', 'false') ===
      'true'
    ) {
      visualizationType = _.get(
        currentDrilldownViewEntry,
        'visualization.default_view'
      );
      const chatViewType = _.get(
        currentDrilldownViewEntry,
        'visualization.' + visualizationType
      );
      currentVizChartType = _.get(
        chatViewType,
        'visualization.' + visualizationType + '.default_view'
      );
    }
    let availableVizType = getAvailableVisualizationType(
      currentDrilldownTemplateId,
      visualizationType,
      currentDrilldownViewEntry
    );

    const drilldownOptions = {
      drilldown,
      currentDrilldownViewEntry
    }

    return (
      <TemplateCardVisualization
        currentVizChartType={currentVizChartType}
        onDataLoading={this.onDataLoading}
        cardEntry={templateEntry}
        mapOptions={mapOptions}
        commonFilters={commonFilters}
        visualizationType={availableVizType}
        drilldownEntry={formatDrilldownParams(drilldownOptions)}
        currentDimensionTotal={currentDimensionTotal}
        viewEntry={currentDrilldownViewEntry}
        isBookmarkCard={true}
        isEmbed={isEmbed}
        visualizationEntry={visualization}
        isChartAndTotalLoading={isTotalLoading}
        showChartValues={_.get(visualization, ['snapshot', 'showSnapshotChartValues'], false)}
      />
    );
  }

  renderBookmarkDescription() {
    const { cardEntry } = this.props;
    const description = _.get(cardEntry, 'bookmarkOptions.description', '');
    if (_.isEmpty(description)) {
      return null;
    }

    return (
      <div className="metric-footer forge-typography--caption">
        <div className="text-truncate line-clamp">{description}</div>
      </div>
    );
  }

  renderEmbedModel() {
    const { showEmbedConfirmModal } = this.state;
    const { cardEntry } = this.props;
    if (!showEmbedConfirmModal) {
      return null
    }
    return (
      <EmbedBookmark
        bookmark={cardEntry}
        onModalClose={this.handleEmbedClick} />
    )
  }

  renderSaveView = () => {
    const saveViewSelectorAttributes = _.pick(
      this.props,
      'cardEntry', 'currentDrilldownViewEntry', 'isEmbed', 'analysisLink'
    );

    return (<SaveViewSelector {...saveViewSelectorAttributes} />)
  }

  renderComparisonContent = () => {
    const { cardEntry, currentDrilldownViewEntry, dateFilters, templateEntry } = this.props;
    let commonFilters = getCommonFilters(cardEntry, dateFilters);
    const templateId = _.get(cardEntry, 'drilldown.currentDrilldownTemplateId');
    const defaultView = getDefaultVisualizationView(currentDrilldownViewEntry);
    const availableVizType = getAvailableVisualizationType(
      templateId,
      defaultView,
      currentDrilldownViewEntry
    );
    const mapOptions = _.get(cardEntry, 'visualization.mapOptions');
    return (
      <ComparisonSelector
        cardEntry={templateEntry}
        drilldown={_.get(cardEntry, 'drilldown')}
        viewEntry={currentDrilldownViewEntry}
        commonFilters={commonFilters}
        mapOptions={mapOptions}
        updateCurrentDimensionTotal={this.updateCurrentDimensionTotal}
        visualizationType={availableVizType}
      />
    )
  }

  renderTargetEntry() {
    const { currentTargetEntry, secondaryTotal } = this.state;
    const { cardEntry, currentUser, currentDrilldownViewEntry } = this.props;
    if (_.get(currentDrilldownViewEntry, 'always_apply_target', 'false') === 'false') {
      return null;
    }

    return (
      <TargetValueSelector
        templateId={_.get(cardEntry, 'drilldown.currentDrilldownTemplateId')}
        currentUser={currentUser}
        viewEntry={currentDrilldownViewEntry}
        currentTargetEntry={currentTargetEntry}
        secondaryTotal={secondaryTotal}
        userTargetEntry={_.get(cardEntry, 'target_entry', {})}
        isSmallView />
    );
  }

  renderTargetValue() {
    const { cardEntry, currentDrilldownViewEntry, dateFilters } = this.props;
    const { dimensionWiseData, benchmarkValues, isLoading } = this.state;
    const showAsDuration = isTimeDurationEnabled(currentDrilldownViewEntry);
    let commonFilters = sanitizeCommonFilterParam(getCommonFilters(cardEntry, dateFilters));
    const cardEntryWithDimensionData = getTransformedCardEntry(
      cardEntry, dimensionWiseData, benchmarkValues);

    const apiParams = getApiParams(cardEntryWithDimensionData, {});
    const queryParams = {
      ...apiParams,
      commonFilters: JSON.stringify(commonFilters),
    };
    const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);

    if (isLoading) {
      return null;
    }

    return (
      <div className="status d-flex flex-wrap">
        {this.renderTargetEntry()}
        <div className="target">
          {isNoneDateSelected ?
            this.renderComparisonContent() :
            <div className="target-value forge-typography--headline4">
              {(
                <MetricTotal
                  queryParams={queryParams}
                  viewEntry={currentDrilldownViewEntry}
                  showAsDuration={showAsDuration}
                  updateCurrentDimensionTotal={this.updateCurrentDimensionTotal}
                  shouldShowTemplateTotal={true}
                />
              )}
            </div>
          }
        </div>
      </div>
    );
  }

  handleMenuSelect = ({ detail }) => {
    const { cardEntry, dispatchUpdateCurrentBookmark } = this.props;
    const { value } = detail;

    if (_.isEqual(value, 'delete')) {
      this.setState({ isDeleteClicked: true });
    } else if (_.isEqual(value, 'edit')) {
      const { id, name } = cardEntry;
      const bookmarkType = getBookmarkType(cardEntry);
      dispatchUpdateCurrentBookmark(id + '', name, bookmarkType);
      window.location.href = "/#!/app/subscriptions";
    } else {
      this.handleEmbedClick(true);
    }
  }

  renderMenus() {
    const { currentUser, collectionEntry, cardEntry } = this.props;
    const showEmbedLinkInKabob = shouldShowEmbedLinkInKabob();
    const isViewCollection = isCollectionViewAccess(collectionEntry);
    const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);

    let options = [
      { value: 'edit', label: 'Edit' },
      { value: 'delete', label: isBookmarkCard ? 'Remove tile' : 'Delete' },
      // { value: 'delete', label: 'Delete Card' },
      { value: 'embed', label: 'Embed' }
    ];

    if (_.isEmpty(currentUser) || isViewCollection) {
      options = _.reject(options, { 'value': 'edit' });
    }

    if (!showEmbedLinkInKabob) {
      options = _.reject(options, { 'value': 'embed' });
    }

    return (showEmbedLinkInKabob || !isViewCollection) && (
      <ForgeMenu
        placement='bottom-start'
        options={options}
        on-forge-menu-select={this.handleMenuSelect}>
        <ForgeIconButton type="button">
          <button type="button">
            <ForgeIcon name='more_vert' className="more-icon" /></button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  }

  renderNewLabel = () => {
    const { cardEntry } = this.props;
    const isNewBookmarkCard = _.get(cardEntry, 'is_new', false);
    const isNewCollectionCard = _.get(cardEntry, 'cardEntry.is_new', false);

    return (isNewBookmarkCard || isNewCollectionCard) && <NewBadge />;
  }

  renderCardHead() {
    const { isEllipsisActive } = this.state;
    const { cardEntry, isEmbed } = this.props;
    const monitorHeaderClassNames = classNames("card-head", {
      "monitor-header": !_.isNull(this.renderMonitorIcon())
    });
    // TODO remove this code.
    let cardViewType = "Subscription";
    const { email_strategy } = _.cloneDeep(cardEntry);
    if (email_strategy == EMAIL_STRATEGY_TYPES.NEVER) {
      cardViewType = "View"
    }

    return (
      <div className={monitorHeaderClassNames}>
        <div className="metric-head-wrapper">
          {this.renderMonitorIcon()}
          <div className="metric-head">
            {isEmbed ? null : this.renderShareInfo()}
            <div className="d-inline-flex gap-5">
              <div className="metric-title forge-typography--subtitle1-secondary line-clamp text-truncate"
                ref={ref => (this.metricTitleDiv = ref)}>
                {cardEntry.name}
              </div>
              <div>
                {this.renderNewLabel()}
              </div>
            </div>
            {isEllipsisActive && <forge-tooltip
              position="top"
              target=".metric-title"
              text={cardEntry.name}
              delay={500} />}
            <div className="metric-category forge-typography--caption text-truncate">{cardViewType}</div>
            <BookmarkFilterBadges {...this.props} isBookmarkCardType={true} />
          </div>
          <div className="info-sections ml-1">
            {isEmbed ? null : this.renderMenus()}
          </div>
        </div>
      </div>
    );
  }

  getbookMarkCardId = (cardEntry, viewEntry) => {
    const cardId = _.get(cardEntry, 'id', '');
    const viewId = _.get(viewEntry, 'view_id', '');
    return `${cardId}${viewId}`;
  }

  render() {
    const { isDeleteClicked } = this.state;
    const { cardEntry, currentDrilldownViewEntry, isEmbed } = this.props;
    const bookmarkCardClassNames = classNames("card metric-card bookmark-card", {
      "embed-card": isEmbed
    });
    // TODO remove this code after notification implementation done.
    const isNeverEmailStrategy = _.get(cardEntry, 'email_strategy') === EMAIL_STRATEGY_TYPES.NEVER;

    const cardId = `chart-${this.getbookMarkCardId(cardEntry, currentDrilldownViewEntry)}`;

    if (_.isEmpty(currentDrilldownViewEntry)) {
      throw new Error(`Metric entry missing ${cardEntry.name}`);
    }

    const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
    const deleteMessage = isBookmarkCard ?
      DEFAULT_VIEW_DELETE_MESSAGE :
      VIEW_DELETE_MESSAGE;

    const buttonLabel = isBookmarkCard ? COLLECTION_VIEW_DELETE_BUTTON : MY_VIEW_BUTTON_BUTTON;
    const modalTitle = isBookmarkCard ? COLLECTION_VIEW_DELETE_TITLE : MY_VIEW_DELETE_TITLE;
    return (
      <>
        <div
          role="listitem"
          aria-label={cardEntry.name}
          className={bookmarkCardClassNames}
          tabIndex={0}
          onClick={(e) => this.handleCardClick(cardEntry, e)}
          onKeyDown={(e) => this.handleKeyDown(e, cardEntry)}
        >
          <div className="card-body d-flex flex-column forge-popup-host">
            {this.renderCardHead()}
            <div className="mt-auto">
              {this.renderTargetValue()}
              <div
                id={cardId}
                className="metric-card-visualization js-plotly-plot"
              >
                {this.renderVisualization()}
              </div>
              {this.renderBookmarkDescription()}
            </div>
            <AttributionDescription templateId={_.get(cardEntry, 'drilldown.currentDrilldownTemplateId')} />
            {isNeverEmailStrategy && this.renderSaveView()}
          </div>
        </div>
        {this.renderEmbedModel()}
        {isDeleteClicked &&
          <DeleteConfirmModal
            title={modalTitle}
            message={deleteMessage}
            showNormalButton={true}
            showButtons={false}
            confirmButtonName={buttonLabel}
            onCancelConfirm={() => this.setState({ isDeleteClicked: false })}
            showMenuItems={this.handleClickMenuItems}
            onDeleteConfirm={this.handleCardDelete}
          />
        }
      </>
    );
  }
}

export const getContainerClassNames = ({ cardEntry }) => {
  const { currentDrilldownViewEntry, currentDrilldownTemplateId } = _.get(
    cardEntry,
    'drilldown',
    {}
  );
  const viewId = _.get(currentDrilldownViewEntry, 'view_id');
  const viewEntry = getCurrentViewEntry(currentDrilldownTemplateId, viewId);

  let defaultView = _.get(cardEntry, 'drilldown.currentVisualizationType');
  let visualizationType = _.get(
    cardEntry,
    'drilldown.currentVisualizationType',
    VISUALIZATION_TYPES.SNAPSHOT.type
  );
  let availableVizType = getAvailableVisualizationType(
    currentDrilldownTemplateId,
    visualizationType,
    viewEntry
  );

  if (!_.isEqual(availableVizType, defaultView)) {
    defaultView = getDefaultVisualizationChartView(
      viewEntry,
      visualizationType
    );
  }

  return classNames({
    'bookmark-tile': defaultView !== VISUALIZATION_TYPES.TABLE.type && !cardEntry['is_custom_advance_search'],
    'metric-data-table':
      defaultView === VISUALIZATION_TYPES.TABLE.type || cardEntry['is_custom_advance_search'],
  });
};

BookmarkCard.propTypes = {
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  cardEntry: PropTypes.object,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentUser: PropTypes.object,
  dateFilters: PropTypes.object,
  templateEntry: PropTypes.object,
  onCardClick: PropTypes.func,
  onCardDelete: PropTypes.func,
  onAcceptBookmarkClick: PropTypes.func,
  reloadBookmarks: PropTypes.func,
  bookmarkOptions: PropTypes.object,
  isEmbed: PropTypes.bool,
  analysisLink: PropTypes.string,
  collectionEntry: PropTypes.object,
  dispatchUpdateCurrentBookmark: PropTypes.func,
  dispatchCurrentCardId: PropTypes.func,
  userCollectionsWithCards: PropTypes.array,
  dispatchAnalysisPageCollectionId: PropTypes.func,
};

BookmarkCard.defaultProps = {
  templateEntry: {},
  cardEntries: [],
  dateFilters: {}
}

const mapDispatchToProps = {
  dispatchUpdateCurrentBookmark: updateCurrentBookmark,
  dispatchCurrentCardId: setCurrentCardId,
  dispatchAnalysisPageCollectionId: setAnalysisPageCollectionId,
};

function mapStateToProps(state) {
  return {
    userCollectionsWithCards: _.get(state, 'userCollections.userCollectionsWithCards', []),
  };
}

export default BookmarkErrorBoundaryWrapper(connect(mapStateToProps, mapDispatchToProps)(BookmarkCard));
