import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUserDetailsUrl } from 'common/api/commonApi';
import { fetchApiData } from 'helpers/apiResponseHelper';
import AlertDialogue from 'modules/Administration/AlertDialogue';
import { isPrivate  } from 'common/config/customerConfiguration';

class UserCredentialCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal:false
    };
    this.userAbortFetchController = new AbortController();
  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
  }

  componentWillMount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = async() => {
    const { currentUser, collectionId } = this.props;
    const currentEmail = _.get(currentUser, 'user.email', '');
    if(!isPrivate() && _.isEmpty(currentEmail)){
      return;
    }
    this.userAbortFetchController.abort();
    this.userAbortFetchController = new AbortController();
    const response = await fetchApiData(getUserDetailsUrl(collectionId), this.userAbortFetchController);
    const sessionEmail = _.get(response, 'user.email');
    if(currentEmail != sessionEmail){
      this.setState({showConfirmModal : true})
    }
  }

  handleUserCredentialChange = () => {
    window.location.reload();
  }


  render() {
    const { showConfirmModal } = this.state;

    if(!showConfirmModal){
      return null
    }

    const message =`User credentials have changed.`;
    return (<AlertDialogue
      message={message}
      buttonText="Continue"
      onConfirmationCallBack = {this.handleUserCredentialChange} />)
  }
}

UserCredentialCheck.propTypes = {
  currentUser: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  collectionId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default UserCredentialCheck;