import React from 'react';
import PropTypes from 'prop-types';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeCheckbox } from '@tylertech/forge-react';

const CheckBox = (props) => {
  const { name, isChecked, index, id, onChange, isDisabled } = props;

  const handleKeyDown = (e, index, isChecked) => {
    if (isEnterButtonPressed(e)) {
      onChange(!isChecked, index);
    }
  }

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e, index, isChecked)}
      key={index}>
      <ForgeCheckbox>
        <input
          type="checkbox"
          disabled={isDisabled}
          checked={isChecked}
          id={id}
          tabIndex={-1}
          onChange={(event) => onChange(event.target.checked, index)} />
        <label className="forge-typography--body1" htmlFor={id}>{name}</label>
      </ForgeCheckbox>
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func
}

CheckBox.defaultProps = {
  id: 'checkbox-id',
  index: 0,
  isChecked: false,
  isDisabled: false,
  name: 'checkBox',
  onChange: _.noop
}

export default CheckBox;
