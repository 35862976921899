import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  getCustomerDomainId,
  enableCollectionLevelDateFilters,
  getConfiguredDataEndDate
} from 'common/config/customerConfiguration';
import {
  getCurrentTemplateEntry,
  getGroupByEntries,
  isDateColumnConfigured
} from 'common/config/templateConfiguration';
import {
  EMAIL_STRATEGY_TYPES,
  EMAIL_STRATEGY_OPTIONS,
  DEFAULT_BOOKMARK_DIMENSION_OPTION,
  DATE_FORMAT,
  VISUALIZATION_TYPES,
  BOOKMARK_TYPES,
  RELATIVE_DATE_TYPE,
  DATE_COMPARISON_TYPE,
  SEARCH_BAR_VIEW,
  COLLECTION_ID,
  BOOKMARK_COLLECTION
} from 'appConstants';

import {
  getRelativeFilterDateRange,
  getDefaultDateMode,
  getLastPeriodDateRange
} from 'helpers/dateHelper';
import { getDateRangeBasedOnMonitor } from 'pages/SubscriptionsManager/components/Bookmark/helper';
import {
  getAdditionalDateFiltersConfigs,
  getTemplateCardCommonFilters, isDateIgnored, isIndependentDateFiltersEnabled,
} from 'pages/dashboard/components/Collections/collectionHelper';
import { isEmptyValue } from 'helpers/numberHelper';
import {
  getFormattedNotificationText
} from 'pages/dashboard/components/Collections/Subscription/subscriptionHelper';
import { sanitizeCommonFilterParam } from 'helpers/apiParamsHelper';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
const moment = extendMoment(Moment);

export const getBookmarkDefaultParams = (props, options) => {
    const { drilldown, visualization, commonFilters, currentVizBasedChartType } = props;

    return {
      domain_name: getCustomerDomainId(),
      is_new: _.get(options, 'is_new', false),
      name: _.get(options, 'name', ''),
      send_alerts: _.get(options, 'send_alerts', false),
      email_strategy: _.get(options, 'email_strategy', EMAIL_STRATEGY_OPTIONS[0].type) ,
      email_options: _.get(options, 'email_options', {}),
      drilldown: (drilldown || {}),
      commonFilters: (commonFilters || {}),
      visualization: (visualization || {}),
      bookmarkOptions: {
        currentDimensionTotal: _.get(options, 'currentDimensionTotal'),
        currentVizChartType: currentVizBasedChartType,
        monitorConditions: [],
        description: _.get(options, 'description', ''),
        isRelativeDateSelected: false,
        isNoneDateSelected: _.get(options, 'hideGlobalDateRange', false),
        isNoneAdditionalDateFilters: _.get(options, 'hideAdditionalDateRange', false),
        dimensionOption: DEFAULT_BOOKMARK_DIMENSION_OPTION
      },
      is_radar: false,
      radarOptions: {},
      is_forecast: false,
      forecastOptions: {},
      include_image: true
    };
  }

export const getAdditionalDateRangeFilters = ({ drilldown }) => {
  const { currentDrilldownTemplateId, currentDrilldownViewEntry } = drilldown;
  const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
  if (!enableCollectionLevelDateFilters() || !isDateColumnConfigured(templateEntry)) {
    return null;
  }

  const cards = [{ templateEntry, viewEntry: currentDrilldownViewEntry }];
  const isDateFilterEnabled = !isDateIgnored(currentDrilldownViewEntry);
  const isIndependentFilters = (isIndependentDateFiltersEnabled(currentDrilldownViewEntry) ||
    isIndependentDateFiltersEnabled(templateEntry)) && isDateFilterEnabled
  const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cards);
  if (_.isEmpty(additionalDateFilterConfigs) && !isDateFilterEnabled && !isIndependentFilters) {
    return null;
  }

  return additionalDateFilterConfigs;
}

export const isViewChanged = (props, currentBookmark, isCheckDateRangeOnly = false) => {
  const {
    drilldown,
    visualization,
    currentVizBasedChartType,
    currentDrilldownViewEntry,
    commonFilters,
    recipients
  } = props;
  const drilldownKeys = [
    'currentDrilldownDimensionField',  'quickFilters',
    'currentVisualizationType', 'drilledDownDimensions'
  ];
  let currentVisualizationType = _.get(drilldown, 'currentVisualizationType');
  currentVisualizationType = (currentVisualizationType == 'map' ? 'mapOptions' : currentVisualizationType);
  const currentDrilldownTemplateId = _.get(drilldown, 'currentDrilldownTemplateId');
  const groupByEntries = getGroupByEntries(currentDrilldownTemplateId);
  const bookmarkTemplateId = _.get(currentBookmark, 'drilldown.currentDrilldownTemplateId');
  const isDistributionChartType = currentVisualizationType === VISUALIZATION_TYPES.DISTRIBUTION.type;

  const bookmarkMapOptions = _.get(currentBookmark, 'visualization.mapOptions', {});
  const shapeGroupId = _.get(visualization, 'mapOptions.shape.datasetEntry.shape_dataset_id');
  const shapeIds = _.get(visualization, 'mapOptions.shape.selectedShapeIds', []);
  const selectedOutlines = _.get(visualization, 'mapOptions.shape.selectedOutlines', []);

  const currentDrilldown = _.pick(drilldown, drilldownKeys);
  const bookmarkDrilldown = _.pick(_.get(currentBookmark, 'drilldown', {}), drilldownKeys);
  const groupByEntryKeys = ['column', 'field', 'renderType'];
  const bookmarkGlobalFilters = _.get(currentBookmark, 'commonFilters.globalFilters', []);

  const isTemplateChanged  = (`${currentDrilldownTemplateId}` != `${bookmarkTemplateId}`);

  const isViewEntryChanged  = !_.isEqual(`${currentDrilldownViewEntry.view_id}`,
    `${_.get(currentBookmark, 'drilldown.currentDrilldownViewEntry.view_id')}`);
  const isCurrentVisualizationTypeChanged = isDistributionChartType ?
    false :
    !_.isEqual(currentVizBasedChartType, _.get(currentBookmark, 'bookmarkOptions.currentVizChartType'));
  const isDrilldownChanged = !_.isEqual(currentDrilldown, bookmarkDrilldown);

  let isDateChanged = !_.isEqual(commonFilters.dateRange, getBookmarkDateRange(currentBookmark));
  let isDateTypeChanged = false;
  if(isCheckDateRangeOnly){
    isDateChanged = !_.isEqual(commonFilters.dateRange, _.get(currentBookmark, 'commonFilters.dateRange'));
    isDateTypeChanged = !_.isEqual(commonFilters.dateType, _.get(currentBookmark, 'commonFilters.dateType'));
  }
  const isGlobalFiltersChanged = !_.isEqual(commonFilters.globalFilters, bookmarkGlobalFilters);

  const isGroupEntryChanged = _.isEmpty(groupByEntries) ? false : (
    !_.isEqual(_.pick(_.get(drilldown,'currentDrilldownGroupByEntry', {}), groupByEntryKeys),
      _.pick(_.get(currentBookmark,'drilldown.currentDrilldownGroupByEntry', {}), groupByEntryKeys))
  );

  const shapeFilterChanged = (
    !_.isEqual(shapeIds, _.get(bookmarkMapOptions, 'shape.selectedShapeIds', [])) ||
    !_.isEqual(shapeGroupId, _.get(bookmarkMapOptions, 'shape.datasetEntry.shape_dataset_id'))
  );

  let isVisualizationOptionChanged = false;
  const visualizationKeys = getVisualizationKeys(currentVisualizationType);
  const currentVisualizationOptions = _.get(visualization, currentVisualizationType, {});
  const currentBookamarkVisualizationOptions = _.get(currentBookmark,
    'visualization.'+ currentVisualizationType, {});

  isVisualizationOptionChanged =(
    !_.isEqual(_.pick(currentVisualizationOptions, visualizationKeys),
      _.pick(currentBookamarkVisualizationOptions, visualizationKeys))
  );

  let isDimensionConfigChanged = false;
  if(currentVisualizationType == VISUALIZATION_TYPES.type){
    isDimensionConfigChanged = (
      !_.isEqual(_.compact(_.get(currentVisualizationOptions, "dimensionConfigsByRenderType", [])),
        _.compact(_.get(currentBookamarkVisualizationOptions, "dimensionConfigsByRenderType", [])))
    );
  }

  if(currentVisualizationType == 'mapOptions'){
    const isOutlinecahnged = (
      !_.isEqual(_.compact(selectedOutlines),
      _.compact(_.get(bookmarkMapOptions, 'selectedOutlines', [])))
    );
    isVisualizationOptionChanged = (isOutlinecahnged || isVisualizationOptionChanged);
  }

  const isRecipientsChanged  = !_.isEqual(`${_.get(recipients, 'recipients')}`,
    `${_.get(currentBookmark, 'recipients.recipients')}`);

  return (
    isDrilldownChanged || isDateChanged || isGlobalFiltersChanged || isViewEntryChanged ||
    isGroupEntryChanged || isCurrentVisualizationTypeChanged || shapeFilterChanged ||
    isVisualizationOptionChanged || isDimensionConfigChanged || isTemplateChanged || isDateTypeChanged ||
    isRecipientsChanged
  );
}

export const isChangeDateRange = (props, currentBookmark) => {
  const { commonFilters } = props;
  return !_.isEqual(commonFilters.dateRange, getBookmarkDateRange(currentBookmark));
}

export const getNotifyText = (bookmark) => {
  const { email_options, email_strategy } = bookmark;
  const {
    emailSendingTime,
    timeZone,
    emailSendingDays,
    frequencyInterval,
    frequency,
    monthlyWeekNumber,
    startDate,
    regularityType,
    timePeriodDay
  } = email_options
  const options = {
    email_sending_time: emailSendingTime,
    time_zone: timeZone,
    email_sending_days: emailSendingDays,
    frequency_interval: frequencyInterval,
    frequency,
    week_number_for_month: monthlyWeekNumber,
    start_date: startDate,
    regularity_type: regularityType,
    time_period_day: timePeriodDay
  }

  let successMessage = '';

  if (email_strategy == EMAIL_STRATEGY_OPTIONS[1].type) {
    successMessage =  getFormattedNotificationText(options);
  }else {
    successMessage = 'You’ll receive email updates based on the condition set.'
  }
  return successMessage
}

const getVisualizationKeys = (viz) => {
  const visKeys = {
    snapshot: [
      'currentBarChartSortType', 'currentBenchMarkMetricNames', 'currentDimensionSortType',
      'currentGroupByChartApproach', 'currentGroupBySortType', 'currentGroupByViewType',
      'currentSecondaryMetricField', 'showScatterPlotRange', 'showSnapshotChartValues',
      'pieChartAnnotationOptions'
    ],
    mapOptions: [
      'comparison', 'currentMapStyleEntry',
      'currentMapView', 'geoCoder','isDrawingEnabled'],
    overtime: [
      'axisGranularity', 'compareYearRanges', 'currentBenchMarkMetricNames', 'currentChartView',
      'currentForecastOption', 'currentSecondaryMetricField', 'currentSelectedTimeFrame',
      'isShowProjection'
    ],
    distribution: [ 'currentBenchMarkMetricNames', 'isCumulativeDistribution' , 'isDiscrete'],
    table: [
      'isLeafPage', 'isTableView', 'leafPageCurrentRecordIndex',
      'showTableViewOnDrilldown', 'sortColumns'
    ]
  }
  return (visKeys[viz] || []);
}

export const getBookmarkDateRange = (bookmark) => {
  const { commonFilters, bookmarkOptions } = bookmark;
  let { relativeDateFilterEntry, dateRange, dateType } = _.cloneDeep(commonFilters);

  const isRelativeDateTypeSelected =
    _.get(relativeDateFilterEntry, 'isRelativeFilter', 'false') == 'true';

  if (_.isEmpty(dateType)) {
    dateType = isRelativeDateTypeSelected
      ? 'relative'
      : getDefaultDateMode();
  }
  // Updating date range to current year end date if monitor is enabled
  if (dateType == 'relative') {
    dateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
  } else if (_.get(bookmarkOptions, 'isRelativeDateSelected', false)) {
    dateRange = {
      ...dateRange,
      endDate: getConfiguredDataEndDate().format(DATE_FORMAT)
    }
  }
  return dateRange;
}

export const getCommonFilters = (bookmark, dateFilters) => {
  const commonFilters = _.get(bookmark, 'commonFilters', {});
  const templateEntry = _.get(bookmark, 'templateEntry', {});
  const isNoneDateSelected = _.get(bookmark, 'bookmarkOptions.isNoneDateSelected', false);
  const isNoneAdditionalDateFilters = _.get(bookmark, 'bookmarkOptions.isNoneAdditionalDateFilters', false);
  const isBookmarkCard = _.get(bookmark, 'isBookmarkCard', false);
  const {
    dateType, comparisonModeOn, comparisonType, comparisonDateRanges, relativeDateFilterEntry
  } = commonFilters;
  let newComparisonDateRanges = [];
  if (dateType === RELATIVE_DATE_TYPE && comparisonModeOn && isNoneAdditionalDateFilters) {
    const relativeDateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
    if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD) {
      const yearOptions = getCompareSamePeriodLastYearOptions(
        { primaryDateRange: relativeDateRange,
          primaryDateType: dateType, templateId: _.get(templateEntry, 'template_id') });

      _.each(yearOptions, (yearEntry) => {
        const selectedYearRange = _.find(comparisonDateRanges, (compareDateRange) =>{
          const CompareStartYear = Number(moment(compareDateRange['startDate']).format('YYYY'));
          const startYear = Number(moment(_.get(yearEntry, 'range.startDate')).format('YYYY'));
          return CompareStartYear == startYear;
        });

        if (!_.isEmpty(selectedYearRange)){
          newComparisonDateRanges.push(yearEntry['range'])
        }
      });
    } else if (comparisonType == DATE_COMPARISON_TYPE.PRIOR){
      newComparisonDateRanges = [
        getLastPeriodDateRange(relativeDateRange, dateType)
      ];
    }
  }
  if (isNoneDateSelected && !enableCollectionLevelDateFilters()) {
    let commonFilters = _.cloneDeep(_.get(bookmark, 'commonFilters', {}));
    commonFilters.dateRange = _.get(dateFilters, 'dateRange', {});
    commonFilters.dateType = _.get(dateFilters, 'dateType', {}),
    commonFilters.relativeDateFilterEntry = _.get(dateFilters, 'relativeDateFilterEntry', {}),
    commonFilters.independentDateFilters = _.get(dateFilters, 'independentDateFilters', {}),
    commonFilters.additionalDateFilters = _.get(dateFilters, 'additionalDateFilters', {})
    commonFilters.comparisonModeOn = _.get(dateFilters, 'comparisonModeOn', false);
    commonFilters.comparisonType = _.get(dateFilters, 'comparisonType');
    commonFilters.comparisonDateRanges = _.isEmpty(newComparisonDateRanges) ?
      _.get(dateFilters, 'comparisonDateRanges', []) : newComparisonDateRanges;
    return commonFilters;
  } else if ((isNoneDateSelected && isBookmarkCard) || (isNoneDateSelected && !isBookmarkCard)) {
    let commonFilters = sanitizeCommonFilterParam(_.cloneDeep(_.get(bookmark, 'commonFilters', {})));
    commonFilters.comparisonDateRanges = _.isEmpty(newComparisonDateRanges) ?
      commonFilters.comparisonDateRanges : newComparisonDateRanges;
    return getTemplateCardCommonFilters({
      commonFilters,
      cardEntry: bookmark
    });
  } else {
    let commonFilters = _.cloneDeep(_.get(bookmark, 'commonFilters', {}));
    commonFilters.dateRange = getDateRangeBasedOnMonitor(bookmark);
    commonFilters.comparisonDateRanges = _.isEmpty(newComparisonDateRanges) ?
      commonFilters.comparisonDateRanges : newComparisonDateRanges;
    return sanitizeCommonFilterParam(commonFilters);
  }
}

export const anyEmptyBookmarkValues = (bookmark, anyOneInputValueEmpty = false) => {
  const emailStrategy = _.get(bookmark, 'email_strategy');
  const frequencyInterval = _.get(bookmark, 'email_options.frequencyInterval');
  const monitorConditions = _.get(bookmark, 'bookmarkOptions.monitorConditions', []);
  return anyOneInputValueIsEmpty({
    frequencyInterval,
    monitorConditions,
    emailStrategy,
    anyOneInputValueEmpty
  });
}

export const anyOneInputValueIsEmpty = ({
  frequencyInterval,
  monitorConditions,
  emailStrategy,
  anyOneInputValueEmpty = false
}) => {
  if(emailStrategy === EMAIL_STRATEGY_TYPES.ON_SCHEDULE) {
      if(isEmptyValue(frequencyInterval) || (frequencyInterval < 1)) {
        anyOneInputValueEmpty = true;
      }
    } else if(emailStrategy === EMAIL_STRATEGY_TYPES.THRESHOLD) {
      const anyOneConditionValueEmpty = _.some(monitorConditions, (condition) => {
        return isEmptyValue(_.get(condition, 'value'));
      });

      if(anyOneConditionValueEmpty) {
        anyOneInputValueEmpty = true;
      }
    }
  return anyOneInputValueEmpty;
}

export const getBookmarkType = (bookmark) => {
  let bookmarkType = BOOKMARK_TYPES['ALERT'];
  if(_.get(bookmark, 'email_strategy') == EMAIL_STRATEGY_TYPES.NEVER) {
    bookmarkType = BOOKMARK_TYPES['SAVED_VIEW'];
  }
  return bookmarkType;
}

export const isBookmarkValueChanged = (newBookmark, oldBookmark) => {
  const isChangeBookmark =  (
    !_.isEqual(_.get(newBookmark, 'email_strategy', ''), _.get(oldBookmark, 'email_strategy', '')) ||
    !_.isEqual(_.get(newBookmark, 'include_image', false), _.get(oldBookmark, 'include_image', false)) ||
    !_.isEqual(_.get(newBookmark, 'notification_state', ''),
      _.get(oldBookmark, 'notification_state', '')) ||
    !_.isEqual(_.get(newBookmark, 'name', ''), _.get(oldBookmark, 'name', '')) ||
    !_.isEqual(_.get(newBookmark, 'bookmarkOptions.dimensionOption'),
      _.get(oldBookmark, 'bookmarkOptions.dimensionOption')) ||
    !_.isEqual(_.get(newBookmark, 'send_alerts', false), _.get(oldBookmark, 'send_alerts', false)) ||
    !_.isEqual(_.get(newBookmark, 'bookmarkOptions.description', ''),
      _.get(oldBookmark, 'bookmarkOptions.description', '')) ||
    !_.isEqual(_.get(newBookmark, 'bookmarkOptions.isNoneDateSelected'),
      _.get(oldBookmark, 'bookmarkOptions.isNoneDateSelected')) ||
    !_.isEqual(_.get(newBookmark, 'bookmarkOptions.isNoneAdditionalDateFilters'),
      _.get(oldBookmark, 'bookmarkOptions.isNoneAdditionalDateFilters'))
  );

  const isChangeEmailOption = (
    !_.isEqual(_.get(newBookmark, 'email_options', {}), _.get(oldBookmark, 'email_options', {})))

  const isNeverEmailType = (_.get(newBookmark, 'email_strategy', '') === 'never');
  return (isNeverEmailType ?  isChangeBookmark : (isChangeEmailOption || isChangeBookmark));
}

export const isIncludeBookmarkDateRange = (currentBookmark) => {
  return !_.get(currentBookmark, 'bookmarkOptions.isNoneDateSelected', false) == true;
}

export const isCollectionView = (selectedView) => {
  const withoutCollectionView = [SEARCH_BAR_VIEW, COLLECTION_ID.ALL_METRICS, BOOKMARK_COLLECTION.name];
  return !_.includes(withoutCollectionView, selectedView);
}
